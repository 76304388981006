import Web3 from "web3";
import Fortmatic from "fortmatic";
import Torus from "@toruslabs/torus-embed";

export const FORMATIC_API_KEY = "pk_test_D190D54EBDC58FA4"; //"pk_test_DEE0ACD6E5A7EB56";//"pk_live_9613401E26B091DA";//
export const METAMASK_RECEIVER_ACCOUNT = "";

const ethereum = typeof window != undefined ? window.ethereum : {};
export var web3 = typeof window != undefined ? new Web3(window.ethereum) : {};
// export const initializeWeb3 = async () => {
//   const provider = localStorage.getItem("provider");

//   if (!provider) {
//     //web3 = new Web3(window.torus.provider);

//     // web3 = new Web3(new Web3.providers.HttpProvider("https://data-seed-prebsc-1-s1.binance.org:8545"));
//     web3 = new Web3(
//       new Web3.providers.HttpProvider(
//         "https://eth-goerli.alchemyapi.io/v2/gSEBNODiS1QdyPSe3dsl50xGYYKGKMCY"
//       )
//     );
//   } else {
//     await window.ethereum.request({ method: "eth_requestAccounts" });
//   }
// };

export const initializeWeb3 = async () => {
  const provider = localStorage.getItem("provider");
  // console.log("provider", provider);
  if (!provider) {
    web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://eth-goerli.alchemyapi.io/v2/gSEBNODiS1QdyPSe3dsl50xGYYKGKMCY"
      )
    );
  } else {
    if (provider == '"metamask"') {
      await window.ethereum.request({ method: "eth_requestAccounts" });
    } else if (provider == '"torus"') {
      const torus = new Torus();
      await torus.init({
        enableLogging: true,
        network: {
          host: "https://eth-goerli.alchemyapi.io/v2/gSEBNODiS1QdyPSe3dsl50xGYYKGKMCY", // mandatory
          networkName: "Goerli Test Network",
          chainId: 5,
          blockExplorer: "https://goerli.etherscan.io",
          ticker: "ETH",
          tickerName: "ETH",
        },
        showTorusButton: true,
      });
      // await torus.login(); // await torus.ethereum.enable()
      web3 = new Web3(torus.provider);
    } else if (provider == '"fortmatic"') {
      const BSCOptions = {
        //         /* Smart Chain - Testnet RPC URL */
        // rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        // chainId: 97, // Smart Chain - Testnet chain id

        rpcUrl:
          "https://eth-goerli.alchemyapi.io/v2/gSEBNODiS1QdyPSe3dsl50xGYYKGKMCY",
        chainId: 5, // Smart Chain - Testnet chain id
      };
      const fm = new Fortmatic(FORMATIC_API_KEY, BSCOptions);
      web3 = new Web3(fm.getProvider());
    }
  }
};

export const apiUrl = "https://artabia.com:3002/api";
// export const serviceFee = 0.004725;
export const serviceFee = 2.5;
export const audioUrl =
  "https://firebasestorage.googleapis.com/v0/b/testfirebase-ba78c.appspot.com/o/musicplayer.jpeg?alt=media&token=694a4c58-ae4c-4166-a010-3f45cf63146b";
export const pic =
  "https://image.freepik.com/free-photo/young-beautiful-woman-standing-yacht-luxury-travel-boat-cruise-vacation_146377-4040.jpg";
