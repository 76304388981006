import React, { Fragment } from "react";

const NOTIFICATIONS = [
  {
    profile: "/images/profile.jpg",
    type: "following",
    name: "Anna",
    desc: "Anna Start following you.",
    date: "2 d",
  },
  {
    profile: "/images/profile.jpg",
    type: "like",
    name: "Anna",
    desc: "Like your photo.",
    postImage: "/images/nft-3.jpg",
    date: "2 d",
  },

  {
    profile: "/images/profile.jpg",
    type: "comment",
    name: "Anna",
    postImage: "/images/nft-1.jpg",
    desc: "commented on your photo.",
    date: "3 d",
    comment: "Greet post!...",
    mention: "@Alessandro",
  },
];

const FeedNotification = () => {
  return (
    <div className="feed-notification-wrapper">
      <div className="drawer-cancel-btn">
        <button data-bs-dismiss="offcanvas" aria-label="Close">
          <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
        </button>
      </div>
      <h2 className="drawer-heading">Notifications</h2>
      <div className="feed-notification-wrap">
        <h3> This Week </h3>

        {NOTIFICATIONS?.map((data, index) => {
          return (
            <Fragment key={index}>
              <div className="notification-repeat-wrap">
                <div className="notification-left-wrap">
                  <img src={data.profile} alt="img" />
                  <p>
                    <span> {data.name} </span> {data.desc} {data.date}
                    <span> {data.mention} </span> {data.comment}
                  </p>
                </div>

                <div className="notifaction-right-wrap">
                  {data.postImage && <img src={data.postImage} alt="img" />}
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default FeedNotification;
