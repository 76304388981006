import React, { Fragment, useState, useEffect } from "react";
import { servicePost } from "../../../helpers/api";
import { shortenWalletId } from "../../../helpers/Normalization";
import moment from "moment";

const Bids = ({ selectedData }) => {
  const [bidList, setBids] = useState([]);

  /// Get bids by auctionId by Api here latter
  const getBidsbyId = async () => {
    try {
      const auctionId = selectedData && selectedData.auctionId;
      let tokendata = await servicePost("api/nft/getbidData", {
        auctionId: auctionId,
      });

      if (selectedData && selectedData.tokenOf != "PRONFT") {
        const filter = tokendata.bidData?.filter((data) => {
          return data.tokenOf != "PRONFT";
        });
        setBids(filter);
      } else {
        const filter = tokendata.bidData?.filter((data) => {
          return data.tokenOf == "PRONFT";
        });
        setBids(filter);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getBidsbyId();
  }, [selectedData && selectedData.auctionId]);

  const BIDS = [
    // {
    //   image: "",
    //   address: "quarkquarkquark",
    //   date: "4 days ago",
    //   price: "3.1",
    //   dollar: "23",
    //   expired: "Expired in 2 days",
    // },
    // {
    //   address: "quarkquarkquark",
    //   type: "purchased for",
    //   date: "30 weeks ago",
    //   price: "5.1",
    //   dollar: "68",
    //   expired: "Expired in 4 days",
    // },
    // {
    //   image: "/images/profile.jpg",
    //   address: "0xca....fshbdjv",
    //   date: "28 days ago",
    //   price: "1",
    //   dollar: "20",
    //   expired: "Expired in 8 days",
    // },
    // {
    //   address: "0xca....fshbdjv",
    //   type: "purchased for",
    //   date: "28 Minute ago",
    //   price: "2",
    //   dollar: "18",
    // },
  ];
  return (
    <>
      <div className="sellpage-bids-wrapper">
        <div className="activity-main-wrap">
          {bidList?.length == 0 ? (
            <>
              <div className="empty-activity-wrap">
                <img src="/images/icons/empty-bid.png" alt="img" />
                <p> Be The Pioneer: Make The First Bid! No Active Bids Yet. </p>
              </div>
            </>
          ) : (
            bidList?.map((data, index) => {
              return (
                <Fragment key={index}>
                  <div className="activity-repeat-wrap">
                    <div className="activity-left-wrap">
                      {data.bidderProfile ? (
                        <img
                          className="activity-dynamic-image"
                          src={data.bidderProfile}
                          alt="img"
                        />
                      ) : (
                        <img src="/images/icons/avtar.png" alt="img" />
                      )}

                      <div className="activity-address-wrap">
                        <h4>{shortenWalletId(data.bidderWalletId, 8, 6)}</h4>
                        <p>
                          {moment(data.updatedAt).fromNow()} •
                          {/* <span>{moment(data.updatedAt).fromNow()} </span> */}
                        </p>
                      </div>
                    </div>
                    <div className="activity-right-wrap">
                      <div className="activity-price-wrap">
                        <h3> {data.bidPrice} BNB </h3>
                        <img src="/images/icons/eth-icon.png" />
                      </div>
                      <p> ${data.bidPrice} </p>
                    </div>
                  </div>
                </Fragment>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default Bids;
