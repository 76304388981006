import React, { Fragment, useEffect, useState } from "react";
import StatusDropdown from "../../Dropdown/StatusDropdown";
import TypeDropdown from "../../Dropdown/TypeDropdown";
import Explore from "../Explore";
import PriceFilter from "../../Dropdown/PriceFilter";
import MarketPlaceFilter from "../../Dropdown/MarketPlaceFilter";
import { useNavigate } from "react-router-dom";
import { servicePost } from "../../../helpers/api";
import CollectibleCard from "../../Cards/CollectibleCard";

const NftItems = () => {
  const [allTokens, setAllTokens] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedMarketPlace, setSelectedMarketPlace] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState("");

  console.log("selectedMarketPlace", selectedMarketPlace);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const handleMarketplaceChange = (marketplace) => {
    setSelectedMarketPlace(marketplace);
  };

  const handlePriceChange = (range) => {
    setSelectedPriceRange(range);
  };

  const navigate = useNavigate();
  const goToSellPage = () => {
    navigate("/token/21/1");
  };

  const getNftTokens = async () => {
    try {
      const alltokens = await servicePost("api/nft/getalltoken");

      const data = alltokens.data;
      // Sort the collection ranking data based on floor_price in ascending order
      const sortedDataAsc = data.slice().sort((a, b) => a.price - b.price);

      // Sort the collection ranking data based on floor_price in descending order
      const sortedDataDesc = data.slice().sort((a, b) => b.price - a.price);

      if (selectedPriceRange && selectedPriceRange == "Low to high") {
        setAllTokens(sortedDataAsc);
      } else if (selectedPriceRange && selectedPriceRange == "High to low") {
        setAllTokens(sortedDataDesc);
      } else {
        setAllTokens(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getNftTokens();
  }, [selectedPriceRange]);

  const filterTokenData = () => {
    const filterData =
      allTokens &&
      allTokens.filter((ele) => {
        const status =
          selectedStatus && selectedStatus !== "All" ? selectedStatus : "";
        const type = selectedType && selectedType !== "All" ? selectedType : "";
        const marketplace =
          selectedMarketPlace && selectedMarketPlace !== "All"
            ? selectedMarketPlace
            : "";

        return (
          (status === "" || ele.status === status) &&
          (type === "" || ele.tokenType === type) &&
          ((marketplace === "" && ele.marketplace !== "Opensea") ||
            (marketplace === "ProNFT" && true) ||
            // Add conditions for other marketplaces
            // For example, if selectedMarketPlace is "Opensea", return false
            (marketplace === "Opensea" && false))
        );
      });

    // Apply search filter based on tokenName
    const searchKeyword = searchValue.trim().toLowerCase();
    const searchedData = filterData.filter((ele) =>
      ele.tokenName.toLowerCase().includes(searchKeyword)
    );

    setFilteredData(searchedData);
  };

  useEffect(() => {
    filterTokenData();
  }, [
    allTokens,
    selectedStatus,
    selectedType,
    searchValue,
    selectedMarketPlace,
  ]);
  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasExploreFilter"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <div className="profile-mobile-filter-wrapper">
              <div className="profile-mobile-filter-wrap">
                <div className="drawer-cancel-btn">
                  <button data-bs-dismiss="offcanvas" aria-label="Close">
                    <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                  </button>
                </div>
                <h2 className="drawer-heading"> Filters </h2>
                <MarketPlaceFilter
                  onMartketPlaceChange={handleMarketplaceChange}
                />
                <StatusDropdown onStatusChange={handleStatusChange} />
                <PriceFilter onPriceChange={handlePriceChange} />
                <TypeDropdown onTypeChange={handleTypeChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="explore-bg-wrapper">
        <div className="explore-nft-main-wrapper custom-container">
          <div className="explore-nft-main-wrap">
            <div className="explore-nft-header-wrap">
              <Explore />
              <div className="profile-owned-filter-wrap">
                <div className="explore-nft-search explore-nfts-search">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <img
                    className="search-owned-icon"
                    src="/images/icons/search-icon.svg"
                    alt="icon"
                  />
                </div>

                <div className="explore-filter-main-wrapper explore-filter-desktop">
                  <MarketPlaceFilter
                    onMartketPlaceChange={handleMarketplaceChange}
                  />
                  <StatusDropdown onStatusChange={handleStatusChange} />
                  <PriceFilter onPriceChange={handlePriceChange} />
                  <TypeDropdown onTypeChange={handleTypeChange} />
                </div>

                <div className="filter-laptop-view">
                  <img
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExploreFilter"
                    aria-controls="offcanvasRight"
                    src="/images/icons/filter-icon.svg"
                    alt="icon"
                  />
                </div>
              </div>
            </div>

            <div className="profile-nft-main-wrapper explore-nft-main-wrapper">
              <div className="profile-nft-main-wrap">
                {filteredData?.length > 0 &&
                  filteredData.map((data, index) => {
                    return (
                      <Fragment key={index}>
                        <CollectibleCard card={data} />
                      </Fragment>
                    );
                  })}
              </div>
              {filteredData?.length === 0 && (
                <div className="user-empty-box-wrap">
                  <img src="/images/icons/empty-box.png" alt="icon" />
                  <p> No NFTS Found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftItems;
