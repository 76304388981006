import React, { useState } from "react";
import Lottie from "lottie-react";
import ReviewLottie from "./review.json";
import { servicePost } from "../../helpers/api";
import { web3 } from "../../constants/constants";
import LoadingLottie from "../lottiefiles/loading-white.json";

const VerifyDrawer = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [formData, setFormData] = useState({
    profileLink: "",
    walletAddress: "",
    aboutYourself: "",
    youtubeLink: "",
    email: "",
    comments: "",
  });

  const {
    profileLink,
    walletAddress,
    aboutYourself,
    youtubeLink,
    email,
    comments,
  } = formData;

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  const handleProceedClick = () => {
    if (profileLink === "" || walletAddress === "" || selectedRole === "") {
      alert("Please Enter required Fields");
      return;
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleProceedSecondClick = () => {
    if (aboutYourself == "") {
      alert("Please Enter about yourself");
      return;
    }

    if (uploadedFiles.length < 2) {
      alert("Please Upload At Lest 2 NFT");
      return;
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleSubmitClick = () => {
    setCurrentStep(4);
  };

  const handleRoleClick = (role) => {
    setSelectedRole(role);
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...files]);
  };

  const getVerified = async () => {
    if (email == "") {
      alert("Please Enter eamil");
    }
    let headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
    const accounts = await web3.eth.getAccounts();
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("profileLink", profileLink);
      formData.append("walletAddress", walletAddress);
      formData.append("whoAreyou", selectedRole);
      formData.append("aboutYou", aboutYourself);
      formData.append("youtubeLink", youtubeLink);
      formData.append("email", email);
      // formData.append("workImages", uploadedFiles);
      uploadedFiles.forEach((file) => {
        formData.append("workImages", file);
      });
      formData.append("comments", comments);
      formData.append("ownerAddress", accounts[0]);
      const verifyRes = await servicePost(
        "user/getVerified",
        formData,
        headers
      );

      setCurrentStep(4);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="varify-drawer-main-wrapper">
        {currentStep === 1 && (
          <div className="verify-first-step-wrapper">
            <div className="drawer-cancel-btn">
              <button data-bs-dismiss="offcanvas" aria-label="Close">
                <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
              </button>
            </div>
            <div className="verified-header-wrap">
              <div className="verified-heading-wrapper">
                <div className="verified-heading-wrap">
                  <h2 className="drawer-heading"> Get Verified </h2>
                  <img src="/images/icons/verified.png" alt="icon" />
                </div>
                <div className="verified-pages-wrap">
                  <h4> 1 </h4>
                  <div className="pages-dots-wrap">
                    <div className="dots-wrap active"></div>
                    <div
                      className="dots-wrap"
                      onClick={handleProceedClick}
                    ></div>
                    <div className="dots-wrap"></div>
                  </div>
                </div>
              </div>
              <p>
                We appreciate your interest in ProNFT Profile Verification!
                Kindly complete the form, and we will promptly review it.
              </p>
            </div>

            <div className="verified-first-step-wrap">
              <div className="verify-step-input-repeat">
                <label> Profile link* </label>
                <input
                  type="text"
                  placeholder="Enter URL link of your ProNFT profile"
                  name="profileLink"
                  value={profileLink}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="verify-step-input-repeat">
                <label> Wallet Address* </label>
                <input
                  type="text"
                  placeholder="Enter Blockchain address"
                  name="walletAddress"
                  value={walletAddress}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="verify-collection-wrapper">
                <div className="verify-step-input-repeat">
                  <label> Who Are you* </label>
                  <p>
                    Are you an artist, collector, or seeking to verify your
                    collection?
                  </p>
                </div>
                <div className="verify-creator-wrapper">
                  <div
                    className={`verify-creator-wrap ${
                      selectedRole === "Creator" ? "selected" : ""
                    }`}
                    onClick={() => handleRoleClick("Creator")}
                  >
                    Creator
                    {selectedRole === "Creator" && (
                      <div className="dropdown-selct-tick">
                        <span className="tick-mark">✓</span>
                      </div>
                    )}
                  </div>
                  <div
                    className={`verify-creator-wrap ${
                      selectedRole === "Collector" ? "selected" : ""
                    }`}
                    onClick={() => handleRoleClick("Collector")}
                  >
                    Collector
                    {selectedRole === "Collector" && (
                      <div className="dropdown-selct-tick">
                        <span className="tick-mark">✓</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="verified-drawer-btn-wrap">
                <button className="btn-style" onClick={handleProceedClick}>
                  Proceed
                </button>
              </div>
            </div>
          </div>
        )}

        {/***************** Second Step For Verify account  */}

        {currentStep === 2 && (
          <div className="verify-second-step-wrapper">
            <div className="drawer-cancel-btn">
              <button data-bs-dismiss="offcanvas" aria-label="Close">
                <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
              </button>
            </div>
            <div className="verified-header-wrap">
              <div className="verified-heading-wrapper">
                <div className="verified-heading-wrap">
                  <h2 className="drawer-heading"> Get Verified </h2>
                  <img src="/images/icons/verified.png" alt="icon" />
                </div>
                <div className="verified-pages-wrap">
                  <h4 className="active-2"> 2 </h4>
                  <div className="pages-dots-wrap">
                    <div
                      className="dots-wrap"
                      onClick={() => setCurrentStep(1)}
                    ></div>
                    <div className="dots-wrap active"></div>
                    <div className="dots-wrap"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="verify-second-step-wrap">
              <div className="about-self-wrap">
                <div className="edit-profile-input-repeat">
                  <label> About Yourself* </label>
                  <textarea
                    placeholder="Please provide a brief description of yourself and your intentions for being a part of ProNFT."
                    name="aboutYourself"
                    value={aboutYourself}
                    onChange={inputEventHandler}
                  />
                </div>
              </div>

              <div className="upload-nft-wrapper">
                <div className="verify-step-input-repeat">
                  <label> Work in Progress* </label>
                  <p>
                    Please attach any artwork you are considering for NFT
                    publication or have already published.
                  </p>
                </div>
                <div className="upload-nft-box-wrapper">
                  <div className="upload-nft-box-wrap">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      // xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="72.117"
                      height="72.134"
                      viewBox="0 0 72.117 72.134"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.129"
                          x2="0.939"
                          y2="0.902"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#f0f" />
                          <stop offset="1" stop-color="orange" />
                        </linearGradient>
                      </defs>
                      <g
                        id="Component_75_1"
                        data-name="Component 75 – 1"
                        transform="translate(0.497 0.495)"
                      >
                        <path
                          id="Union_16"
                          data-name="Union 16"
                          d="M35.542,71.127v-.065c-5.365,0-10.725.057-16.082-.029-2.989-.043-6.023-.165-8.8-1.437C5.14,67.075,1.555,62.95.527,56.828A54.662,54.662,0,0,1,.017,47.7c0-4.718-.057-9.446.05-14.167.079-3.377.28-6.783,1.969-9.829,2.752-4.963,6.79-8.189,12.543-8.994a67.019,67.019,0,0,1,9.221-.5c8.282-.007,16.557-.029,24.843.022,3.432.014,6.917-.072,10.229.982a16.2,16.2,0,0,1,11.51,12.308c.917,3.868.683,7.832.719,11.762.034,4.06.034,8.119-.057,12.169a23.333,23.333,0,0,1-1.174,8.27c-2.333,5.885-6.529,9.671-12.859,10.835-3.794.695-7.638.51-11.467.56q-1.918.018-3.846.019C39.647,71.139,37.595,71.127,35.542,71.127ZM32.884,39.6c0,4.5.01,9,0,13.5a2.808,2.808,0,0,0,.683,1.99,2.682,2.682,0,0,0,2.953.738,2.727,2.727,0,0,0,1.7-2.62c.01-4.584,0-9.168.01-13.753a1.306,1.306,0,0,1,.165-.927c1.384,1.4,2.723,2.774,4.079,4.124a2.621,2.621,0,0,0,3.854.122,2.714,2.714,0,0,0-.1-3.873c-.544-.553-1.1-1.1-1.65-1.643-2.342-2.338-4.663-4.694-7.022-7.008a2.622,2.622,0,0,0-4.036.022c-.438.434-.869.865-1.3,1.293-2.457,2.46-4.922,4.9-7.357,7.379a2.631,2.631,0,0,0,1.1,4.5,2.674,2.674,0,0,0,2.788-.853c1.322-1.329,2.651-2.666,4.136-4.165ZM66.826,13.2A21.324,21.324,0,0,0,56.7,9.353a92.155,92.155,0,0,0-10.813-.481c-7.753,0-15.5-.036-23.263.022A46.642,46.642,0,0,0,11.065,9.987a21.5,21.5,0,0,0-9.877,5.9c-.165.165-.273.429-.6.438.453-3.384,1.164-6.654,3.2-9.485A14.609,14.609,0,0,1,12.9,1.2C18.329,0,23.855.043,29.373.019,35.07,0,40.766-.024,46.466.065A54.251,54.251,0,0,1,58.517,1.272C65.022,2.821,68.749,7,70.035,13.477c.187.941.326,1.89.527,3.054A26.086,26.086,0,0,0,66.826,13.2Z"
                          stroke="rgba(0,0,0,0)"
                          strokeWidth="1"
                          fill="url(#linear-gradient)"
                        />
                      </g>
                    </svg>
                    <p> Upload File</p>
                    <input type="file" onChange={handleFileUpload} />
                  </div>
                </div>
                <div className="upload-verify-nft-wrapper">
                  {Array.from(Array(3)).map((_, index) => (
                    <div className="upload-verify-nft-wrap" key={index}>
                      {uploadedFiles[index] ? (
                        <img
                          src={URL.createObjectURL(uploadedFiles[index])}
                          alt="uploaded"
                        />
                      ) : (
                        <p>Review</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="verified-drawer-btn-wrap">
              <button className="btn-style" onClick={handleProceedSecondClick}>
                Proceed
              </button>
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <div className="verify-third-step-wrapper">
            <div className="drawer-cancel-btn">
              <button data-bs-dismiss="offcanvas" aria-label="Close">
                <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
              </button>
            </div>
            <div className="verified-header-wrap">
              <div className="verified-heading-wrapper">
                <div className="verified-heading-wrap">
                  <h2 className="drawer-heading"> Get Verified </h2>
                  <img src="/images/icons/verified.png" alt="icon" />
                </div>
                <div className="verified-pages-wrap">
                  <h4 className="active-3">3</h4>
                  <div className="pages-dots-wrap">
                    <div
                      className="dots-wrap"
                      onClick={() => setCurrentStep(1)}
                    ></div>
                    <div
                      className="dots-wrap"
                      onClick={() => setCurrentStep(2)}
                    ></div>
                    <div className="dots-wrap active"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="verify-third-step-wrap">
              <div className="verify-step-input-repeat">
                <label> Youtube </label>
                <input
                  type="text"
                  placeholder="Enter URL link of your youtube profile"
                  name="youtubeLink"
                  value={youtubeLink}
                  onChange={inputEventHandler}
                />
                <p className="optional-filed"> Optional </p>
              </div>

              <div className="verify-step-input-repeat">
                <label> Email* </label>
                <input
                  type="text"
                  placeholder="Enter your email address"
                  name="email"
                  value={email}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="verify-step-input-repeat">
                <label> Comments </label>
                <p>
                  Is there anything else you would like to add or share with us?
                </p>
              </div>
              <div className="verify-comment-wrap">
                <div className="edit-profile-input-repeat">
                  <textarea
                    placeholder="Type here"
                    name="comments"
                    value={comments}
                    onChange={inputEventHandler}
                  />
                </div>
                <p className="optional-filed"> Optional </p>
              </div>
            </div>

            <div className="verified-drawer-btn-wrap">
              <button
                disabled={isLoading}
                className="btn-style"
                onClick={getVerified}
              >
                {isLoading ? (
                  <div className="button-loading-gif">
                    <Lottie animationData={LoadingLottie} loop={true} />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        )}
        {currentStep === 4 && (
          <div className="verify-fourth-step-wrapper">
            <div className="drawer-cancel-btn">
              <button data-bs-dismiss="offcanvas" aria-label="Close">
                <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
              </button>
            </div>
            <div className="verified-header-wrap">
              <div className="verified-heading-wrapper">
                <div className="verified-heading-wrap">
                  <h2 className="drawer-heading"> Get Verified </h2>
                  <img src="/images/icons/verified.png" alt="icon" />
                </div>
              </div>
            </div>
            <div className="verify-review-wrapper">
              <div className="verify-review-wrap">
                <div className="verify-review-lottie">
                  <Lottie animationData={ReviewLottie} loop={true} />
                </div>
                <h3> In Review </h3>
                <p>
                  Congratulations! Your application has been successfully
                  submitted. Please note that our diligent verification process
                  typically takes 2 weeks.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VerifyDrawer;
