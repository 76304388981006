import React, { useState } from "react";
import CommanModal from "../Modals/CommanModal";

const PostDrawer = () => {
  const [showPostPreview, setShowPostPreview] = useState(false);
  const [showTwittePreview, setShowTwittePreview] = useState(false);
  const [showCommanModal, setShowCommanModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [modalContant, setModalContant] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setShowPostPreview(true);
    }
  };

  const postShare = () => {
    setModalHeading("Post");
    setModalContant(
      "Your post has been successfully uploaded, and you can now find it in your feed."
    );
    setShowCommanModal(true);
    document.getElementById("closecreatepostDrawer").click();
  };

  const twitteShare = () => {
    setModalHeading("Pro Tweet");
    setModalContant(
      "Your Tweet has been successfully uploaded, and you can now find it in your feed."
    );
    setShowCommanModal(true);
    document.getElementById("closecreatepostDrawer").click();
  };

  return (
    <>
      <span
        id="closecreatepostDrawer"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></span>
      <CommanModal
        show={showCommanModal}
        onHide={() => setShowCommanModal(false)}
        heading={modalHeading}
        contant={modalContant}
      />
      <div className="create-post-drawer-wrapper">
        <div className="drawer-cancel-btn">
          <button data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
          </button>
        </div>
        {!showPostPreview && !showTwittePreview && (
          <>
            <h2 className="drawer-heading"> Create New Post </h2>

            <div className="create-post-main-wrapper">
              <div className="create-post-wrap">
                <div className="creat-post-box-repeat">
                  <div className="create-post-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      // xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="72.117"
                      height="72.134"
                      viewBox="0 0 72.117 72.134"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          y1="0.129"
                          x2="0.939"
                          y2="0.902"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#f0f" />
                          <stop offset="1" stop-color="orange" />
                        </linearGradient>
                      </defs>
                      <g
                        id="Component_75_1"
                        data-name="Component 75 – 1"
                        transform="translate(0.497 0.495)"
                      >
                        <path
                          id="Union_16"
                          data-name="Union 16"
                          d="M35.542,71.127v-.065c-5.365,0-10.725.057-16.082-.029-2.989-.043-6.023-.165-8.8-1.437C5.14,67.075,1.555,62.95.527,56.828A54.662,54.662,0,0,1,.017,47.7c0-4.718-.057-9.446.05-14.167.079-3.377.28-6.783,1.969-9.829,2.752-4.963,6.79-8.189,12.543-8.994a67.019,67.019,0,0,1,9.221-.5c8.282-.007,16.557-.029,24.843.022,3.432.014,6.917-.072,10.229.982a16.2,16.2,0,0,1,11.51,12.308c.917,3.868.683,7.832.719,11.762.034,4.06.034,8.119-.057,12.169a23.333,23.333,0,0,1-1.174,8.27c-2.333,5.885-6.529,9.671-12.859,10.835-3.794.695-7.638.51-11.467.56q-1.918.018-3.846.019C39.647,71.139,37.595,71.127,35.542,71.127ZM32.884,39.6c0,4.5.01,9,0,13.5a2.808,2.808,0,0,0,.683,1.99,2.682,2.682,0,0,0,2.953.738,2.727,2.727,0,0,0,1.7-2.62c.01-4.584,0-9.168.01-13.753a1.306,1.306,0,0,1,.165-.927c1.384,1.4,2.723,2.774,4.079,4.124a2.621,2.621,0,0,0,3.854.122,2.714,2.714,0,0,0-.1-3.873c-.544-.553-1.1-1.1-1.65-1.643-2.342-2.338-4.663-4.694-7.022-7.008a2.622,2.622,0,0,0-4.036.022c-.438.434-.869.865-1.3,1.293-2.457,2.46-4.922,4.9-7.357,7.379a2.631,2.631,0,0,0,1.1,4.5,2.674,2.674,0,0,0,2.788-.853c1.322-1.329,2.651-2.666,4.136-4.165ZM66.826,13.2A21.324,21.324,0,0,0,56.7,9.353a92.155,92.155,0,0,0-10.813-.481c-7.753,0-15.5-.036-23.263.022A46.642,46.642,0,0,0,11.065,9.987a21.5,21.5,0,0,0-9.877,5.9c-.165.165-.273.429-.6.438.453-3.384,1.164-6.654,3.2-9.485A14.609,14.609,0,0,1,12.9,1.2C18.329,0,23.855.043,29.373.019,35.07,0,40.766-.024,46.466.065A54.251,54.251,0,0,1,58.517,1.272C65.022,2.821,68.749,7,70.035,13.477c.187.941.326,1.89.527,3.054A26.086,26.086,0,0,0,66.826,13.2Z"
                          stroke="rgba(0,0,0,0)"
                          strokeWidth="1"
                          fill="url(#linear-gradient)"
                        />
                      </g>
                    </svg>
                    <input type="file" onChange={handleFileChange} />
                  </div>
                  <div className="create-post-text">
                    <h5> Upload photos and GIFs here </h5>
                    <p>
                      Optimize your viewing experience with the enhanced
                      dimensions of 1080x1080 pixels.
                    </p>
                  </div>
                </div>

                <div className="creat-post-box-repeat">
                  <div
                    className="create-post-icon"
                    onClick={() => setShowTwittePreview(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      // xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="72.144"
                      height="72.157"
                      viewBox="0 0 72.144 72.157"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          x1="0.12"
                          y1="0.038"
                          x2="0.631"
                          y2="1"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#f0f" />
                          <stop offset="1" stop-color="orange" />
                        </linearGradient>
                      </defs>
                      <g
                        id="Component_76_1"
                        data-name="Component 76 – 1"
                        transform="translate(0.523 0.5)"
                      >
                        <path
                          id="Union_25"
                          data-name="Union 25"
                          d="M2.434,71.157a3.351,3.351,0,0,1-2.4-2.71A3.291,3.291,0,0,1,3.183,64.6c.184-.012.369-.008.553-.008q22.138,0,44.275,0a4.559,4.559,0,0,1,1.568.222,3.308,3.308,0,0,1,.367,6.056c-.2.11-.42.191-.631.285ZM67.242,55.088q-31.66-.037-63.319-.011a4.362,4.362,0,0,1-2.211-.449A3.309,3.309,0,0,1,.133,50.944a3.259,3.259,0,0,1,3.061-2.482c.231-.007.462,0,.693,0q31.729,0,63.458-.019a3.618,3.618,0,0,1,3.776,2.427v1.807a3.736,3.736,0,0,1-3.872,2.415ZM41.776,38.921H25.832q-10.919,0-21.838,0a6.4,6.4,0,0,1-1.445-.13,3.3,3.3,0,0,1,.034-6.432,6.007,6.007,0,0,1,1.237-.12q22.079-.009,44.159,0c2.417,0,3.928,1.446,3.785,3.6a3.313,3.313,0,0,1-3.542,3.078q-1.321.013-2.642.011C44.313,38.927,43.044,38.921,41.776,38.921Zm25.567-16.2q-31.8-.042-63.595-.01a3.585,3.585,0,0,1-2.677-.9A3.307,3.307,0,0,1,3.163,16.1c.275-.027.555-.011.832-.011h14.6q24.325,0,48.653-.016a3.732,3.732,0,0,1,3.878,2.415v1.806a3.621,3.621,0,0,1-3.768,2.427ZM2.194,6.338A3.136,3.136,0,0,1,.032,3.467,3.157,3.157,0,0,1,1.766.318C1.987.194,2.227.1,2.458,0H49.341a3.34,3.34,0,0,1,2.394,2.711,3.29,3.29,0,0,1-3.144,3.842c-.184.012-.369.008-.555.008q-22.138,0-44.277,0A4.211,4.211,0,0,1,2.194,6.338Z"
                          transform="translate(0 0)"
                          stroke="rgba(0,0,0,0)"
                          strokeWidth="1"
                          fill="url(#linear-gradient)"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="create-post-text">
                    <h5> Pro Tweet post</h5>
                    <p>Quickly share your thoughts with a Pro Tweet post.</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {showPostPreview && (
          <>
            <h2 className="drawer-heading"> Preview </h2>
            <div className="create-post-preview-wrap">
              <div className="create-post-preview">
                <img src={selectedImage} alt="img" />
                <div className="post-cross-button">
                  <button onClick={() => setShowPostPreview(false)}>
                    <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                  </button>
                </div>
              </div>

              <div className="post-creator-profile-wrap">
                <img src="/images/profile.jpg" alt="img" />
                <p> Genesis</p>
              </div>

              <div className="post-message-wrap">
                <textarea placeholder="Write a caption or Hastags you want"></textarea>
              </div>

              <div className="postdrawer-share-btn">
                <button className="btn-style" onClick={postShare}>
                  Share
                </button>
              </div>
            </div>
          </>
        )}

        {showTwittePreview && (
          <>
            <h2 className="drawer-heading"> Preview </h2>
            <div className="create-twitte-main-wrapper">
              <div className="post-creator-profile-wrap">
                <img src="/images/profile.jpg" alt="img" />
                <p> Genesis</p>
              </div>

              <div className="post-message-wrap twitte-message-wrap">
                <textarea placeholder="Write a caption or Hastags you want"></textarea>
              </div>

              <div className="postdrawer-share-btn">
                <button className="btn-style" onClick={twitteShare}>
                  Share
                </button>

                <button
                  className="btn-style cancel-btn-style"
                  onClick={() => setShowTwittePreview(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PostDrawer;
