import React, { useEffect, useState } from "react";
import PostDrawer from "../Drawers/PostDrawer";
import { NavLink, useNavigate } from "react-router-dom";
import FollowerDrawer from "../Drawers/FollowerDrawer";
import FollowingDrawer from "../Drawers/FollowingDrawer";
import ReportDrawer from "../Drawers/ReportDrawer";
import "bootstrap/dist/css/bootstrap.min.css";
import { DropdownButton } from "react-bootstrap";
import { serviceGet, servicePost } from "../../helpers/api";
import {
  shortenWalletId,
  handleCopyWalletId,
} from "../../helpers/Normalization";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading-white.json";
import { ALLOWED_IMAGE_SIZE_IN_BYTES } from "../../constants/utility";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const MyProfile = () => {
  const [coverLoading, setCoverLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [userData, setUserData] = useState([]);
  const [ownedTokenCount, setOwnedTokenCount] = useState(0);
  const [createdTokenCount, setCreatedTokenCount] = useState(0);
  const [saleTokenCount, setSaleTokenCount] = useState(0);
  const [collectionCount, setCollectionCount] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);

  //get user from localstorage...
  const user = JSON.parse(localStorage.getItem("user"));
  const walletId = user && user.walletId;

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // reader.readAsDataURL(file);
      try {
        if (file.size > ALLOWED_IMAGE_SIZE_IN_BYTES) {
          alert("File size exceeds maximum file size limit of 12MB");
          return;
        }

        var allowedExtensions = /(jpg|jpeg|png|gif)$/i;
        if (!allowedExtensions.exec(file.type)) {
          alert("Invalid file type. Only jpg, png & gif allowed");
          return;
        }

        setCoverLoading(true);
        const fileTarget = file;
        let formData = new FormData();
        formData.append("image", fileTarget);
        formData.append("walletId", walletId);
        let projectImage = await servicePost(`image/coverupload`, formData);
        setUploadedImage(projectImage.imageUrl);
        setCoverLoading(false);
      } catch (err) {
        setCoverLoading(false);
        console.error(err);
      }
    }
  };

  const navigate = useNavigate();
  const goToEditProfile = () => {
    navigate("/setting/profile");
  };

  const getUserDetail = async () => {
    try {
      const userRes = await serviceGet(`user/${walletId}`);
      setUserData(userRes.user);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get Owned Token Count
  const getOwnedTokenCount = async () => {
    try {
      const OwnedTokenCount = await servicePost("api/nft/counttokensbyowner", {
        ownerAddress: walletId,
      });

      setOwnedTokenCount(OwnedTokenCount.tokenCount);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get Created Token Count
  const getCreatedTokenCount = async () => {
    try {
      const createdTokenCount = await servicePost(
        "api/nft/counttokensbycreator",
        {
          creatorAddress: walletId,
        }
      );

      setCreatedTokenCount(createdTokenCount.tokenCount);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get Sale Token Count
  const getSaleTokenCount = async () => {
    try {
      const saleTokenCount = await servicePost("api/nft/saletokencount", {
        ownerAddress: walletId,
      });

      setSaleTokenCount(saleTokenCount.tokenCount);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get Sale Token Count
  const getCollectionCount = async () => {
    try {
      const collectionCount = await servicePost(
        "api/contract/countCollectionsByOwner",
        {
          ownerAddress: walletId,
        }
      );

      setCollectionCount(collectionCount.collectionCount);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
    getOwnedTokenCount();
    getCreatedTokenCount();
    getSaleTokenCount();
    getCollectionCount();
  }, [walletId]);

  const getFollowerCount = async () => {
    try {
      const countData = await servicePost("api/followLink/followerCount", {
        userId: walletId,
      });

      setFollowerCount(countData.followerCount);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFollowingCount = async () => {
    try {
      const countData = await servicePost("api/followLink/followingCount", {
        userId: walletId,
      });

      setFollowingCount(countData.followingCount);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getFollowerCount();
    getFollowingCount();
  }, []);

  // Share user Profile
  const currentPath = window.location.href;
  const shareUrl = currentPath;
  const title = "ProNFT";

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasCreatePost"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <PostDrawer />
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasFollowers"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body">
          <FollowerDrawer walletId={walletId} />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasFollowing"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body">
          <FollowingDrawer walletId={walletId} />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasReport"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <ReportDrawer />
        </div>
      </div>

      <div className="profile-page-main-wrapper custom-container">
        <div className="profile-page-main-wrap">
          <div className="profile-main-wrap">
            <div className="profile-backround-image">
              {uploadedImage ? (
                <img
                  className="uploaded-image"
                  src={uploadedImage}
                  alt="uploaded"
                />
              ) : userData.coverImageUrl ? (
                <img
                  className="uploaded-image"
                  src={userData.coverImageUrl}
                  alt="uploaded"
                />
              ) : (
                ""
              )}
            </div>
            <div className="edit-bg-image">
              {coverLoading ? (
                <>
                  <div className="profile-loading">
                    <Lottie animationData={LoadingLottie} loop={true} />
                  </div>
                </>
              ) : (
                <>
                  <img src="/images/icons/edit-profile-icon.svg" alt="img" />
                  <input type="file" onChange={handleImageUpload} />
                </>
              )}
            </div>
            <div className="profile-image-wrap">
              {userData?.profileImageUrl ? (
                <img src={userData?.profileImageUrl} alt="img" />
              ) : (
                <img src="/images/icons/avtar.png" alt="img" />
              )}
              {/* <img src="/images/profile.jpg" alt="img" /> */}
              {/* <img src="/images/icons/avtar.png" alt="img" /> */}
            </div>
          </div>

          <div className="profile-info-main-wrapper">
            <div className="profile-info-left-wrap">
              <div className="profile-name-wrapper">
                <h2>
                  {userData?.name
                    ? userData?.name
                    : userData?.walletId
                    ? userData?.walletId.slice(0, 8)
                    : "-"}
                </h2>

                {userData?.verified && (
                  <img src="/images/icons/verified.png" alt="icon" />
                )}

                <img
                  onClick={goToEditProfile}
                  className="profile-edit-icon"
                  src="/images/icons/edit.png"
                  alt="icon"
                />
              </div>
              <div className="profile-address-wrapper">
                <p>
                  Address
                  <span> {shortenWalletId(userData.walletId, 8, 4)} </span>
                </p>

                <div className="copy-address-button">
                  <img
                    src="/images/icons/copy-icon.png"
                    alt="icon"
                    onClick={() =>
                      handleCopyWalletId(userData.walletId, setIsTooltipVisible)
                    }
                  />
                  <span> {isTooltipVisible && "Copied!"} </span>
                </div>
              </div>
              <div className="profile-desc-wrap">
                <p> {userData?.shortBio} </p>
              </div>
            </div>
            <div className="profile-info-right-wrap">
              {userData?.twitterUserName && (
                <span tooltip={userData?.twitterUserName}>
                  <img src="/images/icons/twitter-gray.png" alt="img" />
                </span>
              )}

              {userData?.website && (
                <span tooltip={userData?.website}>
                  <a href={userData?.website} target="_blank">
                    <img src="/images/icons/website-gray.png" alt="img" />
                  </a>
                </span>
              )}

              <div className="share-btn-wrap">
                <img
                  onMouseEnter={() => setShowShare(true)}
                  onMouseLeave={() => setShowShare(false)}
                  src="/images/icons/share.png"
                  alt="img"
                />

                <div
                  className="share-btn-space-manage"
                  onMouseEnter={() => setShowShare(true)}
                  onMouseLeave={() => setShowShare(false)}
                ></div>
              </div>
              {/* <div className="report-btn-wrap">
                <img
                  onMouseEnter={() => setShowReport(true)}
                  onMouseLeave={() => setShowReport(false)}
                  src="/images/icons/menu.png"
                  alt="img"
                />
                <div
                  className="report-btn-space-manage"
                  onMouseEnter={() => setShowReport(true)}
                  onMouseLeave={() => setShowReport(false)}
                ></div>
              </div> */}

              {showShare && (
                <div
                  className="report-button-wrap"
                  onMouseEnter={() => setShowShare(true)}
                  onMouseLeave={() => setShowShare(false)}
                >
                  <div className="share-button-wrapper">
                    <div className="share-icon-wrap">
                      <TwitterShareButton url={shareUrl} quote={title}>
                        <img src="/images/icons/twitter-white.svg" alt="icon" />
                      </TwitterShareButton>

                      <InstapaperShareButton url={shareUrl} title={title}>
                        <img src="/images/icons/insta-white.svg" alt="icon" />
                      </InstapaperShareButton>
                      <WhatsappShareButton url={shareUrl} quote={title}>
                        <img src="/images/icons/whatsapp.svg" alt="icon" />
                      </WhatsappShareButton>
                      <FacebookShareButton url={shareUrl} quote={title}>
                        <img src="/images/icons/facebook.svg" alt="icon" />
                      </FacebookShareButton>
                    </div>
                  </div>
                </div>
              )}

              {showReport && (
                <div
                  className="report-button-wrap"
                  onMouseEnter={() => setShowReport(true)}
                  onMouseLeave={() => setShowReport(false)}
                >
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasReport"
                    aria-controls="offcanvasExample"
                    className="btn-style"
                  >
                    Report page
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="profile-socail-info-wrapper">
            <div className="profile-socail-left-info">
              <div className="profile-followers-wrap">
                <h3> Followers </h3>
                <p
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasFollowers"
                  aria-controls="offcanvasExample"
                >
                  {followerCount}
                </p>
              </div>
              <div className="profile-followers-wrap">
                <h3> Following </h3>
                <p
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasFollowing"
                  aria-controls="offcanvasExample"
                >
                  {followingCount}
                </p>
              </div>
            </div>
            {/* <div className="profile-socail-right-info">
              <div
                className="create-post-button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasCreatePost"
                aria-controls="offcanvasRight"
              >
                <img src="/images/icons/create-icon.png" alt="icon" />
              </div>

              <h4> Create Post </h4>
            </div> */}
          </div>
        </div>
        <div className="profile-nav-tab">
          <div className="porfile-navigation profile-navigation-desktop">
            <ul>
              <li>
                <NavLink to="/profile/owned" activeClassName="isActive">
                  Owned
                </NavLink>
                {ownedTokenCount > 0 && (
                  <div className="count-box-wrap">
                    <span> {ownedTokenCount} </span>
                  </div>
                )}
              </li>
              <li>
                <NavLink to="/profile/sale" activeClassName="isActive">
                  On Sale
                </NavLink>
                {saleTokenCount > 0 && (
                  <div className="count-box-wrap">
                    <span> {saleTokenCount} </span>
                  </div>
                )}
              </li>
              <li>
                <NavLink to="/profile/collections" activeClassName="isActive">
                  Collection
                </NavLink>

                {collectionCount > 0 && (
                  <div className="count-box-wrap">
                    <span> {collectionCount} </span>
                  </div>
                )}
              </li>
              <li>
                <NavLink to="/profile/created" activeClassName="isActive">
                  Created
                </NavLink>
                {createdTokenCount > 0 && (
                  <div className="count-box-wrap">
                    <span> {createdTokenCount} </span>
                  </div>
                )}
              </li>
              <li>
                <NavLink to="/profile/activity" activeClassName="isActive">
                  Activity
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="porfile-navigation profile-navigation-mobile">
            <ul>
              <li>
                <NavLink to="/profile/owned" activeClassName="isActive">
                  Owned
                </NavLink>
                <div className="count-box-wrap">
                  <span> 2 </span>
                </div>
              </li>
              <li>
                <NavLink to="/profile/sale" activeClassName="isActive">
                  On Sale
                </NavLink>
                <div className="count-box-wrap">
                  <span> 5 </span>
                </div>
              </li>

              <DropdownButton id="dropdown-basic-button" title="More">
                <li>
                  <NavLink to="/profile/collections" activeClassName="isActive">
                    Collection
                  </NavLink>
                  <div className="count-box-wrap">
                    <span> 2 </span>
                  </div>
                </li>
                <li>
                  <NavLink to="/profile/created" activeClassName="isActive">
                    Created
                  </NavLink>
                  <div className="count-box-wrap">
                    <span> 3 </span>
                  </div>
                </li>
                <li>
                  <NavLink to="/profile/activity" activeClassName="isActive">
                    Activity
                  </NavLink>
                </li>
              </DropdownButton>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
