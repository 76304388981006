import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CreateNFT = () => {
  const [metamaskBrowser, setMetamaskBrowser] = useState();
  useEffect(() => {
    let userAgent = navigator.userAgent;
    // alert(userAgent);
    var isMobile = /android/.test(userAgent.toLowerCase());
    // alert(isMobile);
    var isMetamaskBrowser = /metamask/.test(userAgent.toLowerCase());
    //return isMobile;
    // alert(isMetamaskBrowser);
    setMetamaskBrowser(isMetamaskBrowser);
  }, []);

  const navigate = useNavigate();
  const goToCreatePage = () => {
    if (metamaskBrowser == true) {
      navigate("/android/single");
    }
  };

  const goToMultiplePage = () => {
    if (metamaskBrowser == true) {
      navigate("/android/multiple");
    }
  };

  return (
    <>
      <div className="create-drawer-main-wrapper android-create-main-wrapper">
        <div className="drawer-cancel-btn">
          <a
            href="intent://nft.blockrhino.io/#Intent;scheme=https;package=com.shahjad.walletconnectlibadd;end"
            target="_new"
          >
            <button>
              <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
            </button>
          </a>
        </div>
        <div className="create-drawer-header">
          <h2 className="drawer-heading">Create NFT</h2>
          <p>
            Select 'Single' for a One-of-a-Kind Item or 'Multiple' to Sell a
            Collectible Multiple Times.
          </p>
        </div>

        <div className="create-drawer-type-wrapper">
          <div className="create-drawer-type-wrap">
            <div className="create-drawer-type-repeat" onClick={goToCreatePage}>
              <div className="create-drawer-warp">
                <img src="/images/icons/single-create.svg" alt="img" />
                <div className="create-drawer-type-text">
                  <h3> Single </h3>
                  <p>
                    If you wish to accentuate the uniqueness and individuality
                    of your item...
                  </p>
                </div>
              </div>
            </div>

            <div
              className="create-drawer-type-repeat"
              onClick={goToMultiplePage}
            >
              <div className="create-drawer-warp">
                <img src="/images/icons/multi-create.svg" alt="img" />
                <div className="create-drawer-type-text">
                  <h3> Multiple </h3>
                  <p>
                    If you want to distribute your NFT among a vast community of
                    members...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="create-drawer-footer-wrap">
          <p>
            We do not own your private keys and cannot acces your funds without
            your confirmation
          </p>
        </div>
      </div>
    </>
  );
};

export default CreateNFT;
