import React, { Fragment, useEffect, useState } from "react";
import StatusDropdown from "../../Dropdown/StatusDropdown";
import TypeDropdown from "../../Dropdown/TypeDropdown";
import { servicePost } from "../../../helpers/api";
import { web3 } from "../../../constants/constants";
import CollectibleCard from "../../Cards/CollectibleCard";

const Items = ({ tokenOf, owner }) => {
  const [collectionItems, setCollectionItems] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const getCollectionTokens = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      const collectionTokens = await servicePost("api/getCollectionNFT", {
        owner: owner,
        tokenOf: tokenOf,
      });
      setCollectionItems(collectionTokens.collectionNFTs);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCollectionTokens();
  }, [tokenOf, owner]);

  const filterTokenData = () => {
    const filterData =
      collectionItems &&
      collectionItems.filter((ele) => {
        var status =
          selectedStatus && selectedStatus !== "All" ? selectedStatus : "";
        var type = selectedType && selectedType !== "All" ? selectedType : "";

        if (status == "" && type == "") {
          return true;
        } else if (status && !type) {
          return ele.status === status;
        } else if (!status && type) {
          return ele.tokenType === type;
        } else if (status && type) {
          return ele.status === status && ele.tokenType === type;
        }

        return false;
      });

    // Apply search filter based on tokenName
    const searchKeyword = searchValue.trim().toLowerCase();
    const searchedData = filterData.filter((ele) =>
      ele.tokenName.toLowerCase().includes(searchKeyword)
    );

    setFilteredData(searchedData);
  };

  useEffect(() => {
    filterTokenData();
  }, [collectionItems, selectedStatus, selectedType, searchValue]);

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasProfileFilter"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <div className="profile-mobile-filter-wrapper">
              <div className="profile-mobile-filter-wrap">
                <div className="drawer-cancel-btn">
                  <button data-bs-dismiss="offcanvas" aria-label="Close">
                    <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                  </button>
                </div>
                <h2 className="drawer-heading"> Filters </h2>
                <StatusDropdown onStatusChange={handleStatusChange} />
                <TypeDropdown onTypeChange={handleTypeChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-bg-wrapper">
        {/* <MyCollection /> */}
        <div className="profile-owned-main-wraper">
          <div className="profile-owned-filter-wrap">
            <div className="profile-owned-nft-search">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <img
                className="search-owned-icon"
                src="/images/icons/search-icon.svg"
                alt="icon"
              />
            </div>
            <div className="filter-mobile-view">
              <img
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasProfileFilter"
                aria-controls="offcanvasRight"
                src="/images/icons/filter-icon.svg"
                alt="icon"
              />
            </div>
            <div className="filter-desktop-view">
              <StatusDropdown onStatusChange={handleStatusChange} />
              <TypeDropdown onTypeChange={handleTypeChange} />
            </div>
          </div>

          <div className="profile-nft-main-wrapper">
            <div className="profile-nft-main-wrap">
              {filteredData?.length > 0
                ? filteredData.map((data, index) => {
                    return (
                      <Fragment key={index}>
                        <CollectibleCard card={data} />
                      </Fragment>
                    );
                  })
                : null}
            </div>

            {filteredData?.length === 0 && (
              <div className="empty-card-main-wrap">
                <div className="empty-card-image">
                  <img src="/images/icons/empty-box.png" alt="empty" />
                  <p>Nothing Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Items;
