import React, { Fragment, useEffect, useState } from "react";
import { servicePost } from "../../helpers/api";
import { shortenWalletId } from "../../helpers/Normalization";
import { saveContract } from "../../redux/actions/contract";
import { web3 } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const SelectContract = ({ collectionType }) => {
  const [selectedContract, setSelectedContract] = useState(null);
  const [contractList, setContractList] = useState([]);
  const [walletId, setWalletId] = useState();
  const dispatch = useDispatch();

  // clear Selected Contract When Path Change
  const location = useLocation();
  useEffect(() => {
    dispatch(saveContract());
  }, [location.pathname]);

  const handleContractClick = (index, data) => {
    setSelectedContract(index);
    dispatch(saveContract(data));
    document.getElementById("closeSelectContractDrawer").click();
  };

  const getUserContracts = async () => {
    const accounts = await web3.eth.getAccounts();
    setWalletId(accounts[0]);
    try {
      const contractData = await servicePost(
        "api/contract/getContractDataByOwner",
        {
          ownerAddress: walletId,
        }
      );

      const filteredContracts = contractData.data.filter(
        (contract) => contract.contractType === collectionType
      );

      setContractList(filteredContracts);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserContracts();
  }, [walletId]);

  return (
    <>
      <span
        id="closeSelectContractDrawer"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></span>
      <div className="select-contract-main-wrapper">
        <div className="create-contract-main-wrapper">
          <div className="drawer-cancel-btn">
            <button data-bs-dismiss="offcanvas" aria-label="Close">
              <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
            </button>
          </div>
          <h2 className="drawer-heading">Contract List</h2>

          <div className="contract-list-main-wrapper">
            <div className="contract-list-main-wrap">
              {contractList?.map((data, index) => {
                const isActive = selectedContract === index;
                return (
                  <Fragment key={index}>
                    <div
                      className={`contract-list-repeat ${
                        isActive ? "active" : ""
                      }`}
                      onClick={() => handleContractClick(index, data)}
                    >
                      <div className="contract-left-wrap">
                        <div className="contract-image-wrap">
                          {data.contractImage ? (
                            <img src={data.contractImage} alt="img" />
                          ) : (
                            <img src="/images/icons/avtar.png" alt="img" />
                          )}
                        </div>
                        <div className="contract-info-wrap">
                          <h3>
                            {data?.contractName} <span> ({data?.symbol}) </span>
                          </h3>
                          <div className="contract-address-wrap">
                            <p>{shortenWalletId(data.contractAddress, 8, 6)}</p>
                            <img src="/images/icons/copy-icon.png" alt="icon" />
                          </div>
                        </div>
                      </div>
                      <div className="contract-right-wrap">
                        {isActive && (
                          <div className="contract-tick-wrap">
                            <img src="/images/icons/tick-mark.svg" alt="img" />
                          </div>
                        )}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectContract;
