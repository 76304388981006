import { useSelector } from "react-redux";
import { BrowserRouter as Router, Navigate, Outlet } from "react-router-dom";
import { isUserAuthenticated } from "../helpers/AuthUtils";

let userAgent = navigator.userAgent;
var isMetamaskBrowser = /metamask/.test(userAgent.toLowerCase());

// Protected if user not authenticated..
export const ProtectedRoute = ({ redirectPath = "/" }) => {
  if (!isUserAuthenticated()) {
    localStorage.clear();
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

// Protected for the Metamask Brawser Router
export const BrowserProtectroute = ({ redirectPath = "/" }) => {
  if (isUserAuthenticated() && isMetamaskBrowser === true) {
    return <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};
