import React, { Fragment, useState } from "react";

const allUser = [
  {
    name: "All",
    icon: "/images/icons/all-status.svg",
    activeIcon: "/images/icons/all-status-active.svg",
  },
  {
    name: "Buy now",
    icon: "/images/icons/buy-icon.svg",
    activeIcon: "/images/icons/buy-icon-active.svg",
  },
  {
    name: "Live Auction",
    icon: "/images/icons/live-aution.svg",
    activeIcon: "/images/icons/live-aution-active.svg",
  },

  {
    name: "Not For Sale",
    icon: "/images/icons/not-sale.svg",
    activeIcon: "/images/icons/not-sale-active.svg",
  },
];

const StatusDropdown = ({ onStatusChange }) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("All");

  return (
    <>
      <div className="select-box-wrapper">
        {/* <label>Assigned To</label> */}
        <div className="react-dropdown">
          <div
            className="react-dropdown-btn"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsActive(false);
            }}
          >
            <div className="selected-item">
              <p>
                Status: <span> {selected} </span>
              </p>
            </div>

            <img
              className={
                isActive
                  ? "dropdown-down-arrow rotate-arrow"
                  : "dropdown-down-arrow"
              }
              src="/images/icons/dropdown-arrow.svg"
              alt="arrow"
            />
          </div>
          <div
            className="dropdown-space-manage"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsActive(false);
            }}
          ></div>
          {isActive && (
            <div
              className="dropdown-content"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsActive(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsActive(false);
              }}
            >
              {allUser &&
                allUser.map((option, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        onClick={(e) => {
                          setSelected(option.name);
                          setIsActive(false);
                          onStatusChange(option.name); // call the callback function
                        }}
                        className="dropdown-item"
                      >
                        <div className="dropdown-options">
                          {option.name === selected ? (
                            <img src={option.activeIcon} alt="icon" />
                          ) : (
                            <img src={option.icon} alt="icon" />
                          )}

                          <p
                            className={`${
                              option.name === selected
                                ? "option-active"
                                : "option-normal"
                            }`}
                          >
                            {option.name}
                          </p>
                        </div>

                        {option.name === selected && (
                          <div className="dropdown-selct-tick">
                            <span className="tick-mark">✓</span>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StatusDropdown;
