import React, { Fragment, useEffect, useState } from "react";
import MyProfile from "../MyProfile";
import TypeDropdown from "../../Dropdown/TypeDropdown";
import { servicePost } from "../../../helpers/api";
import { useNavigate, useParams } from "react-router-dom";

const UserCollection = () => {
  const [collectionData, setCollectionData] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();

  const { walletId } = useParams();

  const getUserCollection = async () => {
    try {
      const collectionRes = await servicePost(
        "api/contract/getContractDataByOwner",
        {
          ownerAddress: walletId,
        }
      );

      setCollectionData(collectionRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserCollection();
  }, []);

  const goToContractPage = (data) => {
    navigate("/collection/items", {
      state: data,
      replace: true,
    });
  };

  const filterTokenData = () => {
    const filterData =
      collectionData &&
      collectionData.filter((ele) => {
        var type = selectedType && selectedType !== "All" ? selectedType : "";

        if (type == "") {
          return true;
        } else if (type) {
          return ele.contractType === type;
        }
        return false;
      });

    // Apply search filter based on tokenName
    const searchKeyword = searchValue.trim().toLowerCase();
    const searchedData = filterData.filter((ele) =>
      ele.contractName.toLowerCase().includes(searchKeyword)
    );

    setFilteredData(searchedData);
  };

  useEffect(() => {
    filterTokenData();
  }, [collectionData, selectedType, searchValue]);

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasProfileFilter"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <div className="profile-mobile-filter-wrapper">
              <div className="profile-mobile-filter-wrap">
                <div className="drawer-cancel-btn">
                  <button data-bs-dismiss="offcanvas" aria-label="Close">
                    <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                  </button>
                </div>
                <h2 className="drawer-heading"> Filters </h2>
                <TypeDropdown onTypeChange={handleTypeChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-bg-wrapper">
        <MyProfile />
        <div className="profile-owned-main-wraper custom-container">
          <div className="profile-owned-filter-wrap">
            <div className="activity-owned-nft-search">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <img
                className="search-owned-icon"
                src="/images/icons/search-icon.svg"
                alt="icon"
              />
            </div>
            <div className="filter-mobile-view">
              <img
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasProfileFilter"
                aria-controls="offcanvasRight"
                src="/images/icons/filter-icon.svg"
                alt="icon"
              />
            </div>
            <div className="filter-desktop-view">
              {/* <StatusDropdown /> */}
              <TypeDropdown onTypeChange={handleTypeChange} />
            </div>
          </div>

          <div className="profile-collection-main-wrapper">
            <div className="profile-collection-main-wrap">
              {filteredData?.length > 0 &&
                filteredData?.map((data, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="profile-collection-repeat" key={index}>
                        <div
                          className="profile-collection-wrap"
                          onClick={() => goToContractPage(data)}
                        >
                          <div className="collection-image-main-wrap">
                            <div className="collection-bg-image">
                              {data.contractCoverImage ? (
                                <img src={data.contractCoverImage} alt="img" />
                              ) : (
                                <img
                                  src="/images/default-cover.png"
                                  alt="img"
                                />
                              )}
                            </div>
                            <div className="collection-image-wrap">
                              {data.contractImage ? (
                                <img src={data.contractImage} alt="img" />
                              ) : (
                                <img src="/images/icons/avtar.png" alt="img" />
                              )}
                            </div>
                          </div>

                          <div className="collection-info-wrapper">
                            <div className="collection-name-wrap">
                              <h3> {data.contractName}</h3>
                            </div>
                            <div className="collection-info-wrap">
                              <div className="colection-floor-wrap">
                                <h4> Floor </h4>
                                <p> {data.floorPrice}</p>
                              </div>
                              <div className="colection-floor-wrap">
                                <h4> Volume </h4>
                                <p> {data.volume}</p>
                              </div>
                              <div className="colection-floor-wrap">
                                <h4> Owners </h4>
                                <p> {data.owners}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </div>
            {filteredData?.length === 0 && (
              <div className="empty-card-main-wrap">
                <div className="empty-card-image">
                  <img src="/images/icons/empty-box.png" alt="empty" />
                  <p>Nothing Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCollection;
