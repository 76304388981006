import { SET_CONTRACT, SET_TASK_DEP } from "../actionTypes";
const initialState = {
  Contract: null,
  taskDep: 0,
};
export function contract(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_CONTRACT:
      return {
        ...state,
        Contract: action.Contract,
      };

    case SET_TASK_DEP: {
      return {
        ...state,
        taskDep: payload,
      };
    }

    default:
      return state;
  }
}
