import React from "react";
import Modal from "react-bootstrap/Modal";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";

const CreactApprove = (props) => {
  return (
    <Modal
      {...props}
      dialogClassName="connecting-account-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="report-account-main-wrapper">
          <div className="report-account-main-wrap">
            <div className="contract-modal-wrap">
              {/* <img src="/images/icons/confirm-tick.png" alt="icon" /> */}
              <div className="contract-modal-contant">
                <div className="contract-deploy-step-wrap">
                  <img src="/images/icons/confirm-tick.png" alt="icon" />
                  <div className="contract-deploye-contact">
                    <h3> Approve </h3>
                    <p> Approve performing transactions with your wallet </p>
                  </div>
                </div>

                <div className="contract-modal-sign-wrap">
                  <div className="contract-lottie-file">
                    <Lottie animationData={LoadingLottie} loop={true} />
                  </div>
                  <div className="contract-deploye-contact">
                    <h3> Upload files & Mint token </h3>
                    <p> Upload files & Mint token </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreactApprove;
