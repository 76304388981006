import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { servicePost } from "../../helpers/api";
import moment from "moment";
import CollectibleCard from "../Cards/CollectibleCard";

const NFTLIST = [
  {
    image: "/images/nft-1.jpg",
    ownerImg: "/images/nft-1.jpg",
    ownerName: "John Butler",
    price: "0.382",
    status: "Buy Now",
    collectionTyple: "single",
  },
  {
    image: "/images/nft-4.jpg",
    ownerImg: "/images/nft-1.jpg",
    ownerName: "Brain Hands",
    price: "0.382",
    status: "Buy Now",
    collectionTyple: "multiple",
  },
  {
    image: "/images/nft-6.jpg",
    ownerImg: "/images/nft-1.jpg",
    ownerName: "VR Magnet",
    price: "0.382",
    status: "Place a bid",
    collectionTyple: "single",
    endDate: "0d 1h 3m 42s",
  },
  {
    image: "/images/nft-3.jpg",
    ownerImg: "/images/nft-1.jpg",
    ownerName: "Abstraction",
    price: "0.382",
    status: "Buy Now",
    collectionTyple: "single",
  },
  {
    image: "/images/nft-5.jpg",
    ownerImg: "/images/nft-1.jpg",
    ownerName: "John Butler",
    price: "0.382",
    status: "Buy Now",
    collectionTyple: "single",
  },
];

const NFTList = () => {
  const [allTokens, setAllTokens] = useState([]);

  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(true);

  const navigate = useNavigate();

  // const CustomPrevArrow = (props) => (
  //   <div {...props} className="slick-arrow slick-prev">
  //     <img src="/images/icons/pre-arrow.png" alt="Previous" />
  //   </div>
  // );

  // const CustomNextArrow = (props) => (
  //   <div {...props} className="slick-arrow slick-next">
  //     <img src="/images/icons/next-arrow.png" alt="Next" />
  //   </div>
  // );

  const CustomPrevArrow = (props) => (
    <div
      {...props}
      className={`slick-arrow slick-prev ${showPrevArrow ? "" : "hide-arrow"}`}
    >
      <img src="/images/icons/pre-arrow.png" alt="Previous" />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div
      {...props}
      className={`slick-arrow slick-next ${showNextArrow ? "" : "hide-arrow"}`}
    >
      <img src="/images/icons/next-arrow.png" alt="Next" />
    </div>
  );

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },

      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow showPrevArrow={showPrevArrow} />,
    nextArrow: <CustomNextArrow showNextArrow={showNextArrow} />,
    beforeChange: (currentSlide, nextSlide) => {
      setShowPrevArrow(nextSlide !== 0);
      setShowNextArrow(nextSlide !== NFTLIST.length - 1);
    },
  };

  const getNftTokens = async () => {
    try {
      const alltokens = await servicePost("api/nft/getalltoken");
      setAllTokens(alltokens.data);
    } catch (error) {
      console.log("error", error);
    }

    // let collectionsList = [];
    // console.log("collectionsList", collectionsList);

    // if (alltokens.data.length > 0) {
    //   for (let i = 0; i <= alltokens.data.length - 1; i++) {
    //     console.log("alltokens.data.length", alltokens.data.length);
    //     collectionsList.push({
    //       tokenId: alltokens.data[i]?.tokenId,
    //       image: alltokens.data[i]?.image,
    //       fileType: JSON.parse(alltokens.data[i].fileType),
    //       tokenType: alltokens.data[i]?.tokenType,
    //       minterName: alltokens.data[i]?.minterName,
    //       owner: alltokens.data[i]?.owner,
    //       creator: alltokens.data[i]?.creator,
    //       sale: alltokens.data[i]?.sale,
    //       royalities: alltokens.data[i]?.royalities,
    //       transactionHash: allTokens.data[i]?.transactionHash,
    //       tokenCount: allTokens.data[i]?.tokenCount,
    //       tokenName: alltokens.data[i]?.tokenName,
    //       description: alltokens.data[i]?.description,
    //       price: alltokens.data[i]?.price,
    //       tokenOf: alltokens.data[i]?.tokenOf,
    //       auctionId: alltokens.data[i]?.auctionId,
    //       instantBuy: alltokens.data[i]?.instantBuy,
    //       endDate: alltokens.data[i]?.endDate,
    //       numberOfCopy: alltokens.data[i]?.numberOfCopy,
    //       copyRemaining: alltokens.data[i]?.copyRemaining,
    //       numberOfCopyOnSale: alltokens.data[i]?.numberOfCopyOnSale,
    //       multiple: alltokens.data[i]?.tokenType == "single" ? false : true,
    //       filterUserOwner: alltokens.data[i]?.filterUserOwner,
    //     });
    //   }

    //   setAllTokens(collectionsList);
    // }
  };

  useEffect(() => {
    getNftTokens();
  }, []);

  return (
    <>
      <div className="home-nft-main-wrapper custom-container">
        <div className="home-nft-main-wrap">
          <div className="hot-contant-wrap">
            <h3> Hot </h3>
            <img src="/images/icons/fire.png" alt="img" />
          </div>

          <Slider {...settings}>
            {allTokens?.length > 0 &&
              allTokens.map((data, index) => {
                return (
                  <Fragment key={index}>
                    <CollectibleCard card={data} />
                  </Fragment>
                );
              })}
          </Slider>
          {allTokens?.length === 0 && (
            <div className="user-empty-box-wrap">
              <img src="/images/icons/empty-box.png" alt="icon" />
              <p> No NFTS Found</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NFTList;
