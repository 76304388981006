import React, { useEffect, useState } from "react";
import ReportModal from "../Modals/ReportModal";
import { servicePost } from "../../helpers/api";
import { web3 } from "../../constants/constants";

const ReportDrawer = () => {
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportMsg, setReportMsg] = useState("");

  const sumbitReportFun = async () => {
    try {
      if (reportMsg == "") {
        alert("Please enter message");
        return;
      }
      const account = await web3.eth.getAccounts();
      const reportRes = await servicePost("api/report/submitReport", {
        walletAddress: account[0],
        description: reportMsg,
      });

      document.getElementById("closeReportDrawer").click();
      setShowReportModal(true);
      setTimeout(() => {
        setShowReportModal(false);
      }, 3000);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <ReportModal
        show={showReportModal}
        onHide={() => setShowReportModal(false)}
      />

      <span
        id="closeReportDrawer"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></span>

      <div className="report-drawer-main-wrapper">
        <div className="drawer-cancel-btn">
          <button data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
          </button>
        </div>
        <div className="report-drawer-header">
          <h2 className="drawer-heading">Why are you reporting?</h2>
          <p>
            Please provide details about the rule violations committed by this
            user on the site.
          </p>
        </div>

        <div className="report-drawer-wrapper">
          <div className="report-contant-wrap">
            <label> Description* </label>
            <textarea
              placeholder="Tell us some details"
              onChange={(e) => setReportMsg(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className="report-drawer-footer">
          <div className="report-drawer-button-wrap">
            <button onClick={sumbitReportFun} className="btn-style">
              Proceed
            </button>
            <button className="btn-style cancel-btn-style"> Cancel </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportDrawer;
