import React from "react";
import SettingNav from "./SettingNav";
import { Link } from "react-router-dom";
import { useState } from "react";
import AdvancedFeatureMsg from "../Modals/AdvancedFeatureMsg";
const EditWallet = () => {
  const [showConnectModal, setShowConnectmodal] = useState(false);
  return (
    <>
    <AdvancedFeatureMsg
        show={showConnectModal}
        onHide={() => setShowConnectmodal(false)}
      />
      <div className="edit-profile-bg-wrapper">
        <SettingNav />

        <div className="wallet-setting-main-wrapper custom-container">
          <div className="manage-wallet-wrapper">
            <div className="manage-wallet-contant">
              <h3> Manage Wallet </h3>
              <p>
                Add one or more wallets to showcase all your NFTs in one place.
              </p>
            </div>
          </div>
          <div className="wallet-setting-main-wrap">
            <div className="wallet-setting-left">
              <div className="wallet-repeat-wrapper">
                <div className="wallet-address-info-wrapper">
                  <div className="wallet-address-info">
                    <img
                      className="ethereum-icon"
                      src="/images/icons/ethereum.png"
                      alt="icon"
                    />
                    <div className="wallet-address-wrapper">
                      <div className="wallet-address-wrap">
                        <h3> 0xfa7bec5...268e </h3>
                        <img src="/images/icons/copy-icon.png" alt="icon" />
                      </div>
                      <p> BNB </p>
                    </div>
                  </div>
                  <span tooltip="View on Etherscan" flow="down">
                    <Link
                      to="https://etherscan.io/address/0x821933a74439ca1fcc651d747c0f1af24014a934"
                      target="_blank"
                    >
                      <img src="/images/icons/etherscan.svg" alt="img" />
                    </Link>
                  </span>
                </div>
                <div className="wallet-connected-active">
                  <img src="/images/icons/wallect-active-icon.png" alt="icon" />
                </div>
              </div>

              <div className="wallet-repeat-wrapper">
                <div className="wallet-address-info-wrapper">
                  <div className="wallet-address-info">
                    <img src="/images/icons/polygon.png" alt="icon" />
                    <div className="wallet-address-wrapper">
                      <div className="wallet-address-wrap">
                        <h3> 0xfa7bec5......268e </h3>
                        <img src="/images/icons/copy-icon.png" alt="icon" />
                      </div>
                      <p> Polygon </p>
                    </div>
                  </div>
                  <span tooltip="View on Polygonscan" flow="down">
                    <Link
                      to="https://polygonscan.com/address/0x821933a74439ca1fcc651d747c0f1af24014a934"
                      target="_blank"
                    >
                      <img src="/images/icons/pol-scan.svg" alt="img" />
                    </Link>
                  </span>
                </div>
                <div className="wallet-connected-active">
                  <img src="/images/icons/disconned-wallet.png" alt="icon" />
                </div>
              </div>

              <div className="link-wallet-btn-wrap desktop-view">
                <button className="btn-style" onClick={() => setShowConnectmodal(true)}>
                   Link Wallet </button>
              </div>
            </div>

            <div className="wallet-setting-right">
              <div className="wallet-repeat-wrapper">
                <div className="wallet-address-info-wrapper">
                  <div className="wallet-address-info">
                    <img src="/images/icons/immutable.png" alt="icon" />
                    <div className="wallet-address-wrapper">
                      <div className="wallet-address-wrap">
                        <h3> 0xfa7bec5...268e </h3>
                        <img src="/images/icons/copy-icon.png" alt="icon" />
                      </div>
                      <p> Immutable X </p>
                    </div>
                  </div>
                  <span tooltip="View on Immutascan" flow="down">
                    <Link
                      to="https://immutascan.io/address/0x821933a74439ca1fcc651d747c0f1af24014a934"
                      target="_blank"
                    >
                      <img src="/images/icons/immute-scan.svg" alt="img" />
                    </Link>
                  </span>
                </div>
                <div className="wallet-connected-active">
                  <img src="/images/icons/disconned-wallet.png" alt="icon" />
                </div>
              </div>

              <div className="link-wallet-btn-wrap mobile-view">
                <button className="btn-style"> Link Wallet </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWallet;
