import React, { useState } from "react";
import SettingNav from "./SettingNav";
import AccountDeleteModal from "../Modals/AccountDeleteModal";

const EditAccount = () => {
  const [showDeleteModal, setShowDeletemodal] = useState(false);

  return (
    <>
      <AccountDeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeletemodal(false)}
      />

      <div className="edit-profile-bg-wrapper">
        <SettingNav />
        <div className="account-main-wrapper">
          <div className="account-main-wrap">
            <img src="/images/icons/danger-zone.png" alt="img" />
            <h3> Danger zone </h3>
            <p>
              Once you proceed with deleting your account, there is no
              possibility of reversal. Please ensure your decision is final.
            </p>
            <div className="delete-account-button">
              <button onClick={() => setShowDeletemodal(true)}>
                Delete my account
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAccount;
