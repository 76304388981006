import React from "react";
import { NavLink } from "react-router-dom";

const SettingNav = () => {
  return (
    <>
      <div className="setting-nav-wrapper custom-container">
        <div className="setting-nav-wrap">
          <ul>
            <li>
              <NavLink to="/setting/profile" activeClassName="isActive">
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink to="/setting/account" activeClassName="isActive">
                Account
              </NavLink>
            </li>
            <li>
              <NavLink to="/setting/walltes" activeClassName="isActive">
                Wallets
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SettingNav;
