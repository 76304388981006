import React, { Fragment, useEffect, useState } from "react";
import { serviceGet, servicePost } from "../../helpers/api";
import { useDispatch, useSelector } from "react-redux";
import FollowCard from "../Cards/FollowCard";

const FollowerDrawer = ({ walletId }) => {
  const [followerList, setFollowerList] = useState([]);

  //get user from localstorage...
  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  // Get Dep from Redux
  const taskDep = useSelector(
    (state) => state.contract?.taskDep && state.contract.taskDep.Dep1
  );

  const getFollowersList = async () => {
    try {
      const followerData = await servicePost("api/followLink/followerList", {
        userId: walletId,
      });
      setFollowerList(followerData.followers);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getFollowersList();
  }, [walletId, taskDep]);

  return (
    <div className="follower-drawer-main-wrapper">
      <div className="drawer-cancel-right-btn">
        <button data-bs-dismiss="offcanvas" aria-label="Close">
          <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
        </button>
      </div>
      <h2 className="drawer-heading"> Followers </h2>

      <div className="follower-main-wrapper">
        {followerList?.length > 0 ? (
          followerList.map((data, index) => {
            return (
              <Fragment key={index}>
                <FollowCard data={data} />
              </Fragment>
            );
          })
        ) : (
          <div className="empty-follower-card">
            <div className="empty-card-image">
              <img src="/images/icons/empty-box.png" alt="empty" />
              <p>Nothing Found</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FollowerDrawer;
