import React from "react";
import FeedNav from "../FeedNav";

const MyPost = () => {
  return (
    <div className="feed-bg-wrapper">
      <div className="mypost-main-wrapper custom-container">
        <FeedNav activeTab="My Posts" />
        <div className="mypost-main-wrap">
          <h1> Feed</h1>
        </div>
      </div>
    </div>
  );
};

export default MyPost;
