import React from "react";

const COMMENTLIST = [
  {
    profile: "/images/profile.jpg",
    name: "Anna",
    comment:
      "Anna Great post! It is essential to have the right toolkit for content creators so that we can save a ton of time and make the best possible content for our audiences. Without the right tools, your content will look unprofessional and not perform as well. Love these tools. Can't wait to give them a try. Saved ✅️",
  },
];

const PostCommantDrawer = () => {
  return (
    <div className="post-comment-drawer-wrapper">
      <div className="post-comment-drawer-wrap">
        <div className="post-main-image-wrapper">
          <img src="/images/feed-1.jpg" alt="img" />
          <div className="drawer-cancel-btn">
            <button data-bs-dismiss="offcanvas" aria-label="Close">
              <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
            </button>
          </div>
        </div>

        <div className="post-comment-right-wrapper">
          <div className="post-comment-header">
            <div className="post-profile-wrap">
              <img src="/images/profile.jpg" alt="avtar" />
              <div className="feed-user-detail-wrap">
                <h3> Alessandro </h3>
                <p> 17h </p>
              </div>
            </div>
          </div>

          <div className="post-drawer-mesaage-wrap">
            <p>
              To the outside world, creating posts for social media may seem
              easy. But, content creators know there’s a lot more to social
              media content creation and editing than meets the eye,
              particularly in today’s social media landscape. #design_important
              #graphicdesignjoint #photoshoptutorials #brandstrategy
              #designingartz #designthinking #dribbble #designtips
              #digitaldesign #tutorialsvideos #photoshopmanipulationtutorial
            </p>
          </div>

          <div className="post-drawer-comment-section">
            <div className="post-drawer-comment-wrap">
              <h3> Comments </h3>

              <div className="post-drawer-comments-repeat">
                <img src="/images/profile.jpg" alt="img" />
                <p>
                  <span> Anna </span> To the outside world, creating posts for
                  social media may seem easy.
                </p>
              </div>
            </div>
          </div>

          <div className="post-drawer-footer-wrapper">
            <div className="feed-socail-media-wrap">
              <div className="feed-socail-left">
                <div className="feed-socail-like-wrap">
                  <img src="/images/icons/like-active.svg" alt="icon" />
                  <p> 27 likes</p>
                </div>

                <div className="feed-socail-like-wrap">
                  <img src="/images/icons/feed-comment.svg" alt="icon" />
                  <p> 10 comments</p>
                </div>
              </div>
              <div className="feed-socail-right">
                <div className="feed-socail-like-wrap">
                  <img src="/images/icons/feed-share.svg" alt="icon" />
                  <p> 2 </p>
                </div>
              </div>
            </div>

            <div className="post-commenet-input-wrap">
              <input type="text" placeholder="Add a comment..." />
              <div className="sent-coment-btn">
                <img src="/images/icons/send-btn.png" alt="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCommantDrawer;
