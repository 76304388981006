import React, { useEffect, useState } from "react";
import { serviceGet, servicePost } from "../../helpers/api";
import { useNavigate } from "react-router-dom";

const Users = ({ selectedCategory }) => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  //get user from localstorage...
  const user = JSON.parse(localStorage.getItem("user"));
  const walletId = user && user.walletId;

  const getUserByCategories = async () => {
    try {
      const userList = await servicePost("user/getUsersByCategory", {
        category: selectedCategory,
      });
      setUsers(userList.users);
    } catch (error) {
      console.log("error", error);
      setUsers([]);
    }
  };

  useEffect(() => {
    getUserByCategories();
  }, [selectedCategory]);

  const goToUserProfile = (data) => {
    if (data.walletId == walletId) {
      navigate("/profile/owned");
    } else {
      navigate(`/user/${data.walletId}/owned`);
    }
  };

  return (
    <>
      <div className="userlist-main-wrapper custom-container">
        {users?.length === 0 ? (
          <div className="user-empty-box-wrap">
            <img src="/images/icons/empty-box.png" alt="icon" />
            <p> No User Found</p>
          </div>
        ) : (
          <div className="userlist-main-wrap">
            {users?.map((data, index) => {
              return (
                <div className="userlist-card-repeat" key={index}>
                  <div
                    className="userlist-card-wrap"
                    onClick={() => goToUserProfile(data)}
                  >
                    <div className="userlist-card-image">
                      {data.profileImageUrl ? (
                        <img src={data.profileImageUrl} alt="img" />
                      ) : (
                        <img src="/images/icons/avtar.png" alt="img" />
                      )}
                    </div>
                    <div className="userlist-card-info">
                      <div className="user-verified-wrap">
                        <h3>
                          {data.name
                            ? data.name
                            : data.walletId
                            ? data.walletId.slice(0, 8)
                            : "-"}
                        </h3>
                        {data.verified == true && (
                          <img src="/images/icons/verified.png" alt="icon" />
                        )}
                      </div>

                      <h5> Followers</h5>
                      <p> {data.Follower} </p>
                    </div>
                    <div className="userlist-card-info">
                      <h3 className="empty-text"> x </h3>
                      <h5> Following</h5>
                      <p> {data.Following} </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* <div className="see-more-button">
          <button className="btn-style">See more</button>
        </div> */}
      </div>
    </>
  );
};

export default Users;
