import React, { Fragment, useEffect, useState } from "react";
import { serviceGet } from "../../helpers/api";

const CategoryFilter = ({ onCategoryChange }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [isCategoryActive, setIsCategoryActive] = useState(false);
  const [categorySelected, setCategorySelected] = useState("All");

  const getAllCategories = async () => {
    try {
      const categoryRes = await serviceGet("api/category/getAllCategory");
      setCategoryList(categoryRes.categories);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <>
      <div className="select-box-wrapper">
        <div className="react-dropdown">
          <div
            className="react-dropdown-btn"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsCategoryActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsCategoryActive(false);
            }}
          >
            <div className="selected-item">
              <p>
                Category: <span> {categorySelected} </span>
              </p>
            </div>

            <img
              className={
                isCategoryActive
                  ? "dropdown-down-arrow rotate-arrow"
                  : "dropdown-down-arrow"
              }
              src="/images/icons/dropdown-arrow.svg"
              alt="arrow"
            />
          </div>
          <div
            className="dropdown-space-manage"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsCategoryActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsCategoryActive(false);
            }}
          ></div>
          {isCategoryActive && (
            <div
              className="dropdown-content"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsCategoryActive(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsCategoryActive(false);
              }}
            >
              <div
                onClick={(e) => {
                  setCategorySelected("All");
                  setIsCategoryActive(false);
                  onCategoryChange("All");
                }}
                className="dropdown-item"
              >
                <div className="dropdown-options category-option-images">
                  <img src="/images/icons/all-cat.png" alt="icon" />
                  <p
                    className={`${
                      "All" === categorySelected
                        ? "option-active"
                        : "option-normal"
                    }`}
                  >
                    All
                  </p>
                </div>

                {"All" === categorySelected && (
                  <div className="dropdown-selct-tick">
                    <span className="tick-mark">✓</span>
                  </div>
                )}
              </div>
              {categoryList &&
                categoryList.map((option, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        onClick={(e) => {
                          setCategorySelected(option.categoryName);
                          setIsCategoryActive(false);
                          onCategoryChange(option.categoryName);
                        }}
                        className="dropdown-item"
                      >
                        <div className="dropdown-options category-option-images">
                          <img src={option.categoryImage} alt="icon" />
                          <p
                            className={`${
                              option.categoryName === categorySelected
                                ? "option-active"
                                : "option-normal"
                            }`}
                          >
                            {option.categoryName}
                          </p>
                        </div>

                        {option.categoryName === categorySelected && (
                          <div className="dropdown-selct-tick">
                            <span className="tick-mark">✓</span>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryFilter;
