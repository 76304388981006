import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CreateContract from "../Drawers/CreateContract";
import SelectContract from "../Drawers/SelectContract";

import ipfs from "../../functions/Ipfs";
import { useLocation, useNavigate } from "react-router-dom";
import { audioUrl } from "../../constants/constants";

import PreviewCard from "./PreviewCard";
import MultipleForm from "./MultipleForm";

const CreateMultiple = () => {
  const [copyAmount, setCopyAmount] = useState(0);
  const [uploadedImage, setUploadedImage] = useState("");
  const [instant, setInstant] = useState(true);
  const [sale, setSale] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [hash, setHash] = useState("");
  const [fileType, setFileType] = useState("");
  const [createdData, setCreatedData] = useState("");
  const [price, setPrice] = useState(0);
  const [name, setName] = useState("");
  const location = useLocation();

  useEffect(() => {
    const receivedState = location.state;
    setCreatedData(receivedState);
  }, []);

  // Callback function to receive data from the child component
  const handleFormSubmission = (data) => {
    setName(data);
  };

  // Get User from localStorage....
  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);

  // Handle Step Go Next Step or Not
  const handleProceedClick = () => {
    // if (!uploadedImage) {
    //   alert("Please upload NFT image");
    //   return;
    // }
    if (sale == true) {
      if (price == "") {
        alert("Please Enter Amount");
        return;
      }
    }
    if (instant == false) {
      if (selectedDate == null) {
        alert("Please select end date..");
        return;
      }
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // handle Date Picker...
  const datepickerRef = useRef(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleIconClick = () => {
    if (datepickerRef.current) {
      datepickerRef.current.setOpen(true);
    }
  };

  // HighLight if selected
  const BuyFun = () => {
    setInstant(true);
  };

  const AuctionFun = () => {
    setInstant(false);
  };

  // Upload Image and Get Hash and fileType...
  const handleImageUpload = async (e) => {
    let file = e.target.files[0];
    let fileSize = file.size / 1048576;
    if (fileSize > 30) {
      alert("File exceeds the maximum size 30MB");
      return;
    }
    if (file.type.indexOf("image") > -1 || file.type.indexOf("video") > -1) {
      if (file.type.indexOf("video") > -1) {
        setFileType({ type: "video", fileType: file.type });
      }
      if (file.type.indexOf("image") > -1) {
        setFileType({ type: "image", fileType: file.type });
      }
      if (e.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          setUploadedImage(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        const result = await ipfs.add(e.target.files[0]);

        setHash(result.path);
      }
    }
    if (file.type.indexOf("audio") > -1) {
      setFileType({ type: "audio", fileType: file.type });
      setUploadedImage(audioUrl);

      if (e.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          setUploadedImage(audioUrl);
        };
        reader.readAsDataURL(e.target.files[0]);
        //console.log(e.target.files[0]);
        const result = await ipfs.add(e.target.files[0]);
        // console.log(result);
        setHash(result.path);
        // setHash(e.target.result);
      }
    }
  };

  // Increment and Decrement Number
  const incrementAmount = () => {
    setCopyAmount(Number(copyAmount) + 1);
  };

  const decrementAmount = () => {
    if (copyAmount > 1) {
      setCopyAmount(Number(copyAmount) - 1);
    }
  };

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasContractRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <CreateContract collectionType="Multiple" />
          </div>
        </div>
      </div>

      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasSelectContractRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <SelectContract collectionType="Multiple" />
          </div>
        </div>
      </div>

      {!createdData ? (
        <div className="create-bg-wrapper">
          <div className="create-nft-main-wrapper custom-container">
            <div className="create-nft-main-wrap">
              <div className="create-nft-left">
                {currentStep === 1 && (
                  <div className="create-nft-first-step">
                    <div className="create-header-wrap">
                      <h2>
                        <h2> Create Mutiple Collectible </h2>
                      </h2>
                      <div className="verified-pages-wrap">
                        <h4> 1 </h4>
                        <div className="pages-dots-wrap">
                          <div className="dots-wrap active"></div>
                          <div
                            className="dots-wrap"
                            onClick={handleProceedClick}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="create-upload-main-wrap">
                      <div className="create-upload-wrap">
                        <h3> Upload file </h3>
                        <div className="create-upload-box-wrap">
                          {uploadedImage !== "" ? (
                            <div>
                              <div className="drawer-cancel-right-btn">
                                <button onClick={() => setUploadedImage("")}>
                                  <img
                                    src="/images/icons/drawer-cross-icon.svg"
                                    alt="icon"
                                  />
                                </button>
                              </div>
                              <div className="upload-image-wrap">
                                <img src={uploadedImage} alt="picked file" />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="upload-image-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="72.117"
                                  height="72.134"
                                  viewBox="0 0 72.117 72.134"
                                >
                                  <defs>
                                    <linearGradient
                                      id="linear-gradient"
                                      y1="0.129"
                                      x2="0.939"
                                      y2="0.902"
                                      gradientUnits="objectBoundingBox"
                                    >
                                      <stop offset="0" stop-color="#f0f" />
                                      <stop offset="1" stop-color="orange" />
                                    </linearGradient>
                                  </defs>
                                  <g
                                    id="Component_75_1"
                                    data-name="Component 75 – 1"
                                    transform="translate(0.497 0.495)"
                                  >
                                    <path
                                      id="Union_16"
                                      data-name="Union 16"
                                      d="M35.542,71.127v-.065c-5.365,0-10.725.057-16.082-.029-2.989-.043-6.023-.165-8.8-1.437C5.14,67.075,1.555,62.95.527,56.828A54.662,54.662,0,0,1,.017,47.7c0-4.718-.057-9.446.05-14.167.079-3.377.28-6.783,1.969-9.829,2.752-4.963,6.79-8.189,12.543-8.994a67.019,67.019,0,0,1,9.221-.5c8.282-.007,16.557-.029,24.843.022,3.432.014,6.917-.072,10.229.982a16.2,16.2,0,0,1,11.51,12.308c.917,3.868.683,7.832.719,11.762.034,4.06.034,8.119-.057,12.169a23.333,23.333,0,0,1-1.174,8.27c-2.333,5.885-6.529,9.671-12.859,10.835-3.794.695-7.638.51-11.467.56q-1.918.018-3.846.019C39.647,71.139,37.595,71.127,35.542,71.127ZM32.884,39.6c0,4.5.01,9,0,13.5a2.808,2.808,0,0,0,.683,1.99,2.682,2.682,0,0,0,2.953.738,2.727,2.727,0,0,0,1.7-2.62c.01-4.584,0-9.168.01-13.753a1.306,1.306,0,0,1,.165-.927c1.384,1.4,2.723,2.774,4.079,4.124a2.621,2.621,0,0,0,3.854.122,2.714,2.714,0,0,0-.1-3.873c-.544-.553-1.1-1.1-1.65-1.643-2.342-2.338-4.663-4.694-7.022-7.008a2.622,2.622,0,0,0-4.036.022c-.438.434-.869.865-1.3,1.293-2.457,2.46-4.922,4.9-7.357,7.379a2.631,2.631,0,0,0,1.1,4.5,2.674,2.674,0,0,0,2.788-.853c1.322-1.329,2.651-2.666,4.136-4.165ZM66.826,13.2A21.324,21.324,0,0,0,56.7,9.353a92.155,92.155,0,0,0-10.813-.481c-7.753,0-15.5-.036-23.263.022A46.642,46.642,0,0,0,11.065,9.987a21.5,21.5,0,0,0-9.877,5.9c-.165.165-.273.429-.6.438.453-3.384,1.164-6.654,3.2-9.485A14.609,14.609,0,0,1,12.9,1.2C18.329,0,23.855.043,29.373.019,35.07,0,40.766-.024,46.466.065A54.251,54.251,0,0,1,58.517,1.272C65.022,2.821,68.749,7,70.035,13.477c.187.941.326,1.89.527,3.054A26.086,26.086,0,0,0,66.826,13.2Z"
                                      stroke="rgba(0,0,0,0)"
                                      strokeWidth="1"
                                      fill="url(#linear-gradient)"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <p>
                                Png,Gifp4 or Mp3. <span> Max30mb </span>
                              </p>
                              <div className="create-nft-upload">
                                <input
                                  type="file"
                                  onChange={handleImageUpload}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        <div className="put-sale-main-wrapper">
                          <div className="put-sale-main-wrap">
                            <div className="put-sale-contant-wrap">
                              <h3> Put on sale </h3>
                              <p> You'll receive bids on this item </p>
                            </div>
                            <div className="put-sale-toggle-btn">
                              <label className="switch">
                                <input type="checkbox" />
                                <span
                                  className="slider"
                                  onClick={() => setSale(!sale)}
                                ></span>
                              </label>
                            </div>
                          </div>

                          {sale && (
                            <>
                              <div className="select-sale-option-wrapper">
                                <div className="select-sale-option-wrap">
                                  <div
                                    className={`${
                                      instant
                                        ? "buy-option-button active-option"
                                        : "buy-option-button"
                                    }`}
                                    onClick={BuyFun}
                                  >
                                    <img
                                      src="/images/icons/listing-active.svg"
                                      alt="icon"
                                    />
                                    <h3> Buy it Now </h3>
                                  </div>

                                  {/* <div
                                    className={`${
                                      !instant
                                        ? "buy-option-button active-option"
                                        : "buy-option-button"
                                    }`}
                                    // onClick={AuctionFun}
                                  >
                                    <img
                                      className="sale-aution-img"
                                      src="/images/icons/sale-aution.svg"
                                      alt="icon"
                                    />
                                    <h3> Timed aution </h3>
                                  </div> */}
                                </div>
                              </div>

                              {!instant ? (
                                <div className="select-expire-date">
                                  <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    ref={datepickerRef}

                                    // You can add more options and props as per your requirements
                                  />
                                  {selectedDate == null && (
                                    <div className="datepicker-placeholder">
                                      <p> Select Expiry Date </p>
                                    </div>
                                  )}

                                  <div className="datepicker-icon-wrap">
                                    <img
                                      onClick={handleIconClick}
                                      src="/images/icons/datepicker-icon.svg"
                                      alt="icon"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="select-expire-empty-space"></div>
                              )}

                              <div className="multiple-copies-wrapper coppies-input-for-mobile">
                                <div className="multiple-copies-wrap">
                                  <h3> Copies* </h3>
                                  <img
                                    src="/images/icons/copies-icon.png"
                                    alt="icon"
                                  />
                                </div>
                                <div className="coppies-input-wrap coppies-input-for-mobile">
                                  <input
                                    onChange={(e) =>
                                      setCopyAmount(e.target.value)
                                    }
                                    type="number"
                                    value={copyAmount}
                                    placeholder="Enter here"
                                  />
                                  <div className="coppies-amount-icons">
                                    <img
                                      onClick={incrementAmount}
                                      className="amount-up-arrow"
                                      src="/images/icons/up-arrow.svg"
                                      alt="icon"
                                    />
                                    <img
                                      onClick={decrementAmount}
                                      className="amount-down-arrow"
                                      src="/images/icons/down-arrow.svg"
                                      alt="icon"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="create-select-price-wrap">
                                <h3> Price </h3>
                                <input
                                  type="number"
                                  placeholder="Enter price"
                                  name="price"
                                  value={price}
                                  onChange={(e) => setPrice(e.target.value)}
                                />

                                <div className="price-eth-icon">
                                  <img
                                    src="/images/icons/eth-icon.png"
                                    alt="icon"
                                  />
                                  <h4> BNB </h4>
                                </div>
                                <p>
                                  Service fee <span> 2.5% </span>
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="create-continue-btn">
                          <button
                            onClick={handleProceedClick}
                            className="btn-style"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/*************** Section Step to create Nft  */}
                {currentStep === 2 && (
                  <div className="create-nft-second-step">
                    <MultipleForm
                      uploadedImage={uploadedImage}
                      sale={sale}
                      price={price}
                      instant={instant}
                      selectedDate={selectedDate}
                      hash={hash}
                      fileType={fileType}
                      setCurrentStep={setCurrentStep}
                      createdData={createdData}
                      copyQuantity={copyAmount}
                      onFormSubmit={handleFormSubmission}
                    />
                  </div>
                )}
              </div>

              <div className="create-nft-right">
                <PreviewCard
                  name={name}
                  uploadedImage={uploadedImage}
                  price={price}
                  fileType={fileType}
                />
                <div className="multiple-copies-wrapper">
                  <div className="multiple-copies-wrap">
                    <h3> Copies* </h3>
                    <img src="/images/icons/copies-icon.png" alt="icon" />
                  </div>
                  <div className="coppies-input-wrap">
                    <input
                      onChange={(e) => setCopyAmount(e.target.value)}
                      type="number"
                      value={copyAmount}
                      placeholder="Enter here"
                    />
                    <div className="coppies-amount-icons">
                      <img
                        onClick={incrementAmount}
                        className="amount-up-arrow"
                        src="/images/icons/up-arrow.svg"
                        alt="icon"
                      />
                      <img
                        onClick={decrementAmount}
                        className="amount-down-arrow"
                        src="/images/icons/down-arrow.svg"
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="create-bg-wrapper">
          <div className="create-nft-main-wrapper custom-container">
            <div className="create-nft-main-wrap">
              <div className="create-nft-left">
                {currentStep === 1 && (
                  <div className="create-nft-first-step">
                    <div className="create-header-wrap">
                      <h2> Create Mutiple Collectible </h2>
                      <div className="verified-pages-wrap">
                        <h4> 1 </h4>
                        <div className="pages-dots-wrap">
                          <div className="dots-wrap active"></div>
                          <div
                            className="dots-wrap"
                            onClick={handleProceedClick}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="create-upload-main-wrap">
                      <div className="create-upload-wrap">
                        <h3> Upload file </h3>
                        <div className="create-upload-box-wrap">
                          <div className="upload-image-wrap">
                            <img src={createdData.image} alt="picked file" />
                          </div>
                        </div>

                        <div className="put-sale-main-wrapper">
                          <div className="put-sale-main-wrap">
                            <div className="put-sale-contant-wrap">
                              <h3> Put on sale </h3>
                              <p> You'll receive bids on this item </p>
                            </div>
                            <div className="put-sale-toggle-btn">
                              <label className="switch">
                                <input type="checkbox" checked={true} />
                                <span
                                  className="slider"
                                  onClick={() => setSale(true)}
                                ></span>
                              </label>
                            </div>
                          </div>

                          <>
                            <div className="select-sale-option-wrapper">
                              <div className="select-sale-option-wrap">
                                <div
                                  className={`${
                                    instant
                                      ? "buy-option-button active-option"
                                      : "buy-option-button"
                                  }`}
                                  onClick={BuyFun}
                                >
                                  <img
                                    src="/images/icons/listing-active.svg"
                                    alt="icon"
                                  />
                                  <h3> Buy it Now </h3>
                                </div>

                                {/* <div
                                  className={`${
                                    !instant
                                      ? "buy-option-button active-option"
                                      : "buy-option-button"
                                  }`}
                                  // onClick={AuctionFun}
                                >
                                  <img
                                    className="sale-aution-img"
                                    src="/images/icons/sale-aution.svg"
                                    alt="icon"
                                  />
                                  <h3> Timed aution </h3>
                                </div> */}
                              </div>
                            </div>

                            {!instant ? (
                              <div className="select-expire-date">
                                <DatePicker
                                  selected={selectedDate}
                                  onChange={handleDateChange}
                                  dateFormat="dd/MM/yyyy"
                                  minDate={new Date()}
                                  ref={datepickerRef}

                                  // You can add more options and props as per your requirements
                                />
                                {selectedDate == null && (
                                  <div className="datepicker-placeholder">
                                    <p> Select Expiry Date </p>
                                  </div>
                                )}

                                <div className="datepicker-icon-wrap">
                                  <img
                                    onClick={handleIconClick}
                                    src="/images/icons/datepicker-icon.svg"
                                    alt="icon"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="select-expire-empty-space"></div>
                            )}

                            <div className="create-select-price-wrap">
                              <h3> Price </h3>
                              <input
                                type="number"
                                placeholder="Enter price"
                                name="price"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                              />

                              <div className="price-eth-icon">
                                <img
                                  src="/images/icons/eth-icon.png"
                                  alt="icon"
                                />
                                <h4> ETH </h4>
                              </div>
                              <p>
                                Service fee <span> 2.5% </span>
                              </p>
                            </div>
                          </>
                        </div>
                        <div className="create-continue-btn">
                          <button
                            onClick={handleProceedClick}
                            className="btn-style"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {currentStep === 2 && (
                  <div className="create-nft-second-step">
                    <MultipleForm
                      uploadedImage={createdData.image}
                      sale={true}
                      price={price}
                      instant={instant}
                      selectedDate={selectedDate}
                      hash={hash}
                      fileType={fileType}
                      setCurrentStep={setCurrentStep}
                      createdData={createdData}
                      copyQuantity={copyAmount}
                      onFormSubmit={handleFormSubmission}
                    />
                  </div>
                )}
              </div>

              <div className="create-nft-right">
                <PreviewCard
                  uploadedImage={createdData.image}
                  price={price}
                  name={createdData.tokenName}
                  fileType={fileType}
                />

                <div className="multiple-copies-wrapper">
                  <div className="multiple-copies-wrap">
                    <h3> Copies* </h3>
                    <img src="/images/icons/copies-icon.png" alt="icon" />
                  </div>
                  <div className="coppies-input-wrap">
                    <input
                      onChange={(e) => setCopyAmount(e.target.value)}
                      type="number"
                      value={copyAmount}
                      placeholder="Enter here"
                    />
                    <div className="coppies-amount-icons">
                      <img
                        onClick={incrementAmount}
                        className="amount-up-arrow"
                        src="/images/icons/up-arrow.svg"
                        alt="icon"
                      />
                      <img
                        onClick={decrementAmount}
                        className="amount-down-arrow"
                        src="/images/icons/down-arrow.svg"
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateMultiple;
