import React, { Fragment, useState } from "react";

const allUser = [
  {
    name: "All",
    icon: "/images/icons/type-all.svg",
  },
  {
    name: "Single",
    icon: "/images/icons/type-single.svg",
  },
  {
    name: "Multiple",
    icon: "/images/icons/type-multiple.svg",
  },
];

const TypeDropdown = ({ onTypeChange }) => {
  const [isTypeActive, setIsTypeActive] = useState(false);
  const [typeSelected, setTypeSelected] = useState("All");
  return (
    <>
      <div className="select-box-wrapper">
        <div className="react-dropdown">
          <div
            className="react-dropdown-btn"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsTypeActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsTypeActive(false);
            }}
          >
            <div className="selected-item">
              <p>
                Type: <span> {typeSelected} </span>
              </p>
            </div>

            <img
              className={
                isTypeActive
                  ? "dropdown-down-arrow rotate-arrow"
                  : "dropdown-down-arrow"
              }
              src="/images/icons/dropdown-arrow.svg"
              alt="arrow"
            />
          </div>
          <div
            className="dropdown-space-manage"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsTypeActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsTypeActive(false);
            }}
          ></div>
          {isTypeActive && (
            <div
              className="dropdown-content"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsTypeActive(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsTypeActive(false);
              }}
            >
              {allUser &&
                allUser.map((option, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        onClick={(e) => {
                          setTypeSelected(option.name);
                          setIsTypeActive(false);
                          onTypeChange(option.name); // Call callback function
                        }}
                        className="dropdown-item"
                      >
                        <div className="dropdown-options">
                          <img src={option.icon} alt="icon" />
                          <p
                            className={`${
                              option.name === typeSelected
                                ? "option-active"
                                : "option-normal"
                            }`}
                          >
                            {option.name}
                          </p>
                        </div>

                        {option.name === typeSelected && (
                          <div className="dropdown-selct-tick">
                            <span className="tick-mark">✓</span>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TypeDropdown;
