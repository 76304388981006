import React from "react";

const Contact = () => {
  return (
    <>
      <div className="contact-main-wrapper custom-container">
        <div className="contact-main-wrap">
          <div className="conact-main-left">
            <img src="/images/contact-img.png" alt="img" />
          </div>
          <div className="contact-main-right">
            <div className="conact-right-wrap">
              <h2> Stay In The Loop! </h2>
              <p> Stay Connected for the Latest Trending NFT Updates </p>
              <div className="email-input-wrap">
                <input type="email" placeholder="Enter your email" />
                <img
                  className="email-send-btn"
                  src="/images/icons/email-btn.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
