import React, { useRef, useEffect, Fragment, useState } from "react";
import MyProfile from "../MyProfile";
import StatusDropdown from "../../Dropdown/StatusDropdown";
import TypeDropdown from "../../Dropdown/TypeDropdown";
import { servicePost } from "../../../helpers/api";
import CollectibleCard from "../../Cards/CollectibleCard";
import { web3 } from "../../../constants/constants";

const Owned = () => {
  const [walletId, setWalletId] = useState();
  const [ownerTokenData, setOwnerTokenData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const scrollTargetRef = useRef(null);
  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  useEffect(() => {
    // Check if the ref is valid and the route contains the scrollTarget
    if (
      scrollTargetRef.current &&
      window.location.pathname === "/profile/owned/sell"
    ) {
      const targetElement = scrollTargetRef.current;
      const offset = 150;

      // Calculate the position to scroll to
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset - offset;

      // Perform the smooth scroll
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  const getOwnerTokens = async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      setWalletId(accounts[0]);
      const tokenByOwner = await servicePost("api/nft/tokenbyOwner", {
        ownerAddress: accounts[0],
      });

      // Update the state with the filtered data
      setOwnerTokenData(tokenByOwner.data);
    } catch (error) {
      // Handle error if any
      console.error("Error fetching owner tokens:", error);
    }
  };

  useEffect(() => {
    getOwnerTokens();
  }, [walletId]);

  const filterTokenData = () => {
    const filterData =
      ownerTokenData &&
      ownerTokenData.filter((ele) => {
        var status =
          selectedStatus && selectedStatus !== "All" ? selectedStatus : "";
        var type = selectedType && selectedType !== "All" ? selectedType : "";

        if (status == "" && type == "") {
          return true;
        } else if (status && !type) {
          return ele.status === status;
        } else if (!status && type) {
          return ele.tokenType === type;
        } else if (status && type) {
          return ele.status === status && ele.tokenType === type;
        }

        return false;
      });

    // Apply search filter based on tokenName
    const searchKeyword = searchValue.trim().toLowerCase();
    const searchedData = filterData.filter((ele) =>
      ele.tokenName.toLowerCase().includes(searchKeyword)
    );

    setFilteredData(searchedData);
  };

  useEffect(() => {
    filterTokenData();
  }, [ownerTokenData, selectedStatus, selectedType, searchValue]);

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasProfileFilter"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <div className="profile-mobile-filter-wrapper">
              <div className="profile-mobile-filter-wrap">
                <div className="drawer-cancel-btn">
                  <button data-bs-dismiss="offcanvas" aria-label="Close">
                    <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                  </button>
                </div>
                <h2 className="drawer-heading"> Filters </h2>
                <StatusDropdown onStatusChange={handleStatusChange} />
                <TypeDropdown onTypeChange={handleTypeChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-bg-wrapper">
        <MyProfile />
        <div className="profile-owned-main-wraper custom-container">
          <div className="profile-owned-filter-wrap">
            <div className="profile-owned-nft-search">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <img
                className="search-owned-icon"
                src="/images/icons/search-icon.svg"
                alt="icon"
              />
            </div>
            <div className="filter-mobile-view">
              <img
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasProfileFilter"
                aria-controls="offcanvasRight"
                src="/images/icons/filter-icon.svg"
                alt="icon"
              />
            </div>
            <div className="filter-desktop-view">
              <StatusDropdown onStatusChange={handleStatusChange} />
              <TypeDropdown onTypeChange={handleTypeChange} />
            </div>
          </div>

          <div
            className="profile-nft-main-wrapper"
            ref={scrollTargetRef}
            id="scrollTarget"
          >
            <div className="profile-nft-main-wrap">
              {filteredData.length > 0
                ? filteredData.map((data, index) => {
                    return <CollectibleCard card={data} />;
                  })
                : null}
            </div>

            {filteredData.length === 0 && (
              <div className="empty-card-main-wrap">
                <div className="empty-card-image">
                  <img src="/images/icons/empty-box.png" alt="empty" />
                  <p>Nothing Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Owned;
