import React from "react";
import CategoryFilter from "../Dropdown/CategoryFilter";

const CategoryFilterDrawer = () => {
  return (
    <div className="profile-mobile-filter-wrapper">
      <div className="profile-mobile-filter-wrap">
        <div className="drawer-cancel-btn">
          <button data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
          </button>
        </div>
        <h2 className="drawer-heading"> Filters </h2>
        <CategoryFilter />
      </div>
    </div>
  );
};

export default CategoryFilterDrawer;
