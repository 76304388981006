import React from "react";
import { NavLink } from "react-router-dom";

const Explore = () => {
  return (
    <>
      <div className="setting-nav-wrapper">
        <div className="setting-nav-wrap explore-nav-wrap">
          <ul>
            <li>
              <NavLink to="/explore/all/items" activeClassName="isActive">
                NFTs
              </NavLink>
            </li>
            <li>
              <NavLink to="/explore/all/collections" activeClassName="isActive">
                Collections
              </NavLink>
            </li>
            <li>
              <NavLink to="/explore/all/users" activeClassName="isActive">
                Users
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Explore;
