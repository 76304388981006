import React, { useEffect, useState } from "react";
import { web3 } from "../../constants/constants";
import { auctionContract } from "../../contractDetails/auction";
import { postContract, networkAddress } from "../../contractDetails/item";
import ipfs from "../../functions/Ipfs";
import CreactApprove from "../Modals/CreactApprove";
import { servicePost } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveContract } from "../../redux/actions/contract";
import { shortenWalletId } from "../../helpers/Normalization";
const ERC721Collectible = require("../../components/abis/ERC721Collectible.json");
const AuctionCollectible = require("../../components/abis/AuctionCollectible.json");

let tokenTableId;
const SingleForm = ({
  uploadedImage,
  sale,
  price,
  instant,
  selectedDate,
  hash,
  fileType,
  setCurrentStep,
  createdData,
  onFormSubmit,
}) => {
  const [selectedPlatform, setSelectedPlatform] = useState("ProNFT");
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [collectionHighLight, setCollectionHighLight] = useState();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    royalties: "",
  });

  var nowDate = new Date().getTime() / 1000;
  var endDate = selectedDate && selectedDate.getTime() / 1000;
  const sendDate = !instant ? endDate : nowDate;

  const { name, description, royalties } = formData;

  onFormSubmit(name);
  // Check contact selected or not
  const selectedContract = useSelector((state) => state.contract.Contract);
  const CheckContractAddress =
    selectedContract && selectedContract.contractAddress;

  const contractName = selectedContract && selectedContract.contractName;
  const [contractList, setContractList] = useState();
  const checkContract =
    contractList &&
    contractList?.filter((data) => {
      return data.contractType == "Single";
    })?.length > 0;
  const dispatch = useDispatch();

  // Check loggedIn user create any contract
  useEffect(() => {
    const getContractData = async () => {
      const account = await web3.eth.getAccounts();
      servicePost(`api/contract/getContractDataByOwner`, {
        ownerAddress: account[0],
      })
        .then((response) => {
          setContractList(response.data);
        })
        .catch((err) => {});
    };

    getContractData();
  }, []);

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  const navigate = useNavigate();

  // Get User from localStorage....
  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);

  const ProNFTCollection = () => {
    setCollectionHighLight(true);
    setSelectedPlatform("ProNFT");
    dispatch(saveContract(""));
  };

  // For Collectible (Collection)
  const putOnSaleForCollectible = async (values) => {
    const AuctionAbi = AuctionCollectible.abi;
    const Auctioncontract = await new web3.eth.Contract(
      AuctionAbi,
      "0xEbC68f64410400d6201e522b35F14E073a2329Bb"//"0xF45b5D833CB1f5B6d7CD782dAB5e2caDE072c687"
    );
    if (sale == true) {
      if (!instant && !selectedDate) {
        alert("Please select End Date");
        return;
      }

      if (price < 0.00001) {
        alert("Price cannot be less than .00001");
        return;
      }

      const ether = web3.utils.toWei(price, "ether");
      const accounts = await web3.eth.getAccounts();
      var today = new Date();
      today.setHours(today.getHours() + 1);
      var unix_time = today.getTime() / 1000;

      var repositoryAddress = createdData.tokenOf;
      //let price = 2;
      // toggleConfirmDialog();
      setShowApproveModal(true);

      var startDate = new Date().getTime() / 1000;
      var endPrice = web3.utils.toWei(price, "ether");
      var nowDate = new Date().getTime() / 1000;
      var endDate = selectedDate && selectedDate.getTime() / 1000;
      const sendDate = !instant ? endDate : nowDate;

      const doc = JSON.stringify({
        file: createdData.image,
        fileType: { type: "image", fileType: "image/jpeg" },
        sale,
        tokenType: "Single",
        minterName: userData.name,
        minterImage: userData.profileImageUrl,
        username: createdData.name,
        description: createdData.description,
        royalties: Number(createdData.royalties),
        bid: price,
      });

      let added = await ipfs.add(doc);

      await Auctioncontract.methods
        .createAuction(
          repositoryAddress,
          createdData.tokenId,
          createdData.tokenName,
          added.path,
          ether,
          endPrice,
          startDate.toFixed(0),
          sendDate.toFixed(0),
          Number(createdData.royalties)
        )
        .send({ from: accounts[0] })
        .then(async (val) => {
          // let auctionId = val.events.AuctionCreated.returnValues._auctionId;
          const fullHexData = val.logs[0].data; // Assuming receipt.logs[0].data is a valid hex string
          const last10Hex = fullHexData.slice(-10);
          // const auctionId = web3.utils.hexToNumberString(last10Hex);
          const auctionId = parseInt(last10Hex, 16);
          const auctionhash = val.transactionHash;
         // const auctionhash = val.transactionHash;
          const tokendataupdated = await servicePost(
            "api/updateCollectionNft",
            {
              productId: createdData.tokenId,
              tokenId: createdData.tokenId,
              auctionId: auctionId,
              tokenType: "Single",
              owner: accounts[0],
              startPrice: price,
              endPrice: price,
              price: price,
              sale: 1,
              royaltyFee: createdData.royalties,
              transactionHash: auctionhash,
              tokenCount: 1,
              tokenName: name,
              image: createdData.image,
              status: instant ? "Buy now" : "Live Auction",
              instantBuy: instant,
              repositoryAddress: createdData.tokenOf,
              startDate: startDate,
              endDate: sendDate.toFixed(0),
              auctionTitle: name,
            }
          );

          // Add activity Api Call
          const activityData = await servicePost(
            "api/activity/addContractActivity",
            {
              type: "Listing",
              itemImage: createdData.image,
              itemName: name,
              quantity: 1,
              price: price,
              from: accounts[0],
              to: "",
              ownerAddress: accounts[0],
              contractAddress: createdData.tokenOf,
            }
          );

          navigate("../collection/items", {
            state: {
              contractAddress: createdData.tokenOf,
              ownerAddress: accounts[0],
            },
          });
        });
    }
  };

  // For PRONFT
  const putOnSale = async () => {
    // const revertCopy = createdData.numberOfCopy > numberOfCount;
    if (sale == true) {
      if (!instant && !selectedDate) {
        alert("Please select End Date");
        return;
      }

      if (price < 0.00001) {
        alert("Price cannot be less than .00001");
        return;
      }

      setShowApproveModal(true);

      const ether = web3.utils.toWei(price, "ether");
      var today = new Date();
      today.setHours(today.getHours() + 1);
      var unix_time = today.getTime() / 1000;

      var repositoryAddress = await networkAddress();
      //let price = 2;
      // selectedDate.getTime() / 1000).toFixed(0).toString()
      var startDate = (new Date().getTime() / 1000).toFixed(0).toString();
      var endPrice = web3.utils.toWei(price, "ether");
      let nowDate = new Date().getTime() / 1000;
      var endDate = selectedDate && selectedDate.getTime() / 1000;
      const sendDate = !instant ? endDate : nowDate;

      const doc = JSON.stringify({
        file: createdData.image,
        fileType: { type: "image", fileType: "image/jpeg" },
        sale,
        tokenType: "Single",
        minterName: userData.name,
        minterImage: userData.profileImageUrl,
        username: createdData.name,
        description: createdData.description,
        royalties: createdData.royalties,
        bid: price,
      });
      let added = await ipfs.add(doc);

      const auction = await auctionContract();
      const accounts = await web3.eth.getAccounts();

      await auction.methods
        .createAuction(
          repositoryAddress,
          createdData.tokenId,
          "PRONFT",
          added.path,
          ether,
          endPrice,
          startDate,
          sendDate.toFixed(0),
          Number(royalties)
        )
        .send({ from: accounts[0] })
        .once("receipt", async (receipt) => {
          const fullHexData = receipt.logs[0].data; // Assuming receipt.logs[0].data is a valid hex string
          const last10Hex = fullHexData.slice(-10);
          // const auctionId = web3.utils.hexToNumberString(last10Hex);
          const auctionId = parseInt(last10Hex, 16);
          const auctionhash = receipt.transactionHash;

          const tokendataupdated = await servicePost("api/nft/updatetoken", {
            productId: createdData.tokenId,
            tokenId: createdData.tokenId,
            auctionId: auctionId,
            tokenType: "Single",
            owner: accounts[0],
            startPrice: price,
            endPrice: price,
            price: price,
            sale: 1,
            royaltyFee: createdData.royalties,
            transactionHash: auctionhash,
            tokenCount: 1,
            instantBuy: instant,
            tokenName: createdData.tokenName,
            image: createdData.image,
            status: instant ? "Buy now" : "Live Auction",
            repositoryAddress: repositoryAddress,
            startDate: startDate,
            endDate: sendDate.toFixed(0),
            auctionTitle: "PRONFT",
          });

          const activityData = await servicePost("api/activity/addActivity", {
            type: "Listing",
            itemImage: createdData.image,
            itemName: createdData.tokenName,
            quantity: 1,
            price: price,
            from: accounts[0],
            to: "",
            ownerAddress: accounts[0],
          });
          navigate("/profile/owned");
        });
    }
  };

  // Call Contract when Click on Sumbit...
  const onFinish = async () => {
    if (CheckContractAddress) {
      // alert("This is collectible contract");
      // let typeofFile = [fileType];
      // // let typeofFile = "image";
      // let FilesTypes = JSON.stringify(typeofFile);
      // const transactionHash = "receipt.transactionHash";
      // const tokendata = await servicePost("api/addCollectableToken", {
      //   tokenId: 2,
      //   image: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
      //   fileType: FilesTypes,
      //   tokenType: "Single",
      //   minterName: userData.name ? userData.name : "account[0",
      //   owner: "0x821933A74439CA1FCc651d747c0F1aF24014a934",
      //   creator: "0x821933A74439CA1FCc651d747c0F1aF24014a934",
      //   royalties: royalties,
      //   transactionHash: "transactionHash",
      //   tokenCount: 1,
      //   sale: 0,
      //   price: price,
      //   tokenOf: "PRONFT",
      //   tokenName: name,
      //   description: description,
      //   filterUserOwner: true,
      // });

      // // Add activity Api Call
      // const activityData = await servicePost("api/activity/addActivity", {
      //   type: "Listing",
      //   itemImage: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
      //   itemName: name,
      //   quantity: 1,
      //   price: price,
      //   from: "",
      //   to: "",
      //   ownerAddress: "",
      //   contractAddress: "",
      // });

      // return;

      const Abi = ERC721Collectible.abi;
      const contract = await new web3.eth.Contract(Abi, CheckContractAddress);

      if (royalties > 50) {
        alert("royalties cannot be Greater 50");
        return;
      }
      if (createdData != null) {
        putOnSaleForCollectible();
        // alert("aaa FIRST");
      } else {
        // let royalties = values.royalties.toString();
        // royalties = web3.utils.toWei(royalties, "ether");

        if (uploadedImage) {
          if (royalties >= 0) {
            if (sale == true) {
              if (price < 0.00001) {
                alert("Price cannot be less than .00001");
                return;
              }
              if (!instant && !selectedDate) {
                alert("Please select End Date");
                return;
              }
            }
            // alert(sale);
            let transferId = 0;
            let added = "";

            const accounts = await web3.eth.getAccounts();

            let bid = 0;
            if (sale == true) {
              setShowApproveModal(true);
            } else {
              setShowApproveModal(true);
            }

            try {
              const doc = JSON.stringify({
                file: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
                fileType: fileType,
                sale,
                tokenType: "Single",
                minterName: userData.name,
                minterImage: userData.profileImageUrl,
                formData,
              });
              added = await ipfs.add(doc);

              const tx2 = await contract.methods
                .createProduct(
                  accounts[0],
                  added.path,
                  added.path,
                  //  `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${imagehash}`,
                  Number(royalties),
                  Number(royalties),
                  sale == false ? bid : price
                )
                .send({ from: accounts[0] })
                .once("receipt", async (receipt) => {
                  console.log("receipt", receipt);

                  let tokenId = web3.utils.hexToNumber(
                    receipt.logs[0].topics[3]
                  );
                  console.log("TonkenId", tokenId);
                  // transferId = 1; // static value
                  transferId = web3.utils.hexToNumber(
                    receipt.logs[0].topics[3]
                  );

                  console.log("transferIdCollition", transferId);
                  let typeofFile = [fileType];
                  // let typeofFile = "image";
                  let FilesTypes = JSON.stringify(typeofFile);
                  const transactionHash = receipt.transactionHash;

                  // let userOptions = new FormData();
                  // userOptions.append("tokenId", transferId);
                  // userOptions.append(
                  //   "image",
                  //   `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${imagehash}`
                  // );
                  // userOptions.append("fileType", FilesTypes);
                  // userOptions.append("tokenType", "single");
                  // userOptions.append("minterName", accounts[0]);
                  // userOptions.append("owner", accounts[0]);
                  // userOptions.append("creator", accounts[0]);
                  // userOptions.append("royalities", values.royalties);
                  // userOptions.append("transactionHash", transactionHash);
                  // userOptions.append("tokenCount", 1);
                  // userOptions.append("sale", 0);
                  // userOptions.append("tokenOf", CheckContractAddress);
                  // userOptions.append("tokenName", values.username);
                  // userOptions.append("description", values.description);
                  // userOptions.append("filterUserOwner", false);
                  // let tokendata = await servicePost(
                  //   `api/v1/token/addtoken`,
                  //   userOptions
                  // );

                  const tokendata = await servicePost(
                    "api/addCollectableToken",
                    {
                      tokenId: transferId,
                      image: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
                      fileType: FilesTypes,
                      tokenType: "Single",
                      minterName: userData.name ? userData.name : accounts[0],
                      owner: accounts[0],
                      creator: accounts[0],
                      sale: 0,
                      royalties: royalties,
                      transactionHash: transactionHash,
                      tokenCount: 1,
                      tokenName: name,
                      description: description,
                      price: price ? price : null,
                      tokenOf: CheckContractAddress,
                      auctionId: null,
                      instantBuy: instant,
                      filterUserOwner: false,
                    }
                  );

                  tokenTableId = tokendata.data.id;

                  if (sale == false) {
                    navigate("../collection/items", {
                      state: {
                        contractAddress: CheckContractAddress,
                        ownerAddress: accounts[0],
                      },
                    });
                  }
                });
            } catch (error) {
              console.log("Error uploading file: ", error);
            }

            if (sale == true) {
              if (price < 0.00001) {
                alert("Price cannot be less than .00001");
                return;
              }
              const AuctionAbi = AuctionCollectible.abi;
              const Auctioncontract = await new web3.eth.Contract(
                AuctionAbi,
                "0xEbC68f64410400d6201e522b35F14E073a2329Bb"//"0xF45b5D833CB1f5B6d7CD782dAB5e2caDE072c687"
              );

              const ether = web3.utils.toWei(price, "ether");
              const endPrice = web3.utils.toWei(price, "ether");
              let startDate = new Date().getTime() / 1000;
              let nowDate = new Date().getTime() / 1000;
              var endDate = selectedDate && selectedDate.getTime() / 1000;
              const sendDate = !instant ? endDate : nowDate;

              await Auctioncontract.methods
                .createAuction(
                  CheckContractAddress,
                  transferId,
                  contractName,
                  added.path,
                  ether,
                  endPrice,
                  startDate.toFixed(0),
                  sendDate.toFixed(0),
                  Number(royalties)
                )
                .send({ from: accounts[0] })
                .then(async (val) => {
                  console.log("val", val);
                  const auctionhash = val.transactionHash;
                  const fullHexData = val.logs[0].data; // Assuming receipt.logs[0].data is a valid hex string
                  const last10Hex = fullHexData.slice(-10);

                  // const auctionId = web3.utils.hexToNumberString(last10Hex);
                  const auctionId = parseInt(last10Hex, 16);

                  const tokendataupdated = await servicePost(
                    "api/updateCollectionNft",
                    {
                      productId: transferId,
                      tokenId: transferId,
                      auctionId: auctionId,
                      tokenType: "Single",
                      owner: accounts[0],
                      startPrice: price,
                      endPrice: price,
                      price: price,
                      sale: 1,
                      royaltyFee: royalties,
                      transactionHash: auctionhash,
                      tokenCount: 1,
                      tokenName: name,
                      image: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
                      status: instant ? "Buy now" : "Live Auction",
                      instantBuy: instant,
                      repositoryAddress: CheckContractAddress,
                      startDate: startDate,
                      endDate: sendDate.toFixed(0),
                      auctionTitle: name,
                    }
                  );

                  // Add activity Api Call
                  const activityData = await servicePost(
                    "api/activity/addContractActivity",
                    {
                      type: "Listing",
                      itemImage: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
                      itemName: name,
                      quantity: 1,
                      price: price,
                      from: accounts[0],
                      to: "",
                      ownerAddress: accounts[0],
                      contractAddress: CheckContractAddress,
                    }
                  );

                  navigate("../collection/items", {
                    state: {
                      contractAddress: CheckContractAddress,
                      ownerAddress: accounts[0],
                    },
                  });
                });
            }
          } else {
            alert("Royalties cannot be less than 1");
          }
        } else {
          alert("Image Cannot Be Empty");
        }
      }
    } else {
      if (createdData != null) {
        if (createdData.tokenOf != "PRONFT") {
          // alert("multiple Collection");
          putOnSaleForCollectible();
        } else {
          // alert("Single ProNFt");
          putOnSale();
        }
      } else {
        var repositoryAddress = await networkAddress();
        if (uploadedImage) {
          if (name == "") {
            alert("Please Enter name");
            return;
          }
          if (royalties >= 0) {
            if (sale == true) {
              if (price < 0.00001) {
                alert("Price cannot be less than .00001");
                // setModalVisible(true);
                return;
              }
              if (!instant && !selectedDate) {
                alert("Please select End Date");
                // setModalVisible(true);
                return;
              }
            }

            // alert(sale);
            let transferId = 0;
            let added = "";

            const auction = await auctionContract();
            const accounts = await web3.eth.getAccounts();

            // Single Element contract
            if (royalties > 50) {
              alert("royalties cannot be Greater 50");
              // setModalVisible(true);
              return;
            }

            if (sale == true) {
              setShowApproveModal(true);
            } else {
              setShowApproveModal(true);
              // toggleConfirmDialog();
            }

            const contract = await postContract();

            try {
              const doc = JSON.stringify({
                file: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
                fileType: fileType,
                sale,
                tokenType: "Single",
                minterName: userData.name,
                minterImage: userData.profileImageUrl,
                formData,
              });

              added = await ipfs.add(doc);
console.log("price",price);
              var priceInWei = web3.utils.toWei(price, "ether");
              await contract.methods
                .createProduct(
                  accounts[0],
                  added.path,
                  added.path,
                  //  `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
                  Number(royalties),
                  Number(royalties),
                  priceInWei
                )
                .send({ from: accounts[0] })
                .once("receipt", async (receipt) => {
                  transferId = web3.utils.hexToNumber(
                    receipt.logs[0].topics[3]
                  );

                  // typeofFile.push({});
                  let typeofFile = [fileType];
                  // let typeofFile = "image";
                  let FilesTypes = JSON.stringify(typeofFile);
                  const transactionHash = receipt.transactionHash;
                  const tokendata = await servicePost("api/nft/addtoken", {
                    tokenId: transferId,
                    image: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
                    fileType: FilesTypes,
                    tokenType: "Single",
                    minterName: userData.name ? userData.name : accounts[0],
                    owner: accounts[0],
                    creator: accounts[0],
                    royalties: royalties,
                    transactionHash: transactionHash,
                    tokenCount: 1,
                    sale: 0,
                    status: "Not For Sale",
                    price: 0,
                    tokenOf: "PRONFT",
                    tokenName: name,
                    description: description,
                    filterUserOwner: true,
                  });
                  tokenTableId = tokendata.data.id;

                  if (sale == false) {
                    navigate("/profile/owned");
                  }
                })
                .catch((error) => {});
            } catch (error) {
              console.log("Error uploading file: ", error);
            }

            if (sale == true) {
              if (price < 0.00001) {
                alert("Price cannot be less than .00001");
                return;
              }

              const ether = web3.utils.toWei(price, "ether");
              var today = new Date();
              today.setHours(today.getHours() + 1);
              var unix_time = today.getTime() / 1000;

              var repositoryAddress = await networkAddress();
              //let price = 2;
              // selectedDate.getTime() / 1000).toFixed(0).toString()
              var startDate = (new Date().getTime() / 1000)
                .toFixed(0)
                .toString();
              var endPrice = web3.utils.toWei(price, "ether");
              let nowDate = new Date().getTime() / 1000;
              var endDate = selectedDate && selectedDate.getTime() / 1000;
              const sendDate = !instant ? endDate : nowDate;

              await auction.methods
                .createAuction(
                  repositoryAddress,
                  transferId,
                  "PRONFT",
                  added.path,
                  ether,
                  endPrice,
                  startDate,
                  sendDate.toFixed(0),
                  Number(royalties)
                )
                .send({ from: accounts[0] })
                .once("receipt", async (receipt) => {
                  const fullHexData = receipt.logs[0].data; // Assuming receipt.logs[0].data is a valid hex string
                  const last10Hex = fullHexData.slice(-10);

                  // const auctionId = web3.utils.hexToNumberString(last10Hex);
                  const auctionId = parseInt(last10Hex, 16);

                  const auctionhash = receipt.transactionHash;
                  const tokendataupdated = await servicePost(
                    "api/nft/updatetoken",
                    {
                      productId: transferId,
                      tokenId: transferId,
                      auctionId: auctionId,
                      tokenType: "Single",
                      owner: accounts[0],
                      startPrice: price,
                      endPrice: price,
                      instantBuy: instant,
                      price: price,
                      status: instant ? "Buy now" : "Live Auction",
                      sale: 1,
                      royaltyFee: royalties,
                      transactionHash: auctionhash,
                      tokenCount: 1,
                      tokenName: name,
                      image: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
                      repositoryAddress: repositoryAddress,
                      startDate: startDate,
                      endDate: sendDate.toFixed(0),
                      auctionTitle: "PRONFT",
                    }
                  );

                  // Add activity Api Call
                  const activityData = await servicePost(
                    "api/activity/addActivity",
                    {
                      type: "Listing",
                      itemImage: `https://nft-blockrhino-io.infura-ipfs.io/ipfs/${hash}`,
                      itemName: name,
                      quantity: 1,
                      price: price,
                      from: accounts[0],
                      to: "",
                      ownerAddress: accounts[0],
                    }
                  );

                  navigate("/profile/owned");
                });
            } else {
              navigate("/profile/owned");
            }
          } else {
            alert("Royalties cannot be less than 1");
          }
        } else {
          alert("Image Cannot Be Empty");
        }
      }
    }
  };

  return (
    <>
      <CreactApprove
        show={showApproveModal}
        onHide={() => setShowApproveModal(false)}
      />

      <div className="create-header-wrap">
        <h2> Create Single Collectible </h2>
        <div className="verified-pages-wrap">
          <h4 className="active-2"> 2 </h4>
          <div className="pages-dots-wrap">
            <div className="dots-wrap" onClick={() => setCurrentStep(1)}></div>
            <div className="dots-wrap active"></div>
          </div>
        </div>
      </div>

      <div className="create-second-step-wrapper">
        <div className="choose-collection-main-wrapper">
          <h3> Choose collection</h3>
          {!createdData ? (
            <div className="choose-collection-main-wrap">
              <div className="create-collection-wrap">
                <div className="create-button-wrap">
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasContractRight"
                    aria-controls="offcanvasRight"
                  >
                    <img src="/images/icons/add-icon.svg" alt="icon" />
                  </button>
                  <p> Create </p>
                </div>
              </div>
              <div
                onClick={ProNFTCollection}
                className={
                  selectedPlatform == "ProNFT" && !selectedContract
                    ? "choose-platform-wrap active"
                    : "choose-platform-wrap"
                }
              >
                <img src="/images/icons/pro-logo.png" alt="icon" />
                <h3> ProNFT </h3>
              </div>
              {checkContract && (
                <div
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasSelectContractRight"
                  aria-controls="offcanvasRight"
                  className={
                    selectedContract
                      ? "choose-platform-wrap active"
                      : "choose-platform-wrap"
                  }
                >
                  {selectedContract ? (
                    <>
                      <div className="collection-detail-wrap">
                        <img
                          src={
                            selectedContract && selectedContract.contractImage
                              ? selectedContract.contractImage
                              : "/images/icons/avtar.png"
                          }
                          alt="img"
                        />
                        <h3> {selectedContract?.contractName} </h3>
                      </div>
                      <div className="select-collection-wrap">
                        <p> Select other </p>
                        <img
                          className="select-arrow"
                          src="/images/icons/select-arrow.png"
                          alt="icon"
                        />
                      </div>
                    </>
                  ) : (
                    <div className="select-collection-wrap">
                      <p> Collections </p>
                      <img
                        className="select-arrow"
                        src="/images/icons/select-arrow.png"
                        alt="icon"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="choose-collection-main-wrap">
              <div className="create-collection-wrap">
                <div className="create-button-wrap">
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasContractRight"
                    aria-controls="offcanvasRight"
                  >
                    <img src="/images/icons/add-icon.svg" alt="icon" />
                  </button>
                  <p> Create </p>
                </div>
              </div>
              {createdData.tokenOf == "PRONFT" ? (
                <div
                  onClick={ProNFTCollection}
                  className={
                    selectedPlatform == "ProNFT" && !selectedContract
                      ? "choose-platform-wrap active"
                      : "choose-platform-wrap"
                  }
                >
                  <img src="/images/icons/pro-logo.png" alt="icon" />
                  <h3> ProNFT </h3>
                </div>
              ) : (
                <div className="choose-platform-wrap active">
                  <div className="select-collection-wrap">
                    <p>{shortenWalletId(createdData.tokenOf, 6, 4)} </p>
                  </div>
                </div>
              )}
            </div>
          )}

          {!createdData ? (
            <div className="create-nft-input-wrapper">
              <div className="create-nft-input-repeat">
                <label> Name* </label>
                <input
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  value={name}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="create-nft-input-repeat">
                <textarea
                  type="text"
                  name="description"
                  value={description}
                  onChange={inputEventHandler}
                  placeholder="Enter Description here (optional)"
                />
              </div>

              <div className="create-nft-input-repeat">
                <label> Royalties* </label>
                <input
                  type="number"
                  placeholder="Enter Royalty here"
                  name="royalties"
                  value={royalties}
                  onChange={inputEventHandler}
                />
                <div className="royalty-percentage">%</div>
              </div>
            </div>
          ) : (
            <div className="create-nft-input-wrapper">
              <div className="create-nft-input-repeat">
                <label> Name* </label>
                <input
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  value={createdData.tokenName}
                  readOnly
                />
              </div>

              <div className="create-nft-input-repeat">
                <textarea
                  type="text"
                  name="description"
                  value={createdData.description}
                  // onChange={inputEventHandler}
                  placeholder="Enter Description here (optional)"
                  readOnly
                />
              </div>

              <div className="create-nft-input-repeat">
                <label> Royalties* </label>
                <input
                  type="number"
                  placeholder="Enter Royalty here"
                  name="royalties"
                  value={createdData.royalties}
                  // onChange={inputEventHandler}
                  readOnly
                />
                <div className="royalty-percentage">%</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="create-continue-btn">
        <button
          // onClick={() => setShowApproveModal(true)}
          onClick={onFinish}
          className="btn-style"
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default SingleForm;
