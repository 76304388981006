import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ConnectingModal from "./ConnectingModal";

const ConnectError = (props) => {
  const [showModalConnecting, setShowModalConnecting] = useState(false);

  const walletConnected = () => {
    setShowModalConnecting(true);
    props.onHide();

    setTimeout(() => {
      localStorage.setItem("isConnected", true);
      window.location.reload();
    }, 3000);
  };

  return (
    <>
      <ConnectingModal
        show={showModalConnecting}
        onHide={() => setShowModalConnecting(false)}
      />

      <Modal
        {...props}
        dialogClassName="connect-account-error-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="report-account-main-wrapper">
            <div className="report-account-main-wrap">
              <div className="report-modal-wrap">
                {/* <img src="/images/icons/confirm-tick.png" alt="icon" /> */}
                <div className="report-modal-contant">
                  <h3> Error </h3>
                  <p>
                    MetaMask Personal Message Signature: User has declined the
                    message signature. If the issue persists, please reach out
                    to our support team for further assistance.
                  </p>
                </div>
              </div>

              <div className="delete-button-wrap">
                <button onClick={walletConnected} className="btn-style">
                  Try again
                </button>
                <button
                  className="btn-style cancel-btn-style"
                  onClick={props.onHide}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectError;
