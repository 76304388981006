import React, { Fragment, useEffect, useState } from "react";
import Explore from "../Explore";
import TypeDropdown from "../../Dropdown/TypeDropdown";
import CategoryFilter from "../../Dropdown/CategoryFilter";
import CategoryFilterDrawer from "../../Drawers/CategoryFilterDrawer";
import { useNavigate } from "react-router-dom";
import { servicePost } from "../../../helpers/api";
import UserCard from "./UserCard";

const AllUsers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  //get user from localstorage...
  const user = JSON.parse(localStorage.getItem("user"));
  const walletId = user && user.walletId;

  const getUserByCategories = async () => {
    try {
      const userList = await servicePost("user/getUsersByCategory", {
        category: selectedCategory !== null ? selectedCategory : "All",
      });
      setAllUsers(userList.users);
    } catch (error) {
      console.log("error", error);
      setAllUsers([]);
    }
  };

  useEffect(() => {
    getUserByCategories();
  }, [selectedCategory]);

  const filterTokenData = () => {
    const searchKeyword = searchValue.trim().toLowerCase();
    const searchedData = allUsers.filter((ele) =>
      ele.name && ele.name.toLowerCase().includes(searchKeyword)
        ? true
        : ele?.walletId.toLowerCase().includes(searchKeyword)
    );
    setFilteredData(searchedData);
  };

  useEffect(() => {
    filterTokenData();
  }, [allUsers, searchValue]);

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasCategoryFilter"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <div className="profile-mobile-filter-wrapper">
              <div className="profile-mobile-filter-wrap">
                <div className="drawer-cancel-btn">
                  <button data-bs-dismiss="offcanvas" aria-label="Close">
                    <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                  </button>
                </div>
                <h2 className="drawer-heading"> Filters </h2>
                <CategoryFilter onCategoryChange={handleCategoryChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="explore-bg-wrapper">
        <div className="explore-users-main-wrapper custom-container">
          <div className="explore-users-main-wrap">
            <div className="explore-nft-header-wrap">
              <Explore />
              <div className="profile-owned-filter-wrap">
                <div className="activity-owned-nft-search">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <img
                    className="search-owned-icon"
                    src="/images/icons/search-icon.svg"
                    alt="icon"
                  />
                </div>
                <div className="filter-mobile-view">
                  <img
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasCategoryFilter"
                    aria-controls="offcanvasRight"
                    src="/images/icons/filter-icon.svg"
                    alt="icon"
                  />
                </div>
                <div className="filter-desktop-view">
                  <CategoryFilter onCategoryChange={handleCategoryChange} />
                </div>
              </div>
            </div>

            <div className="explore-user-lis-wrapper">
              <div className="profile-collection-main-wrap">
                {filteredData.length > 0 &&
                  filteredData?.map((data, index) => {
                    return (
                      <Fragment key={index}>
                        {/* <div className="profile-collection-repeat" key={index}>
                          <div
                            className="profile-collection-wrap"
                            onClick={() => goToUserProfile(data)}
                          >
                            <div className="collection-image-main-wrap">
                              <div className="collection-bg-image">
                                {data.coverImageUrl ? (
                                  <img src={data.coverImageUrl} alt="img" />
                                ) : (
                                  <img
                                    src="/images/default-cover.png"
                                    alt="img"
                                  />
                                )}
                              </div>
                              <div className="collection-image-wrap user-profile-wrap">
                                {data.profileImageUrl ? (
                                  <img src={data.profileImageUrl} alt="img" />
                                ) : (
                                  <img
                                    src="/images/icons/avtar.png"
                                    alt="img"
                                  />
                                )}
                              </div>
                            </div>

                            <div className="explore-user-info-wrapper">
                              <div className="explore-user-name">
                                <h3>
                                  {data?.name
                                    ? data?.name
                                    : data?.walletId
                                    ? data?.walletId.slice(0, 8)
                                    : "-"}
                                </h3>
                                {data.isVerified === true && (
                                  <img
                                    src="/images/icons/verified.png"
                                    alt="icon"
                                  />
                                )}
                              </div>
                              <div className="explore-user-follower-wrap">
                                <div className="explore-user-follower-count">
                                  <h4> Followers </h4>
                                  <p> {data.Follower} </p>
                                </div>
                                <div className="explore-user-btn-wrap">
                                  <div
                                    className={`${
                                      data.Following == null
                                        ? "follower-btn-wrap"
                                        : "follower-btn-wrap following"
                                    }`}
                                  >
                                    <button> Follow </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <UserCard data={data} />
                      </Fragment>
                    );
                  })}
              </div>
              {allUsers.length === 0 && (
                <>
                  <div className="empty-card-main-wrap">
                    <div className="empty-card-image">
                      <img src="/images/icons/empty-box.png" alt="empty" />
                      <p> No Users Found </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllUsers;
