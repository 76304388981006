// export const SERVICE_URL = "http://192.168.40.81:8001";
// export const SERVICE_URL = "http://192.168.40.71:8001";
//export const SERVICE_URL = "http://localhost:8001";
 export const SERVICE_URL = "https://api.pronft.gyan.solutions";
export const DEFAULT_SERVICE_VERSION = "v1";
//export const CHAINID = 56;
// export const CHAINID = 97;
export const CHAINID = 5;
export const BINANCEURL =
  "https://eth-goerli.alchemyapi.io/v2/gSEBNODiS1QdyPSe3dsl50xGYYKGKMCY";

export const BINANCENETWORK = "binance";
export const NETWORKTYPE = "testnet";

// export const NETWORKTYPE = "mainnet";
export const CATEGORIES = [
  "All",
  "Animation",
  "Art",
  "Gaming",
  "Food",
  "Travel",
  "Comedy",
  "Fashion",
  "Life style",
  "Beauty",
  "Music",
  "Sports",
  "Model",
  "Fitness",
  "Entertainment",
  // "All",
  // "Travel",
  // "Architecture",
  // "Style",
  // "Lifestyle",
  // "Gaming",
  // "Food",
  // "Art",
  // "Beauty",
  // "Music",
  // "Comedy",
  // "Fitness",
];

export const AUTHENTICATION =
  "ABCJVNXAMLTMZIXMS2GH5XDAKCUOFAE2NT3IU3GCL3YKRYEGM2TIUXA";
export const ALLOWED_IMAGE_SIZE_IN_BYTES = 12000000;
