import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Collections = () => {
  const [topCollection, setTopCollection] = useState([]);

  const navigate = useNavigate();

  const goToAllCollections = () => {
    navigate("/explore/all/collections");
  };

  const goToCollectionPage = () => {
    navigate("/collection/items");
  };

  useEffect(() => {
    const fetchCollectionRanking = async () => {
      try {
        const response = await axios.get(
          "https://bnbapi.nftscan.com/api/v2/statistics/ranking/collection?sort_field=volume_1d&sort_direction=desc",
          {
            headers: {
              "X-API-KEY": "ASthHBK9bKecpJmyGCIqfasG",
            },
          }
        );
        const data = response.data.data;

        // Set the collection ranking state
        setTopCollection(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCollectionRanking();
  }, []);
  return (
    <>
      <div className="top-collection-main-wrapper custom-container">
        <div className="top-collection-main-wrap">
          <div className="top-collection-header-wrap">
            <div className="top-collection-header-left">
              <h3> Top Collection </h3>
              <img src="/images/icons/copies-icon.png" alt="img" />
            </div>
            <div className="top-collection-header-right">
              <div className="chain-icon-wrap">
                <img src="/images/icons/eth-icon.png" alt="icon" />
              </div>
              <button onClick={goToAllCollections} className="btn-style">
                View all
              </button>
            </div>
          </div>

          <div className="collection-data-wrapper table-responsive">
            <table className="table collection-table table-borderless">
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "left", width: "60px" }}>
                    #
                  </th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Collection
                  </th>
                  <th scope="col">Floor Price </th>
                  <th scope="col">Volumn</th>
                  <th scope="col">Items</th>
                  <th scope="col">Owners</th>
                </tr>
                <div className="table-head-space"></div>
              </thead>
              <tbody>
                {topCollection?.slice(0, 10).map((data, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "left" }}>
                        <p className="collection-rank-number"> {index + 1} </p>
                      </td>
                      <td style={{ textAlign: "left" }}>
                        <div className="collection-table-info-wrap">
                          <div
                            className="collection-image"
                            // onClick={goToCollectionPage}
                          >
                            {data.logo_url ? (
                              <img src={data.logo_url} alt="img" />
                            ) : (
                              <img src="/images/icons/avtar.png" alt="img" />
                            )}
                          </div>

                          {data.contract_name ? (
                            <p> {data.contract_name} </p>
                          ) : (
                            <span className="span-for-empty-data"> - </span>
                          )}
                          {data.opensea_verified && (
                            <img
                              className="collection-verfied-logo"
                              src="/images/icons/verified.png"
                              alt="img"
                            />
                          )}
                        </div>
                      </td>
                      <td>
                        {data.floor_price ? (
                          `${data.floor_price} BNB`
                        ) : (
                          <span className="span-for-empty-data"> - </span>
                        )}
                      </td>
                      <td>
                        {data.volume_1d ? (
                          `${data.volume_1d.toFixed(2)} BNB`
                        ) : (
                          <span className="span-for-empty-data"> - </span>
                        )}

                        <span
                          className={`volumn-changes ${
                            data.volume_change_1d.startsWith("-")
                              ? "negative"
                              : "positive"
                          }`}
                        >
                          {data.volume_change_1d}
                        </span>
                      </td>
                      <td>
                        {data.items_total ? (
                          data.items_total >= 1000 ? (
                            data.items_total % 1000 === 0 ? (
                              `${data.items_total / 1000}k`
                            ) : (
                              `${(data.items_total / 1000).toFixed(1)}k`
                            )
                          ) : (
                            data.items_total
                          )
                        ) : (
                          <span className="span-for-empty-data"> - </span>
                        )}
                      </td>
                      <td>
                        {data.owners_total ? (
                          data.owners_total >= 1000 ? (
                            data.owners_total % 1000 === 0 ? (
                              `${data.owners_total / 1000}k`
                            ) : (
                              `${(data.owners_total / 1000).toFixed(1)}k`
                            )
                          ) : (
                            data.owners_total
                          )
                        ) : (
                          <span className="span-for-empty-data"> - </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {topCollection === undefined && (
              <div className="user-empty-box-wrap">
                <img src="/images/icons/empty-box.png" alt="icon" />
                <p> No Collection Found </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Collections;
