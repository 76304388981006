import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { serviceGet, servicePost } from "../../helpers/api";
import Users from "./Users";

const Category = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  const getAllCategories = async () => {
    try {
      const categoryRes = await serviceGet("api/category/getAllCategory");
      setCategoryList(categoryRes.categories);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const CustomPrevArrow = (props) => (
    <div {...props} className="slick-arrow slick-prev">
      <img src="/images/icons/pre-arrow.png" alt="Previous" />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div {...props} className="slick-arrow slick-next">
      <img src="/images/icons/next-arrow.png" alt="Next" />
    </div>
  );

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 300,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <div className="category-main-wrapper custom-container">
        <div className="category-main-wrap">
          <div className="category-top-wrap">
            <h2>Categories</h2>
            <button
              className="btn-style"
              onClick={() => setSelectedCategory("All")}
            >
              All
            </button>
          </div>
          <div className="category-silder-wrap">
            <Slider {...settings}>
              {categoryList?.map((data, index) => {
                return (
                  <Fragment key={index}>
                    <div key={index}>
                      <div
                        className={`category-wrap ${
                          selectedCategory === data.categoryName ? "active" : ""
                        }`}
                      >
                        <h3> {data?.categoryName} </h3>
                        <img
                          onClick={() => handleCategoryClick(data.categoryName)}
                          src={data.categoryImage}
                          alt="img"
                        />
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <Users selectedCategory={selectedCategory ? selectedCategory : "All"} />
    </>
  );
};

export default Category;
