import Web3Modal from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import Portis from "@portis/web3";
// import Fortmatic from "fortmatic";
// import Squarelink from "squarelink";
import Torus from "@toruslabs/torus-embed";
import {
  CHAINID,
  BINANCEURL,
  NETWORKTYPE,
  BINANCENETWORK,
} from "./../constants/utility";

// const goToDarkMode = () => {
//   document.body.classList.toggle("dark-theme");
// };

let wCSetting = {};
wCSetting[CHAINID] =
  "https://eth-goerli.alchemyapi.io/v2/gSEBNODiS1QdyPSe3dsl50xGYYKGKMCY";

export const web3Connect = new Web3Modal({
  network: NETWORKTYPE,
  cacheProvider: false, // optional
  providerOptions: {
    // walletconnect: {
    //   package: WalletConnectProvider, // required
    //   options: {
    //     rpc: wCSetting,
    //     network: BINANCENETWORK,
    //   },
    // },

    // portis: {
    //   package: Portis, // required
    //   options: {
    //     id: "PORTIS_ID", // required
    //     network: BINANCENETWORK,
    //   },
    // },

    torus: {
      package: Torus, // required
      options: {
        enableLogging: false, // optional
        buttonPosition: "bottom-left", // optional
        buildEnv: "production", // optional
        showTorusButton: true, // optional
        // network: BINANCENETWORK,
        network: "goerli",
      },
    },
    // fortmatic: {
    //   package: Fortmatic, // required
    //   options: {
    //     key: "FORTMATIC_KEY", // required,
    //     network: BINANCENETWORK,
    //   },
    // },
    // squarelink: {
    //   package: Squarelink, // required
    //   options: {
    //     id: "SQUARELINK_ID", // required
    //     network: BINANCENETWORK,
    //   },
    // },
  },
  disableInjectedProvider: false,
});
