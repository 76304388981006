import { SET_CONTRACT, SET_TASK_DEP } from "../actionTypes";

export function saveContract(Contract) {
  return {
    type: SET_CONTRACT,
    Contract: Contract,
  };
}

export const setTaskDep = (data) => {
  return {
    type: SET_TASK_DEP,
    payload: data,
  };
};
