import React, { useEffect, useState } from "react";
import { serviceGet, servicePost } from "../../helpers/api";

const HomeSlider = () => {
  const [sliderData, setSliderData] = useState([]);
  const [currentCreator, setCurrentCreator] = useState("_");
  const [currentPrice, setCurrentPrice] = useState("0.00");
  const [creatorImage, setCreatorImage] = useState("");
  const [centerIndex, setCenterIndex] = useState(0);

  const images = [
    "/images/nft-1.jpg",
    "/images/nft-2.jpg",
    "/images/nft-3.jpg",
    // Add more image paths as needed
  ];

  useEffect(() => {
    if (sliderData.length >= 3) {
      setCurrentCreator(sliderData[centerIndex].minterName);
      setCurrentPrice(`${sliderData[centerIndex].price}`);
      setCreatorImage(sliderData[centerIndex].ownerImage);
    }
  }, [centerIndex, sliderData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCenterIndex((centerIndex) => (centerIndex + 1) % images.length);

      // Update the creator and current price dynamically
      if (sliderData.length >= 3) {
        setCurrentCreator(sliderData[centerIndex].minterName);
        setCurrentPrice(`${sliderData[centerIndex].price}`);
        setCreatorImage(sliderData[centerIndex].ownerImage);
      }
    }, 4800);
    return () => clearInterval(interval);
  }, [centerIndex, sliderData]);

  const getPopularNFts = async () => {
    try {
      const nftData = await servicePost("api/nft/getalltoken");

      // const data = nftData.data.sort((a, b) =>
      //   new Date(a) < new Date(b) ? 1 : -1
      // );

      setSliderData(nftData.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPopularNFts();
  }, []);

  return (
    <div className="home-slider">
      <div className="nft-price-info-wrap">
        <img src="/images/price-info-bg.svg" alt="img" />
        <img src="/images/price-info-bg.svg" alt="img" />
        <img src="/images/price-info-bg.svg" alt="img" />
        <img
          className="eth-rotate-icon"
          src="/images/icons/eth-rotate.png"
          alt="icon"
        />
        <div className="nft-price-info-contant">
          <img src="/images/icons/buy-icon.png" />
          <div className="nft-price-wrap">
            <p> Current Price </p>
            <h3> {currentPrice} BNB </h3>
          </div>
        </div>
      </div>

      <div className="home-slider-content">
        {sliderData?.length > 2
          ? sliderData.slice(0, 3).map((data, index) => (
              <>
                <div key={index} className="home-slider-item">
                  <img src={data.image} alt={`Image ${index}`} />
                </div>
              </>
            ))
          : images.map((image, index) => (
              <>
                <div key={index} className="home-slider-item">
                  <img src={image} alt={`Image ${index}`} />
                </div>
              </>
            ))}
      </div>

      <div className="nft-info-wrap">
        <img src="/images/info-bg.svg" alt="img" />
        <img src="/images/info-bg.svg" alt="img" />
        <img src="/images/info-bg.svg" alt="img" />

        <div className="nft-info-contant">
          {creatorImage ? (
            <img src={creatorImage} alt="img" />
          ) : (
            <img src="/images/icons/avtar.png" alt="img" />
          )}

          <p> Creator </p>
          <h3> {currentCreator} </h3>
        </div>
      </div>
    </div>
  );
};

export default HomeSlider;
