import React, { useState } from "react";
import Lottie from "lottie-react";
import ReviewLottie from "./review.json";

const VerifyReview = () => {
  return (
    <>
      <div className="varify-drawer-main-wrapper">
        <div className="verify-fourth-step-wrapper">
          <div className="drawer-cancel-btn">
            <button data-bs-dismiss="offcanvas" aria-label="Close">
              <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
            </button>
          </div>
          <div className="verified-header-wrap">
            <div className="verified-heading-wrapper">
              <div className="verified-heading-wrap">
                <h2 className="drawer-heading"> Get Verified </h2>
                <img src="/images/icons/verified.png" alt="icon" />
              </div>
            </div>
          </div>
          <div className="verify-review-wrapper">
            <div className="verify-review-wrap">
              <div className="verify-review-lottie">
                <Lottie animationData={ReviewLottie} loop={true} />
              </div>
              <h3> In Review </h3>
              <p>
                Congratulations! Your application has been successfully
                submitted. Please note that our diligent verification process
                typically takes 2 weeks.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyReview;
