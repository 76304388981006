import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/header/Navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import Owned from "./components/profile/pages/Owned";
import Sale from "./components/profile/pages/Sale";
import Collection from "./components/profile/pages/Collection";
import Created from "./components/profile/pages/Created";
import Activity from "./components/profile/pages/Activity";
import EditProfile from "./components/Settings/EditProfile";
import EditAccount from "./components/Settings/EditAccount";
import EditWallet from "./components/Settings/EditWallet";
import NftItems from "./components/explore/pages/NftItems";
import AllCollecions from "./components/explore/pages/AllCollecions";
import AllUsers from "./components/explore/pages/AllUsers";
import CreateSingle from "./components/create/CreateSingle";
import CreateMultiple from "./components/create/CreateMultiple";
import SellPage from "./components/sellPage/SellPage";
import Feed from "./components/feed/pages/Feed";
import MyPost from "./components/feed/pages/MyPost";
import { initializeWeb3, web3 } from "./constants/constants";
import { useEffect } from "react";
import MyCollection from "./components/collections/MyCollection";
import UserOwned from "./components/user/pages/UserOwned";
import UserSale from "./components/user/pages/UserSale";
import UserCreated from "./components/user/pages/UserCreated";
import UserCollection from "./components/user/pages/UserCollection";
import UserActivity from "./components/user/pages/UserActivity";
import AndriodLogin from "./components/Androidapp/AndriodLogin";
import CreateNFT from "./components/Androidapp/CreateNFT";
import AndroidSingle from "./components/Androidapp/AndroidSingle";
import AndroidMultiple from "./components/Androidapp/AndroidMultiple";
import BidNftPage from "./components/Androidapp/Checkout/BidNftPage";
import BuyNftPage from "./components/Androidapp/Checkout/BuyNftPage";
import SellNft from "./components/Androidapp/Checkout/SellNft";
import { BrowserProtectroute, ProtectedRoute } from "./routing/PrivateRoute";

function App() {
  useEffect(() => {
    // onChangeAccount();
    initializeWeb3();
  }, []);

  return (
    <>
      <Navbar />
      <Routes>
        {/*************** Android pages  */}
        {/* <Route element={<BrowserProtectroute />}> */}
        <Route path="/android-login-connect/:uuid" element={<AndriodLogin />} />
        <Route path="/android-create-nft" element={<CreateNFT />} />
        <Route path="/android/single" element={<AndroidSingle />} />
        <Route path="/android/multiple" element={<AndroidMultiple />} />
        <Route path="/bid/nft" element={<BidNftPage />} />
        <Route path="/buy/nft" element={<BuyNftPage />} />
        <Route path="/buybid/nft" element={<SellNft />} />
        {/* </Route> */}

        <Route element={<ProtectedRoute />}>
          <Route path="/create/single" element={<CreateSingle />} />
          <Route path="/create/multiple" element={<CreateMultiple />} />
          <Route path="/profile/owned" element={<Owned />} />
          <Route path="/profile/owned/sell" element={<Owned />} />
          <Route path="/profile/sale" element={<Sale />} />
          <Route path="/profile/collections" element={<Collection />} />
          <Route path="/profile/created" element={<Created />} />
          <Route path="/profile/activity" element={<Activity />} />
          <Route path="/setting/profile" element={<EditProfile />} />
          <Route path="/setting/account" element={<EditAccount />} />
          <Route path="/setting/walltes" element={<EditWallet />} />
        </Route>

        <Route path="/" element={<Home />} />
        <Route path="/explore/all/items" element={<NftItems />} />
        <Route path="/explore/all/collections" element={<AllCollecions />} />
        <Route path="/explore/all/users" element={<AllUsers />} />
        <Route path="/collection/items" element={<MyCollection />} />
        {/* <Route path="/collection/activity" element={<CollectionActivity />} /> */}
        <Route path="/token/:walletId/:tokenId" element={<SellPage />} />
        <Route path="/user/:walletId/owned" element={<UserOwned />} />
        <Route path="/user/:walletId/sale" element={<UserSale />} />
        <Route
          path="/user/:walletId/collections"
          element={<UserCollection />}
        />
        <Route path="/user/:walletId/created" element={<UserCreated />} />
        <Route path="/user/:walletId/activity" element={<UserActivity />} />
        {/* <Route path="/feed" element={<Feed />} />
        <Route path="/mypost" element={<MyPost />} /> */}
      </Routes>
    </>
  );
}

export default App;
