import { servicePost } from "./api";

// Function to shorten the walletId
const shortenWalletId = (walletId, prefixLength, suffixLength) => {
  if (walletId && walletId.length > 8) {
    const prefix = walletId.substring(0, prefixLength);
    const suffix = walletId.substring(walletId.length - suffixLength);
    return `${prefix}...${suffix}`;
  } else {
    return walletId;
  }
};

const handleCopyWalletId = (walletId, setIsTooltipVisible) => {
  navigator.clipboard.writeText(walletId);
  setIsTooltipVisible(true);

  // Hide the tooltip after a short delay
  setTimeout(() => {
    setIsTooltipVisible(false);
  }, 1000);
};

const calculatePriceInCurrency = async (priceInUsd) => {
  try {
    const data = await servicePost("api/purchase/calculateprice", {
      ethAmount: priceInUsd,
    });
    const priceInTargetCurrency = data.priceInUsd; // Replace with the actual key from the API response
    return priceInTargetCurrency;
  } catch (error) {
    console.log("error", error);
  }
};
export { shortenWalletId, handleCopyWalletId, calculatePriceInCurrency };
