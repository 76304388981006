import React from "react";
import { NavLink, useNavigate } from "react-router-dom"; // Import NavLink from react-router-dom
import { useState } from "react";
import FeedNotification from "../Drawers/FeedNotification";
import PostDrawer from "../Drawers/PostDrawer";

const FeedNav = ({ activeTab }) => {
  // State to manage the active link
  const [activeLink, setActiveLink] = useState(activeTab);

  const navigate = useNavigate();

  // Function to handle click on the sidebar tab
  const handleTabClick = (tabName) => {
    setActiveLink(tabName);
  };

  const goToFeedPage = () => {
    setActiveLink("Feed");
    navigate("/feed");
  };

  const goToMyPostPage = () => {
    setActiveLink("My Posts");
    navigate("/mypost");
  };

  const goToProfile = () => {
    navigate("/profile/owned");
  };

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasNotification"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <FeedNotification />
          </div>
        </div>
      </div>

      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasCreatePost"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <PostDrawer />
          </div>
        </div>
      </div>

      <div className="profeed-sidebar-wrapper">
        <div className="profeed-sidebar-wrap">
          <ul>
            <li>
              <div
                className={`sidebar-tab-repeat ${
                  activeLink === "Feed" ? "active" : ""
                }`}
                onClick={goToFeedPage}
              >
                <div className="sidebar-tab-left">
                  {activeLink === "Feed" ? (
                    <img src="/images/icons/feed-icon-active.svg" alt="icon" />
                  ) : (
                    <img src="/images/icons/feed-icon.svg" alt="icon" />
                  )}
                  <p> Feed </p>
                </div>
                <div className="sidebar-tab-right">
                  {activeLink === "Feed" && (
                    <div className="contract-tick-wrap">
                      <img src="/images/icons/tick-mark.svg" alt="img" />
                    </div>
                  )}
                </div>
              </div>

              <div className="sidebar-tab-repeat">
                <div
                  className="sidebar-tab-left"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNotification"
                  aria-controls="offcanvasExample"
                >
                  <img src="/images/icons/notification.svg" alt="icon" />
                  <p> Notification </p>
                </div>
                <div className="sidebar-tab-right"></div>
              </div>

              <div
                className={`sidebar-tab-repeat ${
                  activeLink === "My Posts" ? "active" : ""
                }`}
                onClick={goToMyPostPage}
              >
                <div className="sidebar-tab-left">
                  {activeLink === "My Posts" ? (
                    <img
                      src="/images/icons/myfeed-icon-active.svg"
                      alt="icon"
                    />
                  ) : (
                    <img src="/images/icons/myfeed-icon.svg" alt="icon" />
                  )}
                  <p> My Posts </p>
                </div>
                <div className="sidebar-tab-right">
                  {activeLink === "My Posts" && (
                    <div className="contract-tick-wrap">
                      <img src="/images/icons/tick-mark.svg" alt="img" />
                    </div>
                  )}
                </div>
              </div>

              <div className="sidebar-tab-repeat">
                <div
                  className="sidebar-tab-left"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasCreatePost"
                  aria-controls="offcanvasRight"
                >
                  <img src="/images/icons/create-post-icon.svg" alt="icon" />
                  <p> Create Post </p>
                </div>
                <div className="sidebar-tab-right"></div>
              </div>
            </li>
          </ul>

          <div className="profile-drawer-footer-wrap">
            <div className="profile-drawer-profile-info">
              <div className="profile-left-wrap" onClick={goToProfile}>
                <img
                  className="profile-pic"
                  src="/images/profile.jpg"
                  alt="img"
                />
                <h3> Genesis </h3>
                <img
                  className="verified-tick"
                  src="/images/icons/verified.png"
                  alt="icon"
                />
              </div>
              <div className="profile-right-wrap" onClick={goToProfile}>
                <p> View Profile </p>
                <div className="profile-link-arrow">
                  <img src="/images/icons/right-arrow.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedNav;
