import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { serviceDelete, servicePost } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading-white.json";

const AccountDeleteModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  //get user from localstorage...
  const user = JSON.parse(localStorage.getItem("user"));
  const walletId = user && user.walletId;

  const deleteUserAccount = async () => {
    setIsLoading(true);
    try {
      const deleteUser = await servicePost("user/deleteUser", {
        walletId: walletId,
      });
      setIsLoading(false);
      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        {...props}
        dialogClassName="delete-account-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="delete-account-main-wrapper">
            <div className="delete-account-main-wrap">
              <h3> Are you sure ? </h3>
              <p>
                Upon deleting your profile, you will cease to receive
                notifications regarding sales and bids. Furthermore, we will
                erase your data, including any connections between multiple
                wallet profiles. Rest assured, your wallet and NFTs will remain
                unaffected, and you will retain sole control over them.
              </p>

              <div className="delete-button-wrap">
                <button className="btn-style" onClick={deleteUserAccount}>
                  {isLoading ? (
                    <div className="button-loading-gif">
                      <Lottie animationData={LoadingLottie} loop={true} />
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </button>
                <button
                  className="btn-style cancel-btn-style"
                  onClick={props.onHide}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccountDeleteModal;
