import React, { useEffect, useState } from "react";
import PostDrawer from "../Drawers/PostDrawer";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import FollowerDrawer from "../Drawers/FollowerDrawer";
import FollowingDrawer from "../Drawers/FollowingDrawer";
import ReportDrawer from "../Drawers/ReportDrawer";
import "bootstrap/dist/css/bootstrap.min.css";
import { serviceGet, servicePost } from "../../helpers/api";
import {
  handleCopyWalletId,
  shortenWalletId,
} from "../../helpers/Normalization";
import { ALLOWED_IMAGE_SIZE_IN_BYTES } from "../../constants/utility";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading-white.json";
import Items from "./pages/Items";
import CollectionActivity from "./pages/CollectionActivity";
import EditContractDrawer from "../Drawers/EditContractDrawer";
import { useSelector } from "react-redux";

const MyCollection = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isTooltip2Visible, setIsTooltip2Visible] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [contractData, setContractData] = useState();
  const [singleContractData, setSingleContractData] = useState();

  //get user from localstorage...
  const user = JSON.parse(localStorage.getItem("user"));
  const walletId = user?.walletId;

  const taskDep = useSelector(
    (state) => state.contract?.taskDep && state.contract.taskDep.Dep1
  );

  const IsContractOwner = contractData?.ownerAddress == walletId;
  const location = useLocation();

  useEffect(() => {
    const receivedState = location.state;
    setContractData(receivedState);
  }, []);

  const getSingleContract = async () => {
    try {
      const contractdataRes = await servicePost(
        "api/contract/getSingleContractData",
        {
          ownerAddress: contractData?.ownerAddress,
          contractAddress: contractData?.contractAddress,
        }
      );

      setSingleContractData(contractdataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // reader.readAsDataURL(file);
      try {
        if (file.size > ALLOWED_IMAGE_SIZE_IN_BYTES) {
          alert("File size exceeds maximum file size limit of 12MB");
          return;
        }

        var allowedExtensions = /(jpg|jpeg|png|gif)$/i;
        if (!allowedExtensions.exec(file.type)) {
          alert("Invalid file type. Only jpg, png & gif allowed");
          return;
        }

        setCoverLoading(true);
        const fileTarget = file;
        let formData = new FormData();
        formData.append("image", fileTarget);
        formData.append("contractAddress", contractData.contractAddress);
        let coverImagedata = await servicePost(
          `api/contract/uploadContractCoverImage`,
          formData
        );
        setUploadedImage(coverImagedata.imageUrl);
        setCoverLoading(false);
      } catch (err) {
        setCoverLoading(false);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    getSingleContract();
  }, [contractData?.ownerAddress, contractData?.contractAddress, taskDep]);

  const navigate = useNavigate();

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasCreatePost"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <PostDrawer />
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasEditContract"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body">
          <EditContractDrawer
            ownerAddress={contractData?.ownerAddress}
            contractAddress={contractData?.contractAddress}
          />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasFollowers"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body">
          <FollowerDrawer walletId={walletId} />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasFollowing"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body">
          <FollowingDrawer walletId={walletId} />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasReport"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <ReportDrawer />
        </div>
      </div>

      <div className="collection-page-main-wrapper custom-container">
        <div className="profile-page-main-wrap">
          <div className="profile-main-wrap">
            <div className="profile-backround-image">
              {uploadedImage ? (
                <img
                  className="uploaded-image"
                  src={uploadedImage}
                  alt="uploaded"
                />
              ) : singleContractData?.contractCoverImage ? (
                <img
                  className="uploaded-image"
                  src={singleContractData?.contractCoverImage}
                  alt="uploaded"
                />
              ) : (
                ""
              )}
            </div>
            {IsContractOwner && (
              <div className="edit-bg-image">
                {coverLoading ? (
                  <>
                    <div className="profile-loading">
                      <Lottie animationData={LoadingLottie} loop={true} />
                    </div>
                  </>
                ) : (
                  <>
                    <img src="/images/icons/edit-profile-icon.svg" alt="img" />
                    <input type="file" onChange={handleImageUpload} />
                  </>
                )}
              </div>
            )}

            <div className="profile-image-wrap collection-profile-wrap">
              {singleContractData?.contractImage ? (
                <img src={singleContractData?.contractImage} alt="img" />
              ) : (
                <img src="/images/icons/avtar.png" alt="img" />
              )}
            </div>
          </div>

          <div className="profile-info-main-wrapper">
            <div className="profile-info-left-wrap">
              <div className="profile-name-wrapper">
                <h2> {singleContractData?.contractName} </h2>

                {/* <img src="/images/icons/verified.png" alt="icon" /> */}

                {IsContractOwner && (
                  <img
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasEditContract"
                    aria-controls="offcanvasExample"
                    className="profile-edit-icon"
                    src="/images/icons/edit.png"
                    alt="icon"
                  />
                )}
              </div>
              <div className="collection-profile-address-wrap">
                <div className="profile-address-wrapper">
                  <p>
                    Created by
                    <span>
                      {shortenWalletId(singleContractData?.ownerAddress, 6, 4)}
                    </span>
                  </p>
                  <div className="copy-address-button">
                    <img
                      src="/images/icons/copy-icon.png"
                      alt="icon"
                      onClick={() =>
                        handleCopyWalletId(
                          singleContractData?.ownerAddress,
                          setIsTooltipVisible
                        )
                      }
                    />
                    <span> {isTooltipVisible && "Copied!"} </span>
                  </div>
                </div>

                <div className="profile-address-wrapper">
                  <p>
                    Address
                    <span className="collection-address">
                      {shortenWalletId(
                        singleContractData?.contractAddress,
                        6,
                        4
                      )}
                    </span>
                  </p>
                  <div className="copy-address-button">
                    <img
                      src="/images/icons/copy-icon.png"
                      alt="icon"
                      onClick={() =>
                        handleCopyWalletId(
                          singleContractData?.contractAddress,
                          setIsTooltip2Visible
                        )
                      }
                    />
                    <span> {isTooltip2Visible && "Copied!"} </span>
                  </div>
                </div>
              </div>

              <div className="profile-desc-wrap">
                <p>{singleContractData?.description}</p>
              </div>
            </div>
            <div className="profile-info-right-wrap">
              <span tooltip="View on Etherscan">
                <img src="/images/icons/etherscan.svg" alt="img" />
              </span>
              {singleContractData?.shortUrl && (
                <span tooltip={singleContractData?.shortUrl}>
                  <img src="/images/icons/website-gray.png" alt="img" />
                </span>
              )}

              <div className="share-btn-wrap">
                <img
                  onMouseEnter={() => setShowShare(true)}
                  onMouseLeave={() => setShowShare(false)}
                  src="/images/icons/share.png"
                  alt="img"
                />

                <div
                  className="share-btn-space-manage"
                  onMouseEnter={() => setShowShare(true)}
                  onMouseLeave={() => setShowShare(false)}
                ></div>
              </div>
              <div className="report-btn-wrap">
                {!IsContractOwner && (
                  <img
                    onMouseEnter={() => setShowReport(true)}
                    onMouseLeave={() => setShowReport(false)}
                    src="/images/icons/menu.png"
                    alt="img"
                  />
                )}

                <div
                  className="report-btn-space-manage"
                  onMouseEnter={() => setShowReport(true)}
                  onMouseLeave={() => setShowReport(false)}
                ></div>
              </div>

              {showShare && (
                <div
                  className="report-button-wrap"
                  onMouseEnter={() => setShowShare(true)}
                  onMouseLeave={() => setShowShare(false)}
                >
                  <div className="share-button-wrapper">
                    <div className="share-icon-wrap">
                      <img src="/images/icons/twitter-white.svg" alt="icon" />
                      <img src="/images/icons/insta-white.svg" alt="icon" />
                      <img src="/images/icons/youtube-white.svg" alt="icon" />
                      <img src="/images/icons/pinetrest-white.svg" alt="icon" />
                    </div>
                  </div>
                </div>
              )}

              {showReport && (
                <div
                  className="report-button-wrap"
                  onMouseEnter={() => setShowReport(true)}
                  onMouseLeave={() => setShowReport(false)}
                >
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasReport"
                    aria-controls="offcanvasExample"
                    className="btn-style"
                  >
                    Report page
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="collection-detail-main-wrapper">
            <div className="collection-detail-main-wrap">
              <div className="collection-detail-repeat">
                <h4> Floor </h4>
                <p> 0.7439 BNB</p>
              </div>

              <div className="collection-detail-repeat">
                <h4> Volume </h4>
                <p> 5.6k BNB</p>
              </div>

              <div className="collection-detail-repeat">
                <h4> Items </h4>
                <p> 1k</p>
              </div>

              <div className="collection-detail-repeat">
                <h4> Owners </h4>
                <p> 219 </p>
              </div>

              <div className="collection-detail-repeat collection-disable-mobile">
                <h4> BlockChain </h4>
                <p> BNB </p>
              </div>

              <div className="collection-detail-repeat collection-disable-mobile">
                <h4> Royalties </h4>
                <p> 5% </p>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-nav-tab">
          <div className="porfile-navigation11">
            <div className="sellpage-tab-main-wrap">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Items
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Activity
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <Items
                    tokenOf={singleContractData?.contractAddress}
                    owner={singleContractData?.ownerAddress}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <CollectionActivity
                    contractAddress={singleContractData?.contractAddress}
                    ownerAddress={singleContractData?.ownerAddress}
                  />
                </div>
              </div>
            </div>
            {/* <ul>
              <li>
                <NavLink to="/collection/items" activeClassName="isActive">
                  Items
                </NavLink>
              </li>
              <li>
                <NavLink to="/collection/activity" activeClassName="isActive">
                  Activity
                </NavLink>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCollection;
