import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer-main-wrapper">
        <div className="footer-main-wrap">
          <div className="footer-left-wrap">
            <img src="/images/icons/logo.png" alt="img" />
            <p>
              ProNFT is the best Nft in the world you can buy, <br /> sell NFT
              art by @Pronft
            </p>
          </div>
          <div className="footer-right-wrap">
            <h3> Follow Us </h3>
            <div className="footer-icons-wrap">
              <img src="/images/icons/twitter.png" alt="icon" />
              <img src="/images/icons/fb-icon.png" alt="icon" />
              <img src="/images/icons/linkedin.png" alt="icon" />
              <img src="/images/icons/youtube.png" alt="icon" />
              <img src="/images/icons/insta.png" alt="icon" />
            </div>
          </div>
        </div>
        <div className="footer-bottom-wrap">
          <p> © 2023 ProNFT. All rights reserved @Gyan Consulting</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
