import React, { useEffect, useState } from "react";
import Overview from "./Tabs/Overview";
import Activity from "./Tabs/Activity";
import Bids from "./Tabs/Bids";
import CheckoutDrawer from "../Drawers/CheckoutDrawer";
import BidDrawer from "../Drawers/BidDrawer";
import "magnific-popup/dist/magnific-popup.css";
import $ from "jquery";
import "magnific-popup";
import { web3 } from "../../constants/constants";
import { useLocation, useParams } from "react-router-dom";
import { serviceGet, servicePost } from "../../helpers/api";
import { shortenWalletId } from "../../helpers/Normalization";
import { auctionContract } from "../../contractDetails/auction";
import { ercAuctionContract } from "../../contractDetails/erc1155Auction";
const AuctionCollectible = require("../../components/abis/AuctionCollectible.json");

const SellPage = () => {
  const [selectedData, setSelectedData] = useState();
  const [tokenDetail, setTokenDetail] = useState(null);
  const [bidButton, showBidButton] = useState(false);
  const [bids, setBids] = useState();
  const [loading, setLoading] = useState(false);

  const { walletId, tokenId } = useParams();

  const location = useLocation();

  // Get User from localStorage....
  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);

  useEffect(() => {
    const receivedState = location.state;
    setSelectedData(receivedState);

    if (userData != null) {
      if (
        userData.walletId != receivedState.owner &&
        receivedState.sale == true
      ) {
        showBidButton(true);
      }
    }
  }, []);

  useEffect(() => {
    $(".without-caption").magnificPopup({
      type: "image",
      closeOnContentClick: true,
      closeBtnInside: false,
      mainClass: "mfp-no-margins mfp-with-zoom", // class to remove default margin from left and right side
      image: {
        verticalFit: true,
      },
      zoom: {
        enabled: true,
        duration: 300, // don't foget to change the duration also in CSS
      },
    });
  }, []);

  // const openPreviewModal = (imageUrl) => {
  //   setSelectedImage(imageUrl);
  // };

  // const closePreviewModal = () => {
  //   setSelectedImage(null);
  // };

  const getTokenDetail = async () => {
    try {
      const tokenResponse = await servicePost("api/nft/getNftSearch", {
        tokenId: tokenId,
        ownerAddress: walletId,
        tokenType: selectedData.tokenType,
        tokenOf: selectedData.tokenOf,
      });

      setTokenDetail(tokenResponse.data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getTokenDetail();
  }, [selectedData, walletId, tokenId]);

  // const highestBidData = (highestBid) => {
  //   // console.log("in", highestBid);
  //   if (highestBid && highestBid.length != 0) {
  //     const ether = web3.utils.fromWei(highestBid[0].amount, "ether");
  //     setItem({
  //       profile: highestBid[0].image,
  //       tag: `${highestBid[0].from}`,
  //       sub: `${ether} ETH`,
  //     });
  //   }
  // };

  const CountDown = new Date(tokenDetail && tokenDetail.endDate * 1000);

  /// get END DATE COUNT
  var yyyy = CountDown.getFullYear();
  var mm = CountDown.getMonth() + 1; // getMonth() is zero-based
  var dd = CountDown.getDate();
  const CountDownDate = ` ${dd}-${mm}-${yyyy}`;

  // Check todayDate and Formet to compare date
  const today = new Date();
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const todayDate = formatDate(today);
  // console.log("todayDate", todayDate);

  // Check SelectedDate and Formet to compare date
  let checkoverDate = tokenDetail && tokenDetail.endDate * 1000;

  function formatDate2(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  const overDate = formatDate2(checkoverDate);
  const endDateover = overDate < todayDate;

  /// Get bids by auctionId by Api here latter
  const getBidsbyId = async () => {
    try {
      const auctionId = tokenDetail && tokenDetail.auctionId;
      let tokendata = await servicePost("api/nft/getbidData", {
        auctionId: auctionId,
      });

      if (tokenDetail && tokenDetail.tokenOf != "PRONFT") {
        const filter = tokendata.bidData?.filter((data) => {
          return data.tokenOf != "PRONFT";
        });
        setBids(filter);
      } else {
        const filter = tokendata.bidData?.filter((data) => {
          return data.tokenOf == "PRONFT";
        });
        setBids(filter);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getBidsbyId();
  }, [tokenDetail && tokenDetail.auctionId]);

  /// GET HIGHEST Bidder
  let highest = 0;
  let highestBiduser = [];

  highest = Math.max.apply(
    Math,
    bids?.map(function (o) {
      // console.log("00000", o.bidPrice);
      return o.bidPrice;
    })
  );
  highestBiduser = bids?.filter(function (obj) {
    return obj.bidPrice == highest;
  });

  const bidderWalletId =
    highestBiduser &&
    highestBiduser.length > 0 &&
    highestBiduser[0].bidderWalletId;

  const currentUser = userData && userData?.walletId;
  const HighestBidder = bidderWalletId == currentUser;
  const bidderBuyNft = endDateover && HighestBidder;

  /// Sell Nft call finalizeAuction method
  const sellNFT = async () => {
    const newowner = currentUser;

    if (tokenDetail && tokenDetail.tokenType == "Multiple") {
      // alert("Multiple SELL TOKEN");
      if (tokenDetail && tokenDetail.tokenOf != "PRONFT") {
        // alert("This is Finalized collectible Token");
        /// Here we will Use AuctionCollectible contract later...
        const AuctionAbi = AuctionCollectible.abi;
        const Auctioncontract = await new web3.eth.Contract(
          AuctionAbi,
          "0xF45b5D833CB1f5B6d7CD782dAB5e2caDE072c687"
        );
        // console.log("Auctioncontract", Auctioncontract);

        try {
          var today = new Date();
          today.setHours(today.getHours() + 1);
          var selectedTime = today.getTime() / 1000;

          setLoading(true);
          await Auctioncontract.methods
            // .finalizeAuction(biddingData.tokenId, selectedTime.toFixed(0))
            .finalizeAuction(tokenDetail.auctionId, "1")
            .send({
              from: newowner,
              gas: 1000000,
            })
            .then(async (val) => {
              let userOptions = new FormData();
              userOptions.append("owner", tokenDetail.owner);
              userOptions.append("auctionId", tokenDetail.auctionId);
              userOptions.append("tokenId", tokenDetail.tokenId);
              userOptions.append("newowner", newowner);
              userOptions.append("transactionHash", val.transactionHash);
              userOptions.append("price", highest);
              userOptions.append("royalityFee", tokenDetail.royalties);
              userOptions.append("tokenType", "Multiple");
              userOptions.append("tokenOf", tokenDetail.tokenOf);
              let tokendata = await servicePost(
                "api/v1/biddata/purchasebid",
                userOptions
              );

              setLoading(false);
              window.open("/user", "_parent");
            })
            .then(async (error) => {
              // ////console.log("auction bid", error);
              setLoading(false);
            });
        } catch (err) {
          console.log("finalizederror", err);
          setLoading(false);
        }
      } else {
        // alert("This is Finalized Multiple");
        try {
          const auction = await ercAuctionContract();
          var today = new Date();
          today.setHours(today.getHours() + 1);
          var selectedTime = today.getTime() / 1000;

          setLoading(true);
          await auction.methods
            // .finalizeAuction(biddingData.tokenId, selectedTime.toFixed(0))
            .finalizeAuction(tokenDetail.auctionId, "1")
            .send({
              from: newowner,
              gas: 1000000,
            })
            .then(async (val) => {
              let userOptions = new FormData();
              userOptions.append("owner", tokenDetail.owner);
              userOptions.append("auctionId", tokenDetail.auctionId);
              userOptions.append("tokenId", tokenDetail.tokenId);
              userOptions.append("newowner", newowner);
              userOptions.append("transactionHash", val.transactionHash);
              userOptions.append("price", highest);
              userOptions.append("royalityFee", tokenDetail.royalties);
              userOptions.append("tokenType", "Multiple");
              userOptions.append("tokenOf", "PRONFT");
              let tokendata = await servicePost(
                "api/v1/biddata/purchasebid",
                userOptions
              );

              setLoading(false);
              window.open("/user", "_parent");
            })
            .then(async (error) => {
              // ////console.log("auction bid", error);
              setLoading(false);
            });
        } catch (err) {
          setLoading(false);
          console.log("finalizederror", err);
        }
      }
    } else {
      if (tokenDetail && tokenDetail.tokenOf != "PRONFT") {
        // alert("This is Finalize Single collectible Token");
        /// Here we will Use AuctionCollectible contract later...
        const AuctionAbi = AuctionCollectible.abi;
        const Auctioncontract = await new web3.eth.Contract(
          AuctionAbi,
          "0xF45b5D833CB1f5B6d7CD782dAB5e2caDE072c687"
        );
        // console.log("Auctioncontract", Auctioncontract);

        try {
          var today = new Date();
          today.setHours(today.getHours() + 1);
          var selectedTime = today.getTime() / 1000;

          setLoading(true);
          await Auctioncontract.methods
            // .finalizeAuction(biddingData.tokenId, selectedTime.toFixed(0))
            .finalizeAuction(tokenDetail.auctionId, "1")
            .send({
              from: newowner,
              gas: 1000000,
            })
            .then(async (val) => {
              let tokendata = await servicePost("api/v1/biddata/purchasebid", {
                owner: tokenDetail.owner,
                auctionId: tokenDetail.auctionId,
                tokenId: tokenDetail.tokenId,
                newOwner: newowner,
                transactionHash: val.transactionHash,
                price: highest,
                royalityFee: tokenDetail.royalties,
                tokenType: "Single",
                tokenOf: tokenDetail.tokenOf,
              });

              setLoading(false);
              window.open("/profile/owned");
            })
            .then(async (error) => {
              setLoading(false);
            });
        } catch (err) {
          setLoading(false);
          console.log("finalizederror", err);
        }
      } else {
        try {
          const auction = await auctionContract();
          var today = new Date();
          today.setHours(today.getHours() + 1);
          var selectedTime = today.getTime() / 1000;

          setLoading(true);
          await auction.methods
            // .finalizeAuction(biddingData.tokenId, selectedTime.toFixed(0))
            .finalizeAuction(tokenDetail.auctionId, "1")
            .send({
              from: newowner,
              gas: 300000,
            })
            .then(async (val) => {
              let tokendata = await servicePost("api/nft/bidPurchaseData", {
                previousOwner: tokenDetail.owner,
                auctionId: tokenDetail.auctionId,
                tokenId: tokenDetail.tokenId,
                newOwner: newowner,
                transactionHash: val.transactionHash,
                price: highest,
                royalityFee: tokenDetail.royalties,
                tokenType: "Single",
                tokenOf: tokenDetail.tokenOf,
              });

              setLoading(false);
              window.open("/profile/owned");
            })
            .then(async (error) => {
              // ////console.log("auction bid", error);
              setLoading(false);
            });
        } catch (err) {
          setLoading(false);
          console.log("finalizederror", err);
        }
      }
    }
  };

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasCheckout"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <CheckoutDrawer selected={tokenDetail} />
            {/* <BidDrawer tokenDetail={tokenDetail} /> */}
          </div>
        </div>
      </div>

      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasBidDrawer"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <BidDrawer
              selected={tokenDetail}
              highestBidPrice={highest}
              biddingData={tokenDetail}
            />
          </div>
        </div>
      </div>

      <div className="sellpage-bg-wrapper">
        <div className="sellpage-main-wrapper custom-container">
          <div className="sellpage-main-wrap">
            <div className="sellpage-left-wrap">
              <div className="sellpage-nft-image-wrapper">
                <div className="sellpage-nft-image-wrap">
                  <a
                    href={tokenDetail?.image}
                    className="without-caption image-link"
                  >
                    {tokenDetail && <img src={tokenDetail?.image} />}
                  </a>
                </div>

                <div className="expend-icon-wrap">
                  <img
                    href={tokenDetail?.image}
                    className="without-caption image-link"
                    // onClick={() => setSelectedImage("/images/nft-1.jpg")}
                    src="/images/icons/expend-icon.svg"
                    alt="icon"
                  />
                </div>
              </div>

              <div className="sellpage-tab-main-wrapper">
                <div className="sellpage-tab-main-wrap">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Overview
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Activity
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        Bids
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <Overview tokenDetail={tokenDetail} />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <Activity selectedData={tokenDetail} />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      <Bids selectedData={tokenDetail} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sellpage-right-wrap">
              <div className="sellpage-nft-header">
                <div className="sellpage-header-left">
                  <div className="sellpage-owner-info">
                    {tokenDetail?.ownerImage ? (
                      <img src={tokenDetail?.ownerImage} alt="img" />
                    ) : (
                      <img src="/images/icons/avtar.png" alt="img" />
                    )}
                    <h4> {tokenDetail && tokenDetail.minterName} </h4>
                  </div>
                  <div className="sellpage-nft-name">
                    <h3> {tokenDetail && tokenDetail?.tokenName} </h3>
                  </div>
                </div>

                {tokenDetail?.tokenType == "Multiple" && (
                  <div className="sellpage-header-right">
                    <img src="/images/icons/copies-icon.png" alt="icon" />
                    <h4>
                      {tokenDetail?.numberOfCopy} of
                      {tokenDetail?.numberOfCopyOnSale}
                    </h4>
                  </div>
                )}
              </div>

              <div className="sellpage-nft-detail-wrapper">
                <div className="sellpage-nft-detail-wrap">
                  <div className="sellpage-creator-wrap">
                    <div className="sellpage-creator-image">
                      <img src="/images/icons/avtar.png" alt="avtar" />
                    </div>
                    <h4>
                      Creator <span> {tokenDetail?.royalties}% royalties </span>
                    </h4>
                    <p> {shortenWalletId(tokenDetail?.creator, 8, 6)} </p>
                  </div>

                  <div className="sellpage-creator-wrap">
                    <div className="sellpage-creator-image">
                      <img src="/images/icons/avtar.png" alt="avtar" />
                      {/* <img
                        className="creator-profile"
                        src="/images/profile.jpg"
                        alt="img"
                      /> */}
                    </div>
                    <h4>Current Owner</h4>
                    <p> {shortenWalletId(tokenDetail?.owner, 8, 6)} </p>
                  </div>
                </div>
              </div>

              <div className="sellpage-price-detail-wrap">
                <div className="sellpage-price-detail">
                  <div className="sellpage-price-left">
                    <p> Price </p>
                    <div className="sellpage-price-wrap">
                      <h3> {tokenDetail?.price} ETH </h3>
                      <img src="/images/icons/eth-icon.png" alt="img" />
                    </div>
                    <p> $605 </p>
                  </div>
                  <div className="sellpage-price-right">
                    <p> Highest Floor Bid </p>
                    <h4> {highest > 0 ? highest : "No bids yet"} </h4>
                  </div>
                </div>
              </div>

              <div className="sellpage-button-wrap">
                {/* {tokenDetail?.instantBuy == true ? (
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasCheckout"
                    aria-controls="offcanvasRight"
                    className="btn-style"
                  >
                    Buy now
                  </button>
                ) : tokenDetail?.instantBuy == false ? (
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasCheckout"
                    aria-controls="offcanvasRight"
                    className="btn-style"
                  >
                    Place a bid
                  </button>
                ) : tokenDetail?.sale == false ? (
                  <button className="btn-style cancel-btn-style">
                    Not For Sale
                  </button>
                ) : (
                  <button className="btn-style cancel-btn-style"></button>
                )} */}

                {bidButton == true ? (
                  <>
                    {tokenDetail?.instantBuy == true && (
                      <div className="sell-btn">
                        <button
                          className="btn-style"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasCheckout"
                          aria-controls="offcanvasRight"
                        >
                          Buy Now
                        </button>
                      </div>
                    )}

                    {tokenDetail?.instantBuy == false &&
                      bidderBuyNft == false &&
                      !endDateover && (
                        <div className="sell-btn">
                          <button
                            className="btn-style"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasBidDrawer"
                            aria-controls="offcanvasRight"
                          >
                            Place a Bid
                          </button>
                        </div>
                      )}

                    {tokenDetail?.instantBuy == false &&
                      bidderBuyNft == true && (
                        <div className="sell-btn">
                          {/* {!loading ? (
                          <button className="btn-style" >Buy</button>
                        ) : (
                          <button disabled>
                            <img
                              className="process-img"
                              src={ProcessImg}
                              alt="prossing"
                            />
                          </button>
                        )} */}
                          <button onClick={sellNFT} className="btn-style">
                            Buy
                          </button>
                        </div>
                      )}
                  </>
                ) : (
                  <>
                    <button className="btn-style cancel-btn-style" disabled>
                      {tokenDetail?.sale == true
                        ? "Item For Sale"
                        : "Item Not For Sale"}
                    </button>

                    {/* <button onClick={sellNFT}> Buy </button> */}
                  </>
                )}

                {/* <button onClick={sellNFT} className="btn-style">
                  Buy
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      {selectedImage && (
        <div id="myModal" className="preview-modal">
          <span onClick={() => setSelectedImage("")} className="close">
            &times;
          </span>
          <img
            className="preview-modal-content"
            src={selectedImage}
            id="img01"
          />
          <div id="caption"></div>
        </div>
      )} */}
    </>
  );
};

export default SellPage;
