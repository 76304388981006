import React, { Fragment, useEffect, useState } from "react";
import SettingNav from "./SettingNav";
import VerifyDrawer from "../Drawers/VerifyDrawer";
import VerifyModal from "../Modals/VerifyModal";
import { serviceGet, servicePost } from "../../helpers/api";
import { ALLOWED_IMAGE_SIZE_IN_BYTES } from "../../constants/utility";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading-white.json";
import LoadingGif from "../lottiefiles/loading.json";
import VerifyReview from "../Drawers/VerifyReview";
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);
  const [showErrorModal, setShowErrormodal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [checkProfileComplete, setCheckProfileComplete] = useState(false);
  const [checkVerifiedStatus, setCheckVerifiedStatus] = useState();
  const [twitterUsername, setTwitterUsername] = useState("");
  const [twitterError, setTwitterError] = useState("");
  const [verifyTwitterLoading, setVerifyTwitterLoading] = useState(false);

  const navigate = useNavigate();

  const [categoryList, setCategoryList] = useState([]);
  const getAllCategories = async () => {
    try {
      const categoryRes = await serviceGet("api/category/getAllCategory");
      setCategoryList(categoryRes.categories);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    website: "",
    shortBio: "",
  });

  const { name, website, shortBio } = formData;

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  //get user from localstorage...
  const user = JSON.parse(localStorage.getItem("user"));
  const walletId = user && user.walletId;

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // reader.readAsDataURL(file);
      try {
        if (file.size > ALLOWED_IMAGE_SIZE_IN_BYTES) {
          alert("File size exceeds maximum file size limit of 12MB");
          return;
        }

        var allowedExtensions = /(jpg|jpeg|png|gif)$/i;
        if (!allowedExtensions.exec(file.type)) {
          alert("Invalid file type. Only jpg, png & gif allowed");
          return;
        }

        setCoverLoading(true);
        const fileTarget = file;
        let formData = new FormData();
        formData.append("image", fileTarget);
        formData.append("walletId", walletId);
        let projectImage = await servicePost(`image/coverupload`, formData);
        setUploadedImage(projectImage.imageUrl);
        setCoverLoading(false);
      } catch (err) {
        setCoverLoading(false);
        console.error(err);
      }
    }
  };

  // upload profile image
  const handleProfileImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // reader.readAsDataURL(file);
      try {
        if (file.size > ALLOWED_IMAGE_SIZE_IN_BYTES) {
          alert("File size exceeds maximum file size limit of 12MB");
          return;
        }

        var allowedExtensions = /(jpg|jpeg|png|gif)$/i;
        if (!allowedExtensions.exec(file.type)) {
          alert("Invalid file type. Only jpg, png & gif allowed");
          return;
        }
        setProfileLoading(true);
        const fileTarget = file;
        let formData = new FormData();
        formData.append("image", fileTarget);
        formData.append("walletId", walletId);
        let projectImage = await servicePost(`image/profileupload`, formData);
        setProfileImage(projectImage.imageUrl);
        setProfileLoading(false);
      } catch (err) {
        setProfileLoading(false);
        console.error(err);
      }
    }
  };

  const handleCategoryClick = (categoryId, categoryName) => {
    if (!selectedCategories) {
      setSelectedCategories([{ id: categoryId, name: categoryName }]);
    } else {
      const categoryIndex = selectedCategories.findIndex(
        (category) => category.id === categoryId
      );

      if (categoryIndex !== -1) {
        // Category already selected, remove it
        setSelectedCategories((prevCategories) =>
          prevCategories.filter((category) => category.id !== categoryId)
        );
      } else {
        // Category not selected, add it
        setSelectedCategories((prevCategories) => [
          ...prevCategories,
          { id: categoryId, name: categoryName },
        ]);
      }
    }
  };

  const getUserDetail = async () => {
    try {
      const userRes = await serviceGet(`user/${walletId}`);
      setUserData(userRes.user);
      const alreadycategoris = userRes.user.categories;

      setSelectedCategories(alreadycategoris);
    } catch (error) {
      console.log("error", error);
    }
  };

  const checkVerifyStatusFun = async () => {
    try {
      const verifyStatusRes = await servicePost("user/getVerifiedStatus", {
        ownerAddress: walletId,
      });
      setCheckVerifiedStatus(verifyStatusRes.status);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
    checkVerifyStatusFun();
  }, [walletId, verifyTwitterLoading]);

  // Check if Values already exist
  useEffect(() => {
    if (userData) {
      setFormData({
        name: !userData.name ? "" : userData.name,
        website: !userData.website ? "" : userData.website,
        shortBio: !userData.shortBio ? "" : userData.shortBio,
      });
      setTwitterUsername(
        userData?.twitterUserName ? userData?.twitterUserName : ""
      );

      if (
        userData.name !== "" &&
        userData.name !== null &&
        selectedCategories?.length > 0 &&
        userData?.twitterVerified
      ) {
        setCheckProfileComplete(true);
      }
    }
  }, [userData, verifyTwitterLoading]);

  // Update input Values and Image
  const updateProfileHandler = async () => {
    setIsLoading(true);

    try {
      const updatedProfile = await servicePost("user/editprofile", {
        walletId: walletId,
        name: name,
        website: website,
        shortBio: shortBio,
        categories: selectedCategories,
        verified: userData.verified,
        profileImageUrl: userData.profileImageUrl,
      });

      setIsLoading(false);
      navigate("/profile/owned");
    } catch (err) {
      console.log("err, err");
      setIsLoading(false);
    }
  };

  // Verify user Twitter Account
  const verifyTwitterAccount = async () => {
    if (twitterUsername == "") {
      alert("Please Enter twitter username");
      return;
    }
    setVerifyTwitterLoading(true);
    try {
      const verifyTwitter = await servicePost("user/twitterVerify", {
        username: twitterUsername,
        walletId: walletId,
      });

      setVerifyTwitterLoading(false);
    } catch (error) {
      // console.log("error", error.response.data.message);
      // setTwitterError(error.response.data[0].message);
      alert(error.response.data.message);
      setVerifyTwitterLoading(false);
    }
  };

  return (
    <>
      <VerifyModal
        show={showErrorModal}
        onHide={() => setShowErrormodal(false)}
      />
      <div
        className="offcanvas offcanvas-end profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasVerify"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <VerifyDrawer />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasReviewVerify"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <VerifyReview />
        </div>
      </div>

      <div className="edit-profile-bg-wrapper">
        <SettingNav />
        <div className="edit-profile-main-wrapper custom-container">
          <div className="profile-main-wrap">
            <div className="profile-backround-image edit-profile-bg-img">
              {uploadedImage ? (
                <img
                  className="uploaded-image"
                  src={uploadedImage}
                  alt="uploadImg"
                />
              ) : userData.coverImageUrl ? (
                <img
                  className="uploaded-image"
                  src={userData.coverImageUrl}
                  alt="coverIMg"
                />
              ) : (
                ""
              )}
            </div>
            <div className="edit-bg-image">
              {coverLoading ? (
                <>
                  <div className="profile-loading">
                    <Lottie animationData={LoadingLottie} loop={true} />
                  </div>
                </>
              ) : (
                <>
                  <img src="/images/icons/edit-profile-icon.svg" alt="img" />
                  <input type="file" onChange={handleImageUpload} />
                </>
              )}
            </div>
            <div className="profile-image-wrap">
              {profileLoading ? (
                <>
                  <div className="profile-loading">
                    <Lottie animationData={LoadingGif} loop={true} />
                  </div>
                </>
              ) : profileImage ? (
                <img
                  className="edit-profile-uploaded"
                  src={profileImage}
                  alt="uploaded"
                />
              ) : userData.profileImageUrl ? (
                <>
                  <div className="uploaded-image-wrap">
                    <img src={userData.profileImageUrl} alt="img" />
                    <div className="edit-hover-icon">
                      <img src="/images/icons/edit-white.svg" alt="icon" />
                      <input type="file" onChange={handleProfileImageUpload} />
                    </div>
                  </div>
                </>
              ) : (
                <div className="edit-profile-pic-wrap">
                  <img
                    className="edit-profile-pic"
                    src="/images/icons/edit-profile-pic.svg"
                    alt="img"
                  />
                  <p>400*400 pixels</p>

                  <div className="upload-profile-input">
                    <input type="file" onChange={handleProfileImageUpload} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="edit-profile-form-wrapper">
            <div className="edit-profile-form-wrap">
              <div className="edit-profile-left">
                <div className="edit-profile-input-repeat">
                  <label> Name* </label>
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    value={name}
                    onChange={inputEventHandler}
                  />
                </div>

                <div className="edit-profile-input-repeat">
                  <label> Website* </label>
                  <input
                    type="text"
                    placeholder="https://"
                    name="website"
                    value={website}
                    onChange={inputEventHandler}
                  />
                  <p> Personal site or portfolio </p>
                  <div className="edit-input-icon">
                    <img src="/images/icons/website-icon.svg" alt="img" />
                  </div>
                </div>

                <div className="edit-profile-input-repeat">
                  <label> Short bio* </label>
                  <textarea
                    placeholder="Describe yourself in a few words"
                    name="shortBio"
                    value={shortBio}
                    maxLength={200}
                    onChange={inputEventHandler}
                  />
                  <p> Max. 200 Characters </p>
                </div>

                <div className="edit-repeat-button-wrap">
                  <button
                    disabled={isLoading}
                    className="btn-style"
                    onClick={updateProfileHandler}
                  >
                    {isLoading ? (
                      <div className="button-loading-gif">
                        <Lottie animationData={LoadingLottie} loop={true} />
                      </div>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                  <button className="btn-style cancel-btn-style">Cancel</button>
                </div>
              </div>
              <div className="edit-profile-right">
                <div className="edit-profile-input-repeat">
                  <label> Category* </label>
                  <div className="category-select-wrapper">
                    {categoryList?.map((data, index) => {
                      return (
                        <Fragment key={index}>
                          <div
                            className={`category-select-repeat ${
                              selectedCategories?.some(
                                (category) => category.id === data.id
                              )
                                ? "selected"
                                : ""
                            }`}
                            key={data.id}
                            onClick={() =>
                              handleCategoryClick(data.id, data.categoryName)
                            }
                          >
                            <div className="category-select-wrap">
                              <h4> {data.categoryName}</h4>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                  {/* <input type="text" placeholder="Enter name" /> */}
                </div>

                <div className="edit-profile-input-repeat">
                  <label> Twitter* </label>
                  <input
                    className="twitter-vefify-input"
                    type="text"
                    placeholder="Enter username"
                    value={twitterUsername}
                    onChange={(e) => {
                      setTwitterUsername(e.target.value);
                    }}
                  />
                  <div className="edit-input-icon">
                    {verifyTwitterLoading ? (
                      <div className="verifying-twitter-wrap">
                        <h4>verifying</h4>
                        <div className="twitter-verify-loading">
                          <Lottie animationData={LoadingGif} loop={true} />
                        </div>
                      </div>
                    ) : !verifyTwitterLoading && !userData?.twitterVerified ? (
                      <h4 onClick={verifyTwitterAccount}> verify </h4>
                    ) : userData?.twitterVerified ? (
                      <h4 className="twitter-verified"> Verified Twitter </h4>
                    ) : null}
                  </div>
                  <div className="twitter-verify-icon">
                    <img src="/images/icons/twitter-dark.svg" alt="icon" />
                  </div>
                </div>

                <div className="edit-profile-verified-wrap">
                  {checkVerifiedStatus == "pending" && checkProfileComplete ? (
                    <span
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasVerify"
                      aria-controls="offcanvasRight"
                    >
                      <div className="verify-button-wrap">
                        <div className="verified-tick-wrap">
                          <img
                            className="verified-tick"
                            src="/images/icons/verified.png"
                            alt="img"
                          />
                          <p> Get verified</p>
                        </div>
                        <img
                          className="verified-send-img"
                          src="/images/icons/verify-send.png"
                          alt="img"
                        />
                      </div>
                    </span>
                  ) : checkVerifiedStatus == "pending" &&
                    !checkProfileComplete ? (
                    <div
                      onClick={() => setShowErrormodal(true)}
                      className="verify-button-wrap"
                    >
                      <div className="verified-tick-wrap">
                        <img
                          className="verified-tick"
                          src="/images/icons/verified.png"
                          alt="img"
                        />
                        <p> Get verified</p>
                      </div>
                      <img
                        className="verified-send-img"
                        src="/images/icons/verify-send.png"
                        alt="img"
                      />
                    </div>
                  ) : checkVerifiedStatus == "inReview" ? (
                    <span
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasReviewVerify"
                      aria-controls="offcanvasRight"
                    >
                      <div className="verify-button-wrap">
                        <div className="verified-tick-wrap">
                          <img
                            className="verified-tick"
                            src="/images/icons/verified.png"
                            alt="img"
                          />
                          <p> In Review </p>
                        </div>
                        <img
                          className="verified-send-img"
                          src="/images/icons/verify-send.png"
                          alt="img"
                        />
                      </div>
                    </span>
                  ) : checkVerifiedStatus == "Verified" ? (
                    <div className="verify-button-wrap">
                      <div className="verified-tick-wrap">
                        <img
                          className="verified-tick"
                          src="/images/icons/verified.png"
                          alt="img"
                        />
                        <p> Verified </p>
                      </div>
                      {/* <img
                        className="verified-send-img"
                        src="/images/icons/verify-send.png"
                        alt="img"
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
