import React, { useEffect, useState } from "react";
import { servicePost } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { data } from "jquery";

const DesktopSeach = ({ searchValue, hideDropdown }) => {
  const [userList, setUserList] = useState([]);
  const [nftList, setNftList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const navigate = useNavigate();

  //get user from localstorage...
  const user = JSON.parse(localStorage.getItem("user"));
  const walletId = user && user.walletId;

  const getSerchFilters = async () => {
    try {
      const serchResponse = await servicePost("user/searchFilter", {
        query: searchValue,
      });
      setUserList(serchResponse.users);
      setNftList(serchResponse.nfts);
      setCollectionList(serchResponse.collections);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSerchFilters();
  }, [searchValue]);

  const goToUserProfile = (data) => {
    if (data.walletId == walletId) {
      navigate("/profile/owned");
      hideDropdown();
    } else {
      navigate(`/user/${data.walletId}/owned`);
      hideDropdown();
    }
  };

  const goToSellPage = (data) => {
    navigate(
      `../token/${data.owner ? data.owner : " "}/${
        data.tokenId ? data.tokenId : " "
      }`,
      { state: data }
    );
    hideDropdown();
  };

  const goToContractPage = (data) => {
    navigate("/collection/items", {
      state: data,
    });
    hideDropdown();
  };

  return (
    <>
      <div className="search-filter-dropdown-wrapper">
        <div className="search-filter-drop-wrap">
          <div className="search-filter-collection-wrap">
            <h3> Collections </h3>
            <div className="collection-filter-wrapper">
              {collectionList?.length === 0 ? (
                <div className="search-filter-empty-wrap">
                  <img src="/images/icons/empty-box.png" alt="icon" />
                  <p> No Collection Found</p>
                </div>
              ) : (
                collectionList?.map((data, index) => {
                  return (
                    <div className="collection-filter-repeat">
                      {data?.contractImage ? (
                        <img
                          onClick={() => goToContractPage(data)}
                          src={data?.contractImage}
                          alt="img"
                        />
                      ) : (
                        <img
                          onClick={() => goToContractPage(data)}
                          src="/images/icons/avtar.png"
                          alt="img"
                        />
                      )}

                      <h4> {data?.contractName} </h4>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div className="nft-search-filter-wrapper">
            <h3> NFTS </h3>
            <div className="collection-filter-wrapper">
              {nftList?.length === 0 ? (
                <div className="search-filter-empty-wrap">
                  <img src="/images/icons/empty-box.png" alt="icon" />
                  <p> No NFTS Found</p>
                </div>
              ) : (
                nftList?.map((data, index) => {
                  return (
                    <div className="nft-filter-repeat" key={index}>
                      <img
                        onClick={() => goToSellPage(data)}
                        src={data?.image}
                        alt="img"
                      />
                      <h4> {data?.tokenName} </h4>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div className="search-filter-collection-wrap">
            <h3> Users </h3>
            <div className="collection-filter-wrapper">
              {userList?.length === 0 ? (
                <div className="search-filter-empty-wrap">
                  <img src="/images/icons/empty-box.png" alt="icon" />
                  <p> No Users Found</p>
                </div>
              ) : (
                userList?.map((data, index) => {
                  return (
                    <div className="collection-filter-repeat" key={index}>
                      {data?.profileImageUrl ? (
                        <img
                          src={data?.profileImageUrl}
                          alt="img"
                          onClick={() => goToUserProfile(data)}
                        />
                      ) : (
                        <img
                          src="/images/icons/avtar.png"
                          alt="img"
                          onClick={() => goToUserProfile(data)}
                        />
                      )}

                      <h4> {data?.name} </h4>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopSeach;
