import { create } from "ipfs-http-client";
//const ipfs = create("https://ipfs.infura.io:5001/api/v0");

// const projectId = "1ztuPwQQ0aBFgG5Lxb0hkXJiF3I";
// const projectSecret = "414236ade76fad058154983bfde8a4f7";

// const projectId = "e1005660255f418aa4f97b1c274a961b";
// const projectSecret = "503df035a7db4946b1382b71f097164b";

const projectId = "29yOKsaOLAdp7UWbLRbeaClaUNl";
const projectSecret = "f5bca8e09a70c66f6b79a5b6388353b2";
// const auth =
//   "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const auth = "Basic " + btoa(`${projectId}:${projectSecret}`);

const ipfs = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  path: "/api/v0/",
  headers: {
    authorization: auth,
  },
});
//console.log(ipfs);
export default ipfs;
