import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ConnectWallet from "../Modals/ConnectWallet";
import CreateDrawer from "./CreateDrawer";

const MenuDrawer = () => {
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [showConnectModal, setShowConnectmodal] = useState(false);
  const navigate = useNavigate();
  const goToNFTs = () => {
    navigate("/explore/all/items");
    document.getElementById("closeMenuDrawer").click();
  };

  const openConnect = () => {
    setShowConnectmodal(true);
    document.getElementById("closeMenuDrawer").click();
  };

  const user = localStorage.getItem("user");
  const logginUser = JSON.parse(user);
  const walletId = logginUser && logginUser.walletId;

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasCreateRight1"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <CreateDrawer />
          </div>
        </div>
      </div>

      <ConnectWallet
        show={showConnectModal}
        onHide={() => setShowConnectmodal(false)}
      />
      <span
        id="closeMenuDrawer"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></span>
      <div className="menu-drawer-wrapper">
        <div className="menu-drawer-wrap">
          <div className="drawer-cancel-btn">
            <button data-bs-dismiss="offcanvas" aria-label="Close">
              <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
            </button>
          </div>

          <div className="drawer-menu-wrap">
            <div className="menu-items-wrap menu-feed-wrap">
              <img src="/images/icons/pro-feed.png" alt="icon" />
              <h5 onClick={goToNFTs}>Pro Feed</h5>
            </div>

            <div className="menu-items-wrap">
              <h5 onClick={goToNFTs}>Explore</h5>
            </div>

            <div className="menu-items-wrap">
              <h5 onClick={goToNFTs}>Sell</h5>
            </div>
          </div>

          {!logginUser ? (
            <div className="menu-drawer-button-wrap">
              <button onClick={openConnect} className="btn-style">
                Connect Wallet
              </button>
            </div>
          ) : (
            <div className="menu-drawer-button-wrap">
              <button
                className="btn-style"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasCreateRight1"
                aria-controls="offcanvasRight"
              >
                Create
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MenuDrawer;
