import React from "react";
import { useNavigate } from "react-router-dom";

const CreateDrawer = () => {
  const navigate = useNavigate();

  const goToCreatePage = () => {
    navigate("/create/single");
    document.getElementById("closeCreatedDrawer").click();
  };

  const goToMultiplePage = () => {
    document.getElementById("closeCreatedDrawer").click();
    navigate("/create/multiple");
  };

  return (
    <>
      <span
        id="closeCreatedDrawer"
        data-bs-dismiss="offcanvas"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasCreateRight1"
        aria-label="Close"
      ></span>

      <div className="create-drawer-main-wrapper">
        <div className="drawer-cancel-btn">
          <button data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
          </button>
        </div>
        <div className="create-drawer-header">
          <h2 className="drawer-heading">Create NFT</h2>
          <p>
            Select 'Single' for a One-of-a-Kind Item or 'Multiple' to Sell a
            Collectible Multiple Times.
          </p>
        </div>

        <div className="create-drawer-type-wrapper">
          <div className="create-drawer-type-wrap">
            <div className="create-drawer-type-repeat" onClick={goToCreatePage}>
              <div className="create-drawer-warp">
                <img src="/images/icons/single-create.svg" alt="img" />
                <div className="create-drawer-type-text">
                  <h3> Single </h3>
                  <p>
                    If you wish to accentuate the uniqueness and individuality
                    of your item...
                  </p>
                </div>
              </div>
            </div>

            <div
              className="create-drawer-type-repeat"
              onClick={goToMultiplePage}
            >
              <div className="create-drawer-warp">
                <img src="/images/icons/multi-create.svg" alt="img" />
                <div className="create-drawer-type-text">
                  <h3> Multiple </h3>
                  <p>
                    If you want to distribute your NFT among a vast community of
                    members...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="create-drawer-footer-wrap">
          <p>
            We do not own your private keys and cannot acces your funds without
            your confirmation
          </p>
        </div>
      </div>
    </>
  );
};

export default CreateDrawer;
