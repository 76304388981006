import React from "react";
import Modal from "react-bootstrap/Modal";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";

const CheckoutModal = (props) => {
  return (
    <Modal
      {...props}
      dialogClassName="checkout-nft-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="checkout-main-wrapper">
          <div className="checkout-main-main-wrap">
            <div className="contract-modal-wrap">
              <div className="contract-modal-contant">
                <div className="contract-deploy-step-wrap">
                  <img src="/images/icons/confirm-tick.png" alt="icon" />
                  <div className="contract-deploye-contact">
                    <h3> Approve </h3>
                    <p>
                      Congratulations! Your Bid has been successfully Placed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CheckoutModal;
