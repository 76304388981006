import React, { useEffect, useState } from "react";
import { serviceGet, servicePost } from "../../../helpers/api";
import { web3 } from "../../../constants/constants";
import { auctionContract } from "../../../contractDetails/auction";
import { ercAuctionContract } from "../../../contractDetails/erc1155Auction";
import { shortenWalletId } from "../../../helpers/Normalization";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import LoadingLottie from "../../lottiefiles/loading-white.json";
const AuctionCollectible = require("../../abis/AuctionCollectible.json");

const BidNftPage = () => {
  const [loading, setLoading] = useState(false);
  const [isMultiple, setIsMultiple] = useState(true);
  const [price, setPrice] = useState(0);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState("");
  const [loggedInUser, setLoggedInUser] = useState();
  const [selected, setSelected] = useState();

  const location = useLocation();
  useEffect(() => {
    const receivedState = location.state;
    setSelected(receivedState);
  }, []);

  // get User from LocalStorage...
  const userData = localStorage.getItem("user");
  const loggedUser = JSON.parse(userData);

  const walletId = loggedUser && loggedUser.walletId;
  // Get Single User Detail

  const setData = async () => {
    if (walletId !== null) {
      const bal = await web3.eth.getBalance(walletId);
      const bal1 = web3.utils.fromWei(bal, "ether");
      const bal2 = parseFloat(bal1).toFixed(4);
      setBalance(bal2);
    }
  };

  useEffect(() => {
    setData();
  }, []);

  const getUserDetail = async () => {
    try {
      const loginUserRes = await serviceGet(`user/${walletId}`);
      setLoggedInUser(loginUserRes.user);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [walletId]);

  // const placeBid = async () => {
  //   let tokendata = await servicePost("api/nft/bid", {
  //     auctionId: 1,
  //     royalties: 10,
  //     transactionHash: "val.transactionHash",
  //     tokenCount: 1,
  //     tokenId: 1,
  //     bidderWalletId: "loggedUser.walletId",
  //     bidStatus: 1,
  //     bidderProfile: loggedInUser.profileImageType,
  //     bidderName: loggedInUser.name,
  //     bidPrice: 100,
  //     auctionOwner: "selected.owner",
  //     serviceFees: 5,
  //     // tokenType: "Single",
  //     // tokenOf: selected.tokenOf,
  //   });

  // };

  const placeBid = async () => {
    if (price < selected.price) {
      alert("Your Price Less then acceptable bid");
      return;
    }

    if (price <= selected?.highestBidPrice && selected?.highestBidPrice) {
      alert("Your bid is not highest then last bid");
      return;
    }

    if (selected && selected.tokenType == "Multiple") {
      // alert("Multiple Token");
      if (selected && selected.tokenOf != "PRONFT") {
        // alert("This is bid for Collectible token");
        /// Here we will use AuctionCOllectible contract Latter...
        const AuctionAbi = AuctionCollectible.abi;
        const Auctioncontract = await new web3.eth.Contract(
          AuctionAbi,
          "0xF45b5D833CB1f5B6d7CD782dAB5e2caDE072c687"
        );

        setLoading(true);

        try {
          const ether = price && web3.utils.toWei(price, "ether");
          const accounts = await web3.eth.getAccounts();

          await Auctioncontract.methods
            .bidOnAuction(
              // selected != undefined ? selected.tokenId : tokenId,
              selected.auctionId,
              "image",
              loggedUser.name
            )
            .send({
              from: accounts[0],
              value: ether,
              gas: 1000000,
            })
            .then(async (val) => {
              setLoading(false);

              let tokendata = await servicePost("api/v1/biddata/addBiddata", {
                tokenType: "Multiple",
                auctionId: selected.auctionId,
                tokenId: selected.tokenId,
                auctionOwner: selected.owner,
                bidderWalletId: loggedUser.walletId,
                transactionHash: val.transactionHash,
                bidPrice: price,
                royalities: selected.eth,
                serviceFees: 5,
                bidStatus: 1,
                bidderName: loggedUser.name,
                bidderProfile: loggedUser.profileImageType,
                tokenOf: selected.tokenOf,
              });

              // toggleDialog();
            })
            .then(async (error) => {});
        } catch (err) {
          setLoading(false);
          // toggleDialog();
        }
      } else {
        if (price < selected.price) {
          alert("Your Price Less then acceptable bid");
          return;
        }

        if (price <= selected?.highestBidPrice && selected?.highestBidPrice) {
          alert("Your bid is not highest then last bid");
          return;
        }
        // alert("This is bid for Snft token");
        setLoading(true);
        try {
          const ether = price && web3.utils.toWei(price, "ether");
          // console.log("ether", ether);
          const accounts = await web3.eth.getAccounts();
          // alert(accounts);
          const contract = await ercAuctionContract();

          await contract.methods
            .bidOnAuction(
              // selected != undefined ? selected.tokenId : tokenId,
              selected.auctionId,
              "image",
              loggedUser.name
            )
            .send({
              from: accounts[0],
              value: ether,
              gas: 1000000,
            })
            .then(async (val) => {
              setLoading(false);
              let tokendata = await servicePost("api/v1/biddata/addBiddata", {
                tokenType: "Multiple",
                auctionId: selected.auctionId,
                tokenId: selected.tokenId,
                auctionOwner: selected.owner,
                bidderWalletId: loggedUser.walletId,
                transactionHash: val.transactionHash,
                bidPrice: price,
                royalities: selected.eth,
                serviceFees: 5,
                bidStatus: 1,
                bidderName: loggedUser.name,
                bidderProfile: loggedInUser.profileImageUrl,
                tokenOf: "PRONFT",
              });
              // console.log("tokenDAta", tokendata);
              // toggleDialog();
              // changeDrawer();
            })
            .then(async (error) => {});
        } catch (err) {
          setLoading(false);
          // toggleDialog();
        }
      }
    } else {
      if (selected && selected.tokenOf != "PRONFT") {
        /// Here we will use AuctionCOllectible contract Latter...
        const AuctionAbi = AuctionCollectible.abi;
        const Auctioncontract = await new web3.eth.Contract(
          AuctionAbi,
          "0xF45b5D833CB1f5B6d7CD782dAB5e2caDE072c687"
        );

        setLoading(true);

        try {
          const ether = price && web3.utils.toWei(price, "ether");

          const accounts = await web3.eth.getAccounts();
          // alert(accounts);
          await Auctioncontract.methods
            .bidOnAuction(
              // selected != undefined ? selected.tokenId : tokenId,
              selected.auctionId,
              "image",
              loggedUser.name
            )
            .send({
              from: accounts[0],
              value: ether,
              gas: 1000000,
            })
            .then(async (val) => {
              setLoading(false);

              let tokendata = await servicePost("api/v1/biddata/addBiddata", {
                tokenType: "Single",
                auctionId: selected.auctionId,
                tokenId: selected.tokenId,
                auctionOwner: selected.owner,
                bidderWalletId: loggedUser.walletId,
                transactionHash: val.transactionHash,
                bidPrice: price,
                royalities: selected.eth,
                serviceFees: 5,
                bidStatus: 1,
                bidderName: loggedUser.name,
                bidderProfile: loggedUser.profileImageType,
                tokenOf: selected.tokenOf,
              });

              // toggleDialog();
              // changeDrawer();
            })
            .then(async (error) => {});
        } catch (err) {
          setLoading(false);
          // toggleDialog();
        }
      } else {
        // alert("Bid PRONFT single Token");
        if (price < selected.price) {
          alert("Your Price Less then acceptable bid");
          return;
        }
        if (price <= selected?.highestBidPrice && selected?.highestBidPrice) {
          alert("Your bid is not highest then last bid");
          return;
        }
        // alert("This is bid for Snft token");
        setLoading(true);
        try {
          const ether = price && web3.utils.toWei(price, "ether");

          const accounts = await web3.eth.getAccounts();
          // alert(accounts);
          const contract = await auctionContract();

          await contract.methods
            .bidOnAuction(
              // selected != undefined ? selected.tokenId : tokenId,
              selected.auctionId,
              "image",
              loggedUser.name
            )
            .send({
              from: accounts[0],
              value: ether,
              gas: 1000000,
            })
            .then(async (val) => {
              setLoading(false);
              // let tokendata = await servicePost("api/v1/biddata/addBiddata", {
              //   tokenType: "single",
              //   auctionId: selected.auctionId,
              //   tokenId: selected.tokenId,
              //   auctionOwner: selected.owner,
              //   bidderWalletId: loggedUser.walletId,
              //   transactionHash: val.transactionHash,
              //   bidPrice: price,
              //   royalities: selected.eth,
              //   serviceFees: 5,
              //   bidStatus: 1,
              //   bidderName: loggedUser.name,
              //   bidderProfile: loggedUser.profileImageType,
              //   tokenOf: "PRONFT",
              // });

              let tokendata = await servicePost("api/nft/bid", {
                auctionId: selected.auctionId,
                royalties: selected.royalties,
                transactionHash: val.transactionHash,
                tokenCount: 1,
                tokenId: selected.tokenId,
                bidderWalletId: loggedUser.walletId,
                bidStatus: 1,
                bidderProfile: loggedInUser.profileImageType,
                bidderName: loggedInUser.name,
                bidPrice: price,
                auctionOwner: selected.owner,
                serviceFees: 5,
                tokenType: "Single",
                tokenOf: selected.tokenOf,
              });

              // document.getElementById("closebidDrawer").click();
            })
            .then(async (error) => {});
        } catch (err) {
          setLoading(false);
          // toggleDialog();
        }
      }
    }
  };

  return (
    <>
      <div className="checkout-main-wrapper android-chackout-wrapper">
        <div className="drawer-cancel-btn">
          <button data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
          </button>
        </div>
        <div className="checkout-drawer-header">
          <h2 className="drawer-heading">Place a bid</h2>
          <p>
            You are about to purchase <b> {selected?.tokenName} </b> from
            <span> "{shortenWalletId(selected?.owner, 14, 8)}" </span>
          </p>
        </div>

        <div className="checkout-multiple-wrapper">
          <div className="checkout-multiple-wrap">
            <h3> {selected?.price} </h3>
            <img src="/images/icons/ethereum.png" alt="icon" />
          </div>
          <div className="checkout-multiple-input bid-single-input">
            <input
              onChange={(e) => setPrice(e.target.value)}
              type="number"
              placeholder="Enter Your bid here"
              value={price}
            />

            <div className="bids-amount-icons">
              <h4> ETH </h4>
              <img src="/images/icons/eth-icon.png" alt="icon" />
            </div>
          </div>
        </div>

        <div className="checkout-detail-main-wrapper">
          <h3> Details </h3>
          <div className="checkout-detail-main-wrap">
            <div className="checkout-detail-repeat">
              <div className="checkout-detail-left">
                <p> Your balance </p>
              </div>
              <div className="checkout-detail-right">
                <h4>
                  {balance} <span> ETH </span>
                </h4>
                <img src="/images/icons/eth-icon.png" alt="icon" />
              </div>
            </div>

            <div className="checkout-detail-repeat">
              <div className="checkout-detail-left">
                <p> Service fee</p>
              </div>
              <div className="checkout-detail-right">
                <h4>
                  2.5 <span> % </span>
                </h4>
                <img src="/images/icons/pro.svg" alt="icon" />
              </div>
            </div>

            <div className="checkout-total-main-wrapper">
              <div className="checkout-total-main-wrap">
                <h2> Total </h2>
                <div className="checkout-total-wrap">
                  <h2> {price ? price : 0} ETH </h2>
                  <img src="/images/icons/eth-icon.png" alt="icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="checkout-button-wrap">
          <button className="btn-style" onClick={placeBid} disabled={loading}>
            {loading ? (
              <div className="button-loading-gif">
                <Lottie animationData={LoadingLottie} loop={true} />
              </div>
            ) : (
              "Proceed"
            )}
          </button>
          <button className="btn-style cancel-btn-style"> Cancel </button>
        </div>
      </div>
    </>
  );
};

export default BidNftPage;
