import React, { useEffect, useState } from "react";
import CheckoutModal from "../../Modals/CheckoutModal";
import { web3 } from "../../../constants/constants";
import { servicePost } from "../../../helpers/api";
import { auctionContract } from "../../../contractDetails/auction";
import { ercAuctionContract } from "../../../contractDetails/erc1155Auction";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import LoadingLottie from "../../lottiefiles/loading-white.json";
import { shortenWalletId } from "../../../helpers/Normalization";

const AuctionCollectible = require("../../abis/AuctionCollectible.json");

function SellNft() {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [balance, setBalance] = useState(0);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    const receivedState = location.state;
    setSelected(receivedState);
  }, []);

  // Increment and Decrement Number
  const incrementAmount = () => {
    setQuantity(Number(quantity) + 1);
  };

  const decrementAmount = () => {
    if (quantity > 1) {
      setQuantity(Number(quantity) - 1);
    }
  };

  var amount = selected?.price;

  var percentage = (amount * 2.5) / 100;
  var pricetopay = (Number(amount) + percentage).toFixed(8);
  var totalprice = Number(amount) + percentage;

  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);
  const WalletId = userData && userData?.walletId;

  const setData = async () => {
    if (WalletId !== null) {
      const bal = await web3.eth.getBalance(WalletId);
      const bal1 = web3.utils.fromWei(bal, "ether");
      const bal2 = parseFloat(bal1).toFixed(4);
      setBalance(bal2);
    }
  };

  useEffect(() => {
    setData();
  }, []);

  /// Sell Nft call finalizeAuction method
  const sellNFT = async () => {
    const newowner = WalletId;

    if (selected && selected.tokenType == "Multiple") {
      // alert("Multiple SELL TOKEN");
      if (selected && selected.tokenOf != "PRONFT") {
        // alert("This is Finalized collectible Token");
        /// Here we will Use AuctionCollectible contract later...
        const AuctionAbi = AuctionCollectible.abi;
        const Auctioncontract = await new web3.eth.Contract(
          AuctionAbi,
          "0xF45b5D833CB1f5B6d7CD782dAB5e2caDE072c687"
        );
        // console.log("Auctioncontract", Auctioncontract);

        try {
          var today = new Date();
          today.setHours(today.getHours() + 1);
          var selectedTime = today.getTime() / 1000;

          setLoading(true);
          await Auctioncontract.methods
            // .finalizeAuction(biddingData.tokenId, selectedTime.toFixed(0))
            .finalizeAuction(selected.auctionId, "1")
            .send({
              from: newowner,
              gas: 1000000,
            })
            .then(async (val) => {
              let userOptions = new FormData();
              userOptions.append("owner", selected.owner);
              userOptions.append("auctionId", selected.auctionId);
              userOptions.append("tokenId", selected.tokenId);
              userOptions.append("newowner", newowner);
              userOptions.append("transactionHash", val.transactionHash);
              userOptions.append("price", selected.highestBidPrice);
              userOptions.append("royalityFee", selected.royalties);
              userOptions.append("tokenType", "Multiple");
              userOptions.append("tokenOf", selected.tokenOf);
              let tokendata = await servicePost(
                "api/v1/biddata/purchasebid",
                userOptions
              );

              setLoading(false);
              window.open("/user", "_parent");
            })
            .then(async (error) => {
              // ////console.log("auction bid", error);
              setLoading(false);
            });
        } catch (err) {
          console.log("finalizederror", err);
          setLoading(false);
        }
      } else {
        // alert("This is Finalized Multiple");
        try {
          const auction = await ercAuctionContract();
          var today = new Date();
          today.setHours(today.getHours() + 1);
          var selectedTime = today.getTime() / 1000;

          setLoading(true);
          await auction.methods
            // .finalizeAuction(biddingData.tokenId, selectedTime.toFixed(0))
            .finalizeAuction(selected.auctionId, "1")
            .send({
              from: newowner,
              gas: 1000000,
            })
            .then(async (val) => {
              let userOptions = new FormData();
              userOptions.append("owner", selected.owner);
              userOptions.append("auctionId", selected.auctionId);
              userOptions.append("tokenId", selected.tokenId);
              userOptions.append("newowner", newowner);
              userOptions.append("transactionHash", val.transactionHash);
              userOptions.append("price", selected.highestBidPrice);
              userOptions.append("royalityFee", selected.royalties);
              userOptions.append("tokenType", "Multiple");
              userOptions.append("tokenOf", "PRONFT");
              let tokendata = await servicePost(
                "api/v1/biddata/purchasebid",
                userOptions
              );

              setLoading(false);
              window.open("/user", "_parent");
            })
            .then(async (error) => {
              // ////console.log("auction bid", error);
              setLoading(false);
            });
        } catch (err) {
          setLoading(false);
          console.log("finalizederror", err);
        }
      }
    } else {
      if (selected && selected.tokenOf != "PRONFT") {
        // alert("This is Finalize Single collectible Token");
        /// Here we will Use AuctionCollectible contract later...
        const AuctionAbi = AuctionCollectible.abi;
        const Auctioncontract = await new web3.eth.Contract(
          AuctionAbi,
          "0xF45b5D833CB1f5B6d7CD782dAB5e2caDE072c687"
        );
        // console.log("Auctioncontract", Auctioncontract);

        try {
          var today = new Date();
          today.setHours(today.getHours() + 1);
          var selectedTime = today.getTime() / 1000;

          setLoading(true);
          await Auctioncontract.methods
            // .finalizeAuction(biddingData.tokenId, selectedTime.toFixed(0))
            .finalizeAuction(selected.auctionId, "1")
            .send({
              from: newowner,
              gas: 1000000,
            })
            .then(async (val) => {
              let tokendata = await servicePost("api/v1/biddata/purchasebid", {
                owner: selected.owner,
                auctionId: selected.auctionId,
                tokenId: selected.tokenId,
                newOwner: newowner,
                transactionHash: val.transactionHash,
                price: selected.highestBidPrice,
                royalityFee: selected.royalties,
                tokenType: "Single",
                tokenOf: selected.tokenOf,
              });

              setLoading(false);
              window.open("/profile/owned");
            })
            .then(async (error) => {
              setLoading(false);
            });
        } catch (err) {
          setLoading(false);
          console.log("finalizederror", err);
        }
      } else {
        try {
          const auction = await auctionContract();
          var today = new Date();
          today.setHours(today.getHours() + 1);
          var selectedTime = today.getTime() / 1000;

          setLoading(true);
          await auction.methods
            // .finalizeAuction(biddingData.tokenId, selectedTime.toFixed(0))
            .finalizeAuction(selected.auctionId, "1")
            .send({
              from: newowner,
              gas: 300000,
            })
            .then(async (val) => {
              let tokendata = await servicePost("api/nft/bidPurchaseData", {
                previousOwner: selected.owner,
                auctionId: selected.auctionId,
                tokenId: selected.tokenId,
                newOwner: newowner,
                transactionHash: val.transactionHash,
                price: selected.highestBidPrice,
                royalityFee: selected.royalties,
                tokenType: "Single",
                tokenOf: selected.tokenOf,
              });

              setLoading(false);
              window.open("/profile/owned");
            })
            .then(async (error) => {
              // ////console.log("auction bid", error);
              setLoading(false);
            });
        } catch (err) {
          setLoading(false);
          console.log("finalizederror", err);
        }
      }
    }
  };

  return (
    <>
      <div className="checkout-main-wrapper android-chackout-wrapper">
        <div className="drawer-cancel-btn">
          <button data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
          </button>
        </div>
        <div className="checkout-drawer-header">
          <h2 className="drawer-heading"> Purchase Bid </h2>
          <p>
            You are about to purchase bid <b> {selected?.tokenName} </b> from
            <span> "{shortenWalletId(selected?.owner, 14, 8)}" </span>
          </p>
        </div>

        <div className="checkout-single-wrap">
          <img src="/images/icons/ethereum.png" alt="icon" />
        </div>

        <div className="checkout-detail-main-wrapper">
          <h3> Details </h3>
          <div className="checkout-detail-main-wrap">
            <div className="checkout-detail-repeat">
              <div className="checkout-detail-left">
                <p> Your balance </p>
              </div>
              <div className="checkout-detail-right">
                <h4>
                  {balance} <span> ETH </span>
                </h4>
                <img src="/images/icons/eth-icon.png" alt="icon" />
              </div>
            </div>

            <div className="checkout-detail-repeat">
              <div className="checkout-detail-left">
                <p> Service fee</p>
              </div>
              <div className="checkout-detail-right">
                <h4>
                  2.5 <span> % </span>
                </h4>
                <img src="/images/icons/pro.svg" alt="icon" />
              </div>
            </div>

            <div className="checkout-total-main-wrapper">
              <div className="checkout-total-main-wrap">
                <h2> Total </h2>
                <div className="checkout-total-wrap">
                  <h2> {totalprice.toFixed(4)} ETH </h2>
                  <img src="/images/icons/eth-icon.png" alt="icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="checkout-button-wrap">
          <button onClick={sellNFT} className="btn-style" disabled={loading}>
            {loading ? (
              <div className="button-loading-gif">
                <Lottie animationData={LoadingLottie} loop={true} />
              </div>
            ) : (
              "Purchase"
            )}
          </button>
          <button
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            className="btn-style cancel-btn-style"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default SellNft;
