// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { servicePost } from "../../helpers/api";
// import Web3 from "web3";
// import { web3 } from "../../constants/constants";
// import Lottie from "lottie-react";
// import LoadingLottie from "../lottiefiles/loading-white.json";

// const AndriodLogin = () => {
//   const [metamaskBrowser, setMetamaskBrowser] = useState();
//   const [requestFor, setRequestFor] = useState("create token");
//   const [cardData, setCardData] = useState("");
//   const [bids, setBids] = useState();
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate();

//   /// GET HIGHEST Bid
//   let highest = 0;
//   let highestBiduser = [];

//   highest = Math.max.apply(
//     Math,
//     bids?.map(function (o) {
//       // console.log("00000", o.bidPrice);
//       return o.bidPrice;
//     })
//   );
//   highestBiduser = bids?.filter(function (obj) {
//     return obj.bidPrice == highest;
//   });

//   /// Get bids by auctionId
//   const getBidsbyId = async () => {
//     try {
//       const auctionId = cardData && cardData?.auctionId;
//       let tokendata = await servicePost("api/v1/biddata/getBiddetail", {
//         auctionId: auctionId,
//       });

//       if (cardData && cardData.tokenOf != "PRONFT") {
//         const filter = tokendata.data?.filter((data) => {
//           return data.tokenOf != "PRONFT";
//         });
//         setBids(filter);
//       } else {
//         const filter = tokendata.data?.filter((data) => {
//           return data.tokenOf == "PRONFT";
//         });
//         setBids(filter);
//       }
//     } catch (error) {
//       console.log("error", error);
//     }
//   };

//   useEffect(() => {
//     getBidsbyId();
//   }, [cardData && cardData?.auctionId]);

//   /// Send card by state and get Info next pages
//   const card = {
//     auctionId: cardData?.auctionId,
//     copyRemaining: cardData?.copyRemaining,
//     creator: cardData?.creator,
//     description: cardData?.description,
//     endDate: cardData?.endDate,
//     fileType: cardData?.fileType,
//     filterCreater: cardData?.filterCreater,
//     filterUserOwner: cardData?.filterUserOwner,
//     ownerId: cardData?.id,
//     image: cardData?.image,
//     instant: cardData?.instantBuy,
//     minterName: cardData?.minterName,
//     newOwner: cardData?.newOwner,
//     numberOfCopy: cardData?.numberOfCopy,
//     numberOfCopyOnSale: cardData?.numberOfCopyOnSale,
//     owner: cardData?.owner,
//     price: cardData?.price,
//     royalityFee: cardData?.royalties,
//     royalities: cardData?.royalties,
//     sale: cardData?.sale,
//     tokenCount: cardData?.tokenCount,
//     tokenId: cardData?.tokenId,
//     tokenName: cardData?.tokenName,
//     tokenOf: cardData?.tokenOf,
//     tokenType: cardData?.tokenType,
//     tokenmeta: cardData?.tokenmeta,
//     transactionHash: cardData?.transactionHash,
//     updatedAt: cardData?.updatedAt,
//     url: cardData?.image,
//     multiple: cardData?.tokenType == "Single" ? false : true,
//     highestBidPrice: highest,
//   };

//   const connectToWallet = (param) => {
//     alert("1");
//     switch (param) {
//       case "MobilemetaMask":
//         connectMobileMetaMask();
//         break;
//       // code block
//     }
//   };

//   const userUuid = window.location.pathname;
//   const userId = userUuid.split("/android-login-connect/");
//   const Uuid = userId[1];

//   // useEffect(() => {
//   //   let userAgent = navigator.userAgent;
//   //   var isMobile = /android/.test(userAgent.toLowerCase());
//   //   const isMetamaskInstalled = typeof window.ethereum !== "undefined";
//   //   setMetamaskBrowser(isMetamaskInstalled);

//   //   if (isMetamaskInstalled) {
//   //     alert("Yes");
//   //   } else {
//   //     alert("Not Installed.");
//   //   }
//   // }, []);

//   useEffect(() => {
//     const userAgent = navigator.userAgent;
//     const isMobile = /android/i.test(userAgent);
//     const isMetamaskLikely = /metamask/i.test(userAgent);
//     const isMetamaskPresent =
//       isMetamaskLikely && typeof window.ethereum !== "undefined";

//     setMetamaskBrowser(isMetamaskPresent);

//     if (isMetamaskPresent) {
//       alert("Yes");
//     } else {
//       alert("No");
//     }
//   }, []);

//   // get Path where user want to go
//   const getuserPath = async () => {
//     const account = await web3.eth.getAccounts();
//     alert(Uuid);

//     try {
//       servicePost(`api/v1/android/android/loginfrommetabrowser`, {
//         walletId: "0x4C623894DFdFe47610e70C935FaDD720881fE929",
//         // uuid: "1705665773471",
//         // walletId: account[0],
//         uuid: Uuid,
//       })
//         .then((response) => {
//           // console.log("Api response", response);
//           setRequestFor(response.requestfor);
//           setCardData(response.data);
//           localStorage.setItem(
//             "requestFor",
//             JSON.stringify(response.requestfor)
//           );
//         })
//         .catch((err) => {});
//     } catch (error) {
//       // console.log("err", error);
//     }
//   };

//   useEffect(() => {
//     getuserPath();
//   }, [Uuid]);

//   // Connect Wallet with metamask in mobile
//   const connectMobileMetaMask = async () => {
//     alert(metamaskBrowser == true);
//     setLoading(true);
//     if (metamaskBrowser) {
//       alert("metamask true");
//       const ethereum = typeof window != undefined ? window.ethereum : {};
//       const web3 = typeof window != undefined ? new Web3(window.ethereum) : {};

//       if (ethereum != undefined) {
//         alert("4");
//         const networkId = await web3.eth.net.getId();
//         if (networkId != 5) {
//           alert("Please Connect Gorila testnet");
//         } else {
//           alert("5");
//           await window.ethereum.request({
//             method: "eth_requestAccounts",
//             params: [{ eth_accounts: {} }],
//           });
//           localStorage.setItem("provider", JSON.stringify("metamask"));
//           submitUserApp(web3);
//         }
//       } else {
//         alert("Please Install Metamask");
//       }
//     } else {
//       alert("this is android browser");
//       // window.open(url);
//       return;
//     }
//   };

//   // Submit User When Connected
//   const submitUserApp = async (web3) => {
//     alert("7");
//     const accounts = await web3.eth.getAccounts();
//     let balance = await web3.eth.getBalance(accounts[0]);
//     try {
//       alert("8");
//       const user = await servicePost("user/signup", {
//         walletId: accounts[0],
//         provider: "Metamask",
//       });

//       alert("9");

//       localStorage.setItem("user", JSON.stringify(user.user));
//       // setShowModalConnecting(true);
//       if (requestFor == "create token") {
//         alert("10");
//         window.open("/android-create-nft", "_parent");
//       } else if (requestFor == "auction") {
//         alert("auction");
//         if (card.multiple == true) {
//           // navigate({ pathname: "/android/multiple", state: card });
//           navigate(`/android/multiple`, { state: card });
//         } else {
//           navigate(`/android/single`, { state: card });
//           // navigate({ pathname: "/android/single", state: { card } });
//         }
//       } else if (requestFor == "bid") {
//         navigate(`/bid/nft`, { state: card });
//       } else if (requestFor == "buy") {
//         navigate(`/buy/nft`, { state: card });
//       } else if (requestFor == "purchase") {
//         // navigate({
//         //   pathname: `/buybid/nft`,
//         //   state: card,
//         // });
//         navigate(`/buybid/nft`, { state: card });
//       }
//       // props.onHide();
//       // setTimeout(() => {
//       //   window.location.reload();
//       // }, 3000);
//     } catch (error) {
//       console.error("Error occurred:", error.message);
//     }
//   };

//   let heading;
//   if (requestFor == "create token") {
//     heading = "Create NFT";
//   } else if (requestFor == "auction") {
//     heading = "Create NFT auction";
//   } else if (requestFor == "bid") {
//     heading = "Place Bid";
//   } else if (requestFor == "buy") {
//     heading = "Buy NFT";
//   } else if (requestFor == "purchase") {
//     heading = "Purchase NFT";
//   }
//   return (
//     <div className="andriod-login-wrapper">
//       <div className="andriod-login-wrap">
//         <div className="android-login-connect-wrapper">
//           {requestFor ? (
//             <>
//               <h2> {heading} </h2>
//               <p>You need to click Proceed button to go next step</p>
//               {loading ? (
//                 <button className="btn-style">
//                   <div className="android-button-loading-gif">
//                     <Lottie animationData={LoadingLottie} loop={true} />
//                   </div>
//                 </button>
//               ) : (
//                 <button
//                   className="btn-style"
//                   onClick={() => connectToWallet("MobilemetaMask")}
//                 >
//                   Proceed
//                 </button>
//               )}
//             </>
//           ) : (
//             <button className="btn-style">
//               <div className="android-button-loading-gif">
//                 <Lottie animationData={LoadingLottie} loop={true} />
//               </div>
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AndriodLogin;
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { web3 } from "../../constants/constants";
import { servicePost } from "../../helpers/api";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading-white.json";
import { useNavigate } from "react-router-dom";

const AndriodLogin = () => {
  const [metamaskBrowser, setMetamaskBrowser] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [requestFor, setRequestFor] = useState("");
  const [cardData, setCardData] = useState("");
  const [bids, setBids] = useState();
  const [loading, setLoading] = useState(false);
  // console.log("bids", bids);
  // alert(requestFor);

  const navigate = useNavigate();

  /// GET HIGHEST Bid
  let highest = 0;
  let highestBiduser = [];

  highest = Math.max.apply(
    Math,
    bids?.map(function (o) {
      // console.log("00000", o.bidPrice);
      return o.bidPrice;
    })
  );
  highestBiduser = bids?.filter(function (obj) {
    return obj.bidPrice == highest;
  });

  //   Get bids by auctionId
  const getBidsbyId = async () => {
    try {
      const auctionId = cardData && cardData?.auctionId;
      let tokendata = await servicePost("api/v1/biddata/getBiddetail", {
        auctionId: auctionId,
      });

      if (cardData && cardData.tokenOf != "PRONFT") {
        const filter = tokendata.data?.filter((data) => {
          return data.tokenOf != "PRONFT";
        });
        setBids(filter);
      } else {
        const filter = tokendata.data?.filter((data) => {
          return data.tokenOf == "PRONFT";
        });
        setBids(filter);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getBidsbyId();
  }, [cardData && cardData?.auctionId]);

  //  Send card by state and get Info next pages
  const card = {
    auctionId: cardData?.auctionId,
    copyRemaining: cardData?.copyRemaining,
    creator: cardData?.creator,
    description: cardData?.description,
    endDate: cardData?.endDate,
    fileType: cardData?.fileType,
    filterCreater: cardData?.filterCreater,
    filterUserOwner: cardData?.filterUserOwner,
    ownerId: cardData?.id,
    image: cardData?.image,
    instant: cardData?.instantBuy,
    minterName: cardData?.minterName,
    newOwner: cardData?.newOwner,
    numberOfCopy: cardData?.numberOfCopy,
    numberOfCopyOnSale: cardData?.numberOfCopyOnSale,
    owner: cardData?.owner,
    price: cardData?.price,
    royalityFee: cardData?.royalties,
    royalities: cardData?.royalties,
    sale: cardData?.sale,
    tokenCount: cardData?.tokenCount,
    tokenId: cardData?.tokenId,
    tokenName: cardData?.tokenName,
    tokenOf: cardData?.tokenOf,
    tokenType: cardData?.tokenType,
    tokenmeta: cardData?.tokenmeta,
    transactionHash: cardData?.transactionHash,
    updatedAt: cardData?.updatedAt,
    url: cardData?.image,
    multiple: cardData?.tokenType == "Single" ? false : true,
    highestBidPrice: highest,
  };

  const connectToWallet = (param) => {
    switch (param) {
      case "MobilemetaMask":
        connectMobileMetaMask();
        break;
      // code block
    }
  };

  const userUuid = window.location.pathname;
  const userId = userUuid.split("/android-login-connect/");
  const Uuid = userId[1];

  alert(Uuid);
  console.log("pathname", Uuid);

  // useEffect(() => {
  //   let userAgent = navigator.userAgent;
  //   var isMobile = /android/.test(userAgent.toLowerCase());
  //   var isMetamaskBrowser = /metamask/.test(userAgent.toLowerCase());
  //   alert(isMobile);
  //   setMetamaskBrowser(isMetamaskBrowser);
  //   console.log("Hello world", userAgent);
  // }, []);

  // get Path where user want to go
  const getuserPath = async () => {
    const account = await web3.eth.getAccounts();
    alert(Uuid);

    try {
      servicePost(`api/v1/android/android/loginfrommetabrowser`, {
        walletId: "0x4C623894DFdFe47610e70C935FaDD720881fE929",
        // uuid: "1705665773471",
        // walletId: account[0],
        uuid: Uuid,
      })
        .then((response) => {
          console.log("Api response", response);
          setRequestFor(response.requestfor);
          setCardData(response.data);
          localStorage.setItem(
            "requestFor",
            JSON.stringify(response.requestfor)
          );
        })
        .catch((err) => {});
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    getuserPath();
  }, [Uuid]);

  //  Connect Wallet with metamask in mobile
  const connectMobileMetaMask = async () => {
    setLoading(true);
    if (true) {
      const ethereum = typeof window != undefined ? window.ethereum : {};
      const web3 = typeof window != undefined ? new Web3(window.ethereum) : {};
      //alert(ethereum);
      if (typeof window.ethereum.isMetaMask !== "undefined") {
        const networkId = await web3.eth.net.getId();
        alert(networkId);
        if (networkId != 5) {
          setModalVisible(true);
        } else {
          await window.ethereum.request({
            method: "eth_requestAccounts",
            params: [{ eth_accounts: {} }],
          });
          localStorage.setItem("provider", JSON.stringify("metamask"));
          submitUserApp(web3);
        }
      } else {
        // alert("Please Install Metamask");
        setErrorContent("Please Install Metamask");
        setModalVisible1(true);
      }
    } else {
      // alert("this is android browser");
      // window.open(url);
      return;
    }
  };

  // Androind sumbit user
  const submitUserApp = async (web3) => {
    alert("submitUserApp");
    const accounts = await web3.eth.getAccounts();
    alert(accounts[0]);
    // console.log("accounts", accounts);
    let balance = await web3.eth.getBalance(accounts[0]);

    if (accounts.length > 0) {
      balance = web3.utils.fromWei(balance, "ether");
      balance = parseFloat(balance).toFixed(4);
    }

    if (requestFor == "create token") {
      window.open("/create-nft", "_parent");
    } else if (requestFor == "auction") {
      if (card.multiple == true) {
        navigate({ pathname: "/android/multiple", state: card });
      } else {
        navigate({ pathname: "/android/single", state: card });
      }
    } else if (requestFor == "bid") {
      navigate({
        pathname: `/bid/nft`,
        state: card,
      });
    } else if (requestFor == "buy") {
      navigate({
        pathname: `/buy/nft`,
        state: card,
      });
    } else if (requestFor == "purchase") {
      navigate({
        pathname: `/buybid/nft`,
        state: card,
      });
    }
  };

  let heading;
  if (requestFor == "create token") {
    heading = "Create NFT";
  } else if (requestFor == "auction") {
    heading = "Create NFT auction";
  } else if (requestFor == "bid") {
    heading = "Place Bid";
  } else if (requestFor == "buy") {
    heading = "Buy NFT";
  } else if (requestFor == "purchase") {
    heading = "Purchase NFT";
  }

  return (
    <>
      <div className="andriod-login-wrapper">
        <div className="andriod-login-wrap">
          <div className="android-login-connect-wrapper">
            {requestFor ? (
              <>
                <h2> {heading} </h2>
                <p>You need to click Proceed button to go next step</p>
                {loading ? (
                  <button className="btn-style">
                    <div className="android-button-loading-gif">
                      <Lottie animationData={LoadingLottie} loop={true} />
                    </div>
                  </button>
                ) : (
                  <button
                    className="btn-style"
                    onClick={() => connectToWallet("MobilemetaMask")}
                  >
                    Proceed
                  </button>
                )}
              </>
            ) : (
              <button
                className="btn-style"
                onClick={() => connectToWallet("MobilemetaMask")}
              >
                Proceed
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AndriodLogin;
