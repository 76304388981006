import React from "react";

const PreviewCard = ({ uploadedImage, price, name, fileType }) => {
  return (
    <>
      <div className="create-nft-preview-wrapper">
        <div className="create-nft-preview-wrap">
          <h3> Preview </h3>
          <div className="create-nft-preview-card">
            {!uploadedImage ? (
              <div className="create-preview-card">
                <p>
                  Upload file and choose collection to preview your brand new
                  NFT
                </p>
              </div>
            ) : (
              <div className="home-nft-card-repeat">
                <div className="nft-card-wrap">
                  <div className="nft-image-wrap">
                    {fileType != undefined ? (
                      fileType.type == "video" ? (
                        <video controls>
                          <source src={uploadedImage} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          className="preview-image"
                          src={uploadedImage !== "" && uploadedImage}
                          alt="preview"
                        />
                      )
                    ) : null}
                    {/* <img src={uploadedImage} alt="img" /> */}
                    <div className="nft-platfrom-wrap">
                      <img src="/images/icons/pro-logo.png" alt="img" />
                    </div>
                  </div>

                  <div className="owner-info-wrap">
                    <div className="owner-detail">
                      <img src="/images/icons/avtar.png" alt="img" />
                      {name ? <h3>{name}</h3> : <h3> unnamed</h3>}
                    </div>
                  </div>

                  <div className="nft-card-info-wrap">
                    <div className="nft-card-left-info">
                      <p> Current Price </p>
                      <div className="nft-card-price-wrap">
                        {price ? <h4> {price} </h4> : <h4>---</h4>}
                        <img src="/images/icons/eth-icon.png" alt="icon" />
                      </div>
                      <p> $605</p>
                    </div>
                    <div className="nft-card-right-info">
                      <p> Current Price </p>
                      <div className="nft-card-bid-wrap">
                        <h4> No bids yet </h4>
                        <img src="/images/icons/bid-icon.svg" alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewCard;
