import React from "react";
import { shortenWalletId } from "../../../helpers/Normalization";

const Overview = ({ tokenDetail }) => {
  return (
    <>
      <div className="overview-main-wrapper">
        <div className="overiew-main-wrap">
          <div className="overview-description-wrap">
            <h3> Description </h3>
            <p>
              {tokenDetail?.description}
              {/* <span> Show More </span> */}
            </p>
          </div>

          <div className="overview-royalties-wrapper">
            <div className="overview-royalties-wrap">
              <h3>
                Royalties <span> {tokenDetail?.royalties}% </span>
              </h3>
              <p>
                Seamless Royalty Allocation: Instant Deposits to Recipients'
                Wallets
              </p>

              <div className="overview-owner-wrap">
                <img src="/images/icons/avtar.png" alt="img" />
                {/* <img
                  className="owner-dynamic-img"
                  src="/images/nft-1.jpg"
                  alt="img"
                /> */}
                <div className="overview-owner-address-wrap">
                  <p> {shortenWalletId(tokenDetail?.creator, 10, 6)} </p>
                  <p> 100% </p>
                </div>
              </div>
            </div>
          </div>

          <div className="overview-detail-main-wrapper">
            <h3> Details </h3>
            <div className="overview-detail-main-wrap">
              <div className="overview-detail-repeat">
                <p> Category </p>
                <div className="overview-detail-right">
                  {tokenDetail?.tokenOf === "PRONFT" ? (
                    <>
                      <p> PRONFT </p>
                      <img src="/images/icons/pro.svg" alt="icon" />
                    </>
                  ) : (
                    <>
                      <p> Collectibles </p>
                      <img src="/images/icons/copies-icon.png" alt="icon" />
                    </>
                  )}
                </div>
              </div>

              <div className="overview-detail-repeat">
                <p> Network </p>
                <div className="overview-detail-right">
                  <p> BNB </p>
                  <img src="/images/icons/eth-icon.png" alt="icon" />
                </div>
              </div>

              <div className="overview-detail-repeat">
                <p> Contract Address </p>
                <div className="overview-detail-right">
                  <p>
                    <span>
                      {shortenWalletId(tokenDetail?.repositoryAddress, 10, 4)}
                    </span>
                  </p>
                </div>
              </div>

              <div className="overview-detail-repeat">
                <p> Token ID </p>
                <div className="overview-detail-right">
                  <p> {tokenDetail?.tokenId} </p>
                </div>
              </div>

              <div className="overview-detail-repeat">
                <p> Platform Fee </p>
                <div className="overview-detail-right">
                  <p>2.5%</p>
                  <img src="/images/icons/pro.svg" alt="icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
