import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import TypeDropdown from "../../Dropdown/TypeDropdown";
import Explore from "../Explore";
import PriceFilter from "../../Dropdown/PriceFilter";
import { useNavigate } from "react-router-dom";

const AllCollecions = () => {
  const [topCollection, setTopCollection] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();
  const goToCollectionPage = () => {
    navigate("/collection/items");
  };

  const handlePriceChange = (range) => {
    setSelectedPriceRange(range);
  };

  useEffect(() => {
    const fetchCollectionRanking = async () => {
      try {
        const response = await axios.get(
          "https://restapi.nftscan.com/api/v2/statistics/ranking/collection?sort_field=volume_1d&sort_direction=desc&limit=100",
          {
            headers: {
              "X-API-KEY": "YdXzJXf3E1BcTXeZ0ALCO7n4",
            },
          }
        );
        const data = response.data.data;
        // Sort the collection ranking data based on floor_price in ascending order
        const sortedDataAsc = data
          .slice()
          .sort((a, b) => a.floor_price - b.floor_price);

        // Sort the collection ranking data based on floor_price in descending order
        const sortedDataDesc = data
          .slice()
          .sort((a, b) => b.floor_price - a.floor_price);

        if (selectedPriceRange && selectedPriceRange == "Low to high") {
          setTopCollection(sortedDataAsc);
        } else if (selectedPriceRange && selectedPriceRange == "High to low") {
          setTopCollection(sortedDataDesc);
        } else {
          setTopCollection(data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCollectionRanking();
  }, [selectedPriceRange]);

  const filterTokenData = () => {
    if (searchValue === undefined || searchValue === "") {
      const searchedData = topCollection?.filter((ele) => ele.contract_name);
      setFilteredData(searchedData);
    } else {
      const searchKeyword = searchValue?.trim()?.toLowerCase();
      const searchedData = topCollection?.filter(
        (ele) =>
          ele.contract_name &&
          ele.contract_name.toLowerCase().includes(searchKeyword)
      );
      setFilteredData(searchedData);
    }
  };

  useEffect(() => {
    filterTokenData();
  }, [topCollection, searchValue]);

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasCollectionFilter"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <div className="profile-mobile-filter-wrapper">
              <div className="profile-mobile-filter-wrap">
                <div className="drawer-cancel-btn">
                  <button data-bs-dismiss="offcanvas" aria-label="Close">
                    <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                  </button>
                </div>
                <h2 className="drawer-heading"> Filters </h2>
                <PriceFilter onPriceChange={handlePriceChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="explore-bg-wrapper">
        <div className="all-collections-main-wrapper custom-container">
          <div className="all-collection-main-wrap">
            <div className="explore-nft-header-wrap">
              <Explore />
              <div className="profile-owned-filter-wrap">
                <div className="activity-owned-nft-search">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <img
                    className="search-owned-icon"
                    src="/images/icons/search-icon.svg"
                    alt="icon"
                  />
                </div>
                <div className="filter-mobile-view">
                  <img
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasCollectionFilter"
                    aria-controls="offcanvasRight"
                    src="/images/icons/filter-icon.svg"
                    alt="icon"
                  />
                </div>
                <div className="filter-desktop-view">
                  <PriceFilter onPriceChange={handlePriceChange} />
                </div>
              </div>
            </div>

            <div className="collection-data-wrapper allcollection-data-wrapper table-responsive">
              <table className="table collection-table  table-borderless">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{ textAlign: "left", width: "60px" }}
                    >
                      #
                    </th>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Collection
                    </th>
                    <th scope="col">Floor Price </th>
                    <th scope="col">Volumn</th>
                    <th scope="col">Items</th>
                    <th scope="col">Owners</th>
                  </tr>
                  <div className="table-head-space"></div>
                </thead>
                <tbody>
                  {filteredData?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: "left" }}>
                          <p className="collection-rank-number">{index + 1}</p>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <div className="collection-table-info-wrap">
                            <div
                              className="collection-image"
                              // onClick={goToCollectionPage}
                            >
                              {data.logo_url ? (
                                <img src={data.logo_url} alt="img" />
                              ) : (
                                <img src="/images/icons/avtar.png" alt="img" />
                              )}
                            </div>

                            {data.contract_name ? (
                              data.contract_name
                            ) : (
                              <span className="span-for-empty-data"> - </span>
                            )}
                            {data.opensea_verified && (
                              <img
                                className="collection-verfied-logo"
                                src="/images/icons/verified.png"
                                alt="img"
                              />
                            )}
                          </div>
                        </td>
                        <td>
                          {data.floor_price ? (
                            `${data.floor_price} BNB`
                          ) : (
                            <span className="span-for-empty-data"> - </span>
                          )}
                        </td>
                        <td>
                          {data.volume_1d ? (
                            `${data.volume_1d.toFixed(2)} BNB`
                          ) : (
                            <span className="span-for-empty-data"> - </span>
                          )}

                          <span
                            className={`volumn-changes ${
                              data.volume_change_1d.startsWith("-")
                                ? "negative"
                                : "positive"
                            }`}
                          >
                            {data.volume_change_1d}
                          </span>
                        </td>
                        <td>
                          {data.items_total ? (
                            data.items_total >= 1000 ? (
                              data.items_total % 1000 === 0 ? (
                                `${data.items_total / 1000}k`
                              ) : (
                                `${(data.items_total / 1000).toFixed(1)}k`
                              )
                            ) : (
                              data.items_total
                            )
                          ) : (
                            <span className="span-for-empty-data"> - </span>
                          )}
                        </td>
                        <td>
                          {data.owners_total ? (
                            data.owners_total >= 1000 ? (
                              data.owners_total % 1000 === 0 ? (
                                `${data.owners_total / 1000}k`
                              ) : (
                                `${(data.owners_total / 1000).toFixed(1)}k`
                              )
                            ) : (
                              data.owners_total
                            )
                          ) : (
                            <span className="span-for-empty-data"> - </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCollecions;
