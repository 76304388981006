import React, { Fragment, useState } from "react";
import FeedNav from "../FeedNav";
import ReportDrawer from "../../Drawers/ReportDrawer";
import PostCommantDrawer from "../../Drawers/PostCommantDrawer";

const FEEDLIST = [
  {
    postImage: "/images/feed-1.jpg",
    name: "Alessandro",
    profile: "/images/profile.jpg",
    description:
      "Alessandro To the outside world, creating posts for social media may seem easy. But, content creators know there’s a lot more to...",
    type: "post",
    likes: "27",
    comments: "10",
    share: "2",
    date: "17h",
  },
  {
    postImage: "",
    name: "Genesis",
    profile: "/images/profile.jpg",
    description:
      "@Genesis 🔥 Brand color psychology is the study of how color impacts the way we perceive a brand. Colors have a powerful effect on our emotions. And these emotions play a major role in how we behave as consumers. An understanding of color psychology in branding gives you one more tool in the underlying goal of branding: shaping the perceptions that drive consumer behavior. Saved this post for future reference 💯💜 Follow my account @_therahulyadav__ for more posts like this 👊💯",
    type: "twitte",
    likes: "56",
    comments: "18",
    share: "5",
    date: "1d",
  },

  {
    postImage: "",
    name: "Genesis",
    profile: "/images/profile.jpg",
    description:
      "@Genesis 🔥 Brand color psychology is the study of how color impacts the way we perceive a brand. Colors have a powerful effect on our emotions. And these emotions play a major role in how we behave as consumers. An understanding of color psychology in branding gives you one more tool in the underlying goal of branding: shaping the perceptions that drive consumer behavior. Saved this post for future reference 💯💜 Follow my account @_therahulyadav__ for more posts like this 👊💯",
    type: "twitte",
    likes: "56",
    comments: "18",
    share: "5",
    date: "1d",
  },

  {
    postImage: "/images/nft-1.jpg",
    name: "Alessandro",
    profile: "/images/profile.jpg",
    description:
      "Alessandro To the outside world, creating posts for social media may seem easy. But, content creators know there’s a lot more to...",
    type: "post",
    likes: "27",
    comments: "10",
    share: "2",
    date: "17h",
  },
];

const Feed = () => {
  const [showReportbtn, setShowReportbtn] = useState(false);
  return (
    <>
      <div
        className="offcanvas offcanvas-end profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasReport"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <ReportDrawer />
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end profile-canvas-wrap"
        tabIndex="-1"
        id="offcanvasPostComment"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <PostCommantDrawer />
        </div>
      </div>

      <div className="feed-bg-wrapper">
        <div className="feed-main-wrapper custom-container">
          <FeedNav activeTab="Feed" />
          <div className="feed-main-wrap">
            {FEEDLIST?.map((data, index) => {
              return (
                <Fragment key={index}>
                  {data.type == "post" ? (
                    <div className="feed-post-repeate-wrap">
                      <div className="feed-user-profile-wrap">
                        <img src="/images/profile.jpg" alt="avtar" />
                        <div className="feed-user-detail-wrap">
                          <h3> {data.name} </h3>
                          <p> {data.date} </p>
                        </div>
                      </div>

                      <div
                        className="feed-menu-options"
                        onMouseEnter={() => setShowReportbtn(true)}
                        onMouseLeave={() => setShowReportbtn(false)}
                      >
                        <img src="/images/icons/menu.png" alt="icon" />
                        {showReportbtn && (
                          <button
                            className="btn-style"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasReport"
                            aria-controls="offcanvasExample"
                          >
                            Report
                          </button>
                        )}
                      </div>

                      <div className="post-image-wrapper">
                        <img src={data?.postImage} alt="img" />
                      </div>
                      <div className="feed-repeat-bottom-wrapper">
                        <div className="feed-socail-media-wrap">
                          <div className="feed-socail-left">
                            <div className="feed-socail-like-wrap">
                              <img
                                src="/images/icons/like-active.svg"
                                alt="icon"
                              />
                              <p> {data.likes} likes</p>
                            </div>

                            <div className="feed-socail-like-wrap">
                              <img
                                // data-bs-toggle="offcanvas"
                                // data-bs-target="#offcanvasPostComment"
                                // aria-controls="offcanvasExample"
                                src="/images/icons/feed-comment.svg"
                                alt="icon"
                              />
                              <p> {data.comments} comments</p>
                            </div>
                          </div>
                          <div className="feed-socail-right">
                            <div className="feed-socail-like-wrap">
                              <img
                                src="/images/icons/feed-share.svg"
                                alt="icon"
                              />
                              <p> {data.share} </p>
                            </div>
                          </div>
                        </div>

                        <div className="feed-description-main-wrap">
                          <p>
                            <span> {data.name} </span> {data.description}
                            <span className="see-more"> more </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="feed-twitte-repeate-wrap">
                      <div className="feed-user-profile-wrap">
                        <img src="/images/profile.jpg" alt="avtar" />
                        <div className="feed-user-detail-wrap">
                          <h3> {data.name} </h3>
                          <p> {data.date} </p>
                        </div>
                      </div>

                      <div className="feed-menu-options">
                        <img src="/images/icons/menu.png" alt="icon" />
                      </div>

                      <div className="feed-twitte-wrap">
                        <p>{data.description}</p>
                      </div>

                      <div className="feed-socail-media-wrap">
                        <div className="feed-socail-left">
                          <div className="feed-socail-like-wrap">
                            <img
                              src="/images/icons/like-active.svg"
                              alt="icon"
                            />
                            <p> {data.likes} likes</p>
                          </div>

                          <div className="feed-socail-like-wrap">
                            <img
                              src="/images/icons/feed-comment.svg"
                              alt="icon"
                            />
                            <p> {data.comments} comments</p>
                          </div>
                        </div>
                        <div className="feed-socail-right">
                          <div className="feed-socail-like-wrap">
                            <img
                              src="/images/icons/feed-share.svg"
                              alt="icon"
                            />
                            <p> {data.share} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Feed;
