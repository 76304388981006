import React from "react";
import HomeSilder from "./HomeSilder";
import Category from "./Category";
import Users from "./Users";
import NFTList from "./NFTList";
import Collections from "./Collections";
import Guide from "./Guide";
import Contact from "./Contact";
import Footer from "../footer/Footer";

const Home = () => {
  return (
    <>
      <div className="home-main-wrapper">
        <div className="home-page-slider">
          <HomeSilder />
        </div>
        <Category />
        {/* <Users /> */}
        <NFTList />
        <Collections />
        <Guide />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Home;
