import React, { useEffect, useState } from "react";
import CheckoutModal from "../../Modals/CheckoutModal";
import { web3 } from "../../../constants/constants";
import { servicePost } from "../../../helpers/api";
import { auctionContract } from "../../../contractDetails/auction";
import { ercAuctionContract } from "../../../contractDetails/erc1155Auction";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import LoadingLottie from "../../lottiefiles/loading-white.json";
import { shortenWalletId } from "../../../helpers/Normalization";

const AuctionCollectible = require("../../abis/AuctionCollectible.json");

const BuyNftPage = () => {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState("");
  const [balance, setBalance] = useState(0);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    const receivedState = location.state;
    setSelected(receivedState);
  }, []);

  // Increment and Decrement Number
  const incrementAmount = () => {
    setQuantity(Number(quantity) + 1);
  };

  const decrementAmount = () => {
    if (quantity > 1) {
      setQuantity(Number(quantity) - 1);
    }
  };

  var amount = selected?.price;

  var percentage = (amount * 2.5) / 100;
  var pricetopay = (Number(amount) + percentage).toFixed(8);
  var totalprice = Number(amount) + percentage;

  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);
  const WalletId = userData && userData?.walletId;

  const setData = async () => {
    if (WalletId !== null) {
      const bal = await web3.eth.getBalance(WalletId);
      const bal1 = web3.utils.fromWei(bal, "ether");
      const bal2 = parseFloat(bal1).toFixed(4);
      setBalance(bal2);
    }
  };

  useEffect(() => {
    setData();
  }, []);

  const setProgressOn = async () => {
    // const auction = await auctionContract();
    const accounts = await web3.eth.getAccounts();
    const ether = web3.utils.toWei(pricetopay, "ether");
    const gasprice = web3.utils.toWei("0.00000000000021", "ether");
    const auctionId = selected.auctionId;

    /// PAY QUANTITY OF COPIES
    const quantitypay = pricetopay * quantity;

    const convertPrice = quantitypay.toFixed(8);

    const paywithQuantity = web3.utils.toWei(convertPrice, "ether");

    if (selected && selected?.tokenType == "Multiple") {
      // alert("Multiple Token");
      if (selected && selected.tokenOf != "PRONFT") {
        const auction = await ercAuctionContract();

        if (selected && parseInt(quantity) > parseInt(selected.copyRemaining)) {
          alert("not valid quantity");
          return;
        }

        setLoading(true);
        await auction.methods
          .buyToken(auctionId, quantity)
          .send({ from: accounts[0], value: paywithQuantity, gas: 1000000 })
          .then(async (val) => {
            let userOptions = new FormData();
            userOptions.append("ownerId", selected.Id);
            userOptions.append("tokenId", selected.tokenId);
            userOptions.append("auctionId", auctionId);
            userOptions.append("owner", selected.owner);
            userOptions.append("newOwner", accounts[0]);
            userOptions.append("royalties", selected.royaltyFee);
            userOptions.append("transactionHash", val.transactionHash);
            userOptions.append("price", amount);
            userOptions.append("serviceFees", 5);
            userOptions.append("tokenOf", selected.tokenOf);
            userOptions.append("tokenType", "Multiple");
            userOptions.append("filterUserOwner", true);
            userOptions.append("copyRemaining", quantity);
            let tokendata = await servicePost(
              "api/purchase/addmultiplepurchasedata",
              userOptions
            );
            setLoading(false);
            navigate("/profile/owned");
            //toggleDialog();
          })
          .catch((error) => {
            setLoading(false);
            //toggleDialog();
          });
      } else {
        alert("this is Multiple TEST");
        const auction = await ercAuctionContract();

        console.log("selected", selected);
        if (selected && parseInt(quantity) > parseInt(selected.copyRemaining)) {
          alert("not valid quantity");
          return;
        }

        setLoading(true);
        await auction.methods
          .buyToken(auctionId, quantity)
          .send({ from: accounts[0], value: paywithQuantity, gas: 1000000 })
          .then(async (val) => {
            let tokendata = await servicePost(
              "api/purchase/addmultiplepurchasedata",
              {
                ownerId: selected.Id,
                tokenId: selected.tokenId,
                auctionId: auctionId,
                owner: selected.owner,
                newOwner: accounts[0],
                royalties: selected.royalties,
                transactionHash: val.transactionHash,
                price: amount,
                serviceFees: 5,
                tokenOf: "PRONFT",
                tokenType: "Multiple",
                filterUserOwner: true,
                copyRemaining: quantity,
              }
            );

            console.log("tokendata", tokendata);

            setLoading(false);
            navigate("/profile/owned");
            //toggleDialog();
          })
          .catch((error) => {
            setLoading(false);
            //toggleDialog();
          });
      }
    } else {
      if (selected && selected.tokenOf != "PRONFT") {
        // alert("this collectible 1");
        const AuctionAbi = AuctionCollectible.abi;
        const Auctioncontract = await new web3.eth.Contract(
          AuctionAbi,
          "0x52c525CDe99D5349a4142bC6F19376C993bC052b"
        );

        setLoading(true);

        /// Here we will use AuctionCollectible contract
        await Auctioncontract.methods
          .purchasePriceAuction(auctionId)
          .send({
            from: accounts[0],
            value: ether,
            gas: 1000000,
          })
          .then(async (val) => {
            let userOptions = new FormData();
            userOptions.append("tokenId", selected.tokenId);
            userOptions.append("auctionId", selected.auctionId);
            userOptions.append("previousOwner", selected.owner);
            userOptions.append("newOwner", accounts[0]);
            userOptions.append("royalityFee", selected.royalties);
            userOptions.append("transactionHash", val.transactionHash);
            userOptions.append("price", amount);
            userOptions.append("serviceFees", 5);
            userOptions.append("tokenOf", selected.tokenOf);
            userOptions.append("tokenType", "Single");
            userOptions.append("filterUserOwner", true);
            let tokendata = await servicePost(
              "api/purchase/addPurchase",
              userOptions
            );

            setLoading(false);

            const activityData = await servicePost("api/activity/addActivity", {
              type: "Purchase",
              itemImage: selected.image,
              itemName: selected.tokenName,
              quantity: 1,
              price: selected.price,
              from: selected.owner,
              to: accounts[0],
              ownerAddress: accounts[0],
            });

            navigate("/profile/owned");
            window.location.reload();
          })
          .catch((error) => {
            setLoading(false);
            // toggleDialog();
          });
      } else {
        const auction = await auctionContract();

        if (quantity !== 0) {
          // console.log("ether[0]", ether);
          try {
            setLoading(true);
            //
            await auction.methods
              .purchasePriceAuction(auctionId)
              .send({ from: accounts[0], value: ether, gas: 1000000 })
              .then(async (val) => {
                let tokendata = await servicePost("api/purchase/addPurchase", {
                  tokenId: selected.tokenId,
                  auctionId: selected.auctionId,
                  previousOwner: selected.owner,
                  newOwner: accounts[0],
                  royalityFee: selected.royalties,
                  transactionHash: val.transactionHash,
                  price: amount,
                  serviceFees: 5,
                  tokenOf: "PRONFT",
                  tokenType: "Single",
                  filterUserOwner: true,
                });

                console.log("tokendata", tokendata);

                setLoading(false);
                navigate("/profile/owned");
                //toggleDialog();
              });
          } catch (error) {
            console.log("Error uploading file: ", error);
          }
        } else {
          alert("invalid quantity");
        }
      }
    }
  };

  return (
    <>
      <CheckoutModal
        show={showApproveModal}
        onHide={() => setShowApproveModal(false)}
      />

      <div className="checkout-main-wrapper android-chackout-wrapper">
        <div className="drawer-cancel-btn">
          <button data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
          </button>
        </div>
        <div className="checkout-drawer-header">
          <h2 className="drawer-heading">Checkout</h2>
          <p>
            You are about to purchase <b> {selected?.tokenName} </b> from
            <span> "{shortenWalletId(selected?.owner, 14, 8)}" </span>
          </p>
        </div>

        {selected && selected.tokenType == "Multiple" ? (
          <div className="checkout-multiple-wrapper">
            <div className="checkout-multiple-wrap">
              <h3> {selected.price} ETH </h3>
              <img src="/images/icons/ethereum.png" alt="icon" />
            </div>
            <div className="checkout-multiple-input">
              <input
                onChange={(e) => setQuantity(e.target.value)}
                type="number"
                placeholder="Enter Quantity here"
                value={quantity}
              />

              <div className="multiple-coppies-icon">
                <img src="/images/icons/copies-icon.png" alt="icon" />
              </div>

              <div className="checkout-amount-icons">
                <img
                  onClick={incrementAmount}
                  className="amount-up-arrow"
                  src="/images/icons/up-arrow.svg"
                  alt="icon"
                />
                <img
                  onClick={decrementAmount}
                  className="amount-down-arrow"
                  src="/images/icons/down-arrow.svg"
                  alt="icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="checkout-single-wrap">
            <img src="/images/icons/ethereum.png" alt="icon" />
            <h3> {selected?.price} ETH </h3>
          </div>
        )}

        <div className="checkout-detail-main-wrapper">
          <h3> Details </h3>
          <div className="checkout-detail-main-wrap">
            <div className="checkout-detail-repeat">
              <div className="checkout-detail-left">
                <p> Your balance </p>
              </div>
              <div className="checkout-detail-right">
                <h4>
                  {balance} <span> ETH </span>
                </h4>
                <img src="/images/icons/eth-icon.png" alt="icon" />
              </div>
            </div>

            <div className="checkout-detail-repeat">
              <div className="checkout-detail-left">
                <p> Service fee</p>
              </div>
              <div className="checkout-detail-right">
                <h4>
                  2.5 <span> % </span>
                </h4>
                <img src="/images/icons/pro.svg" alt="icon" />
              </div>
            </div>

            <div className="checkout-total-main-wrapper">
              <div className="checkout-total-main-wrap">
                <h2> Total </h2>
                <div className="checkout-total-wrap">
                  <h2> {totalprice.toFixed(4)} ETH </h2>
                  <img src="/images/icons/eth-icon.png" alt="icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="checkout-button-wrap">
          <button
            onClick={setProgressOn}
            className="btn-style"
            disabled={loading}
          >
            {loading ? (
              <div className="button-loading-gif">
                <Lottie animationData={LoadingLottie} loop={true} />
              </div>
            ) : (
              "Proceed"
            )}
          </button>
          <button
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            className="btn-style cancel-btn-style"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default BuyNftPage;
