import React from "react";
import Modal from "react-bootstrap/Modal";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";

const ConnectingModal = (props) => {
  return (
    <Modal
      {...props}
      dialogClassName="connecting-account-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="report-account-main-wrapper">
          <div className="report-account-main-wrap">
            <div className="report-modal-wrap">
              {/* <img src="/images/icons/confirm-tick.png" alt="icon" /> */}
              <div className="report-modal-contant">
                <div className="loading-lottie-file-wrap">
                  <h3> Loading </h3>
                  <div className="loading-lottie-file">
                    <Lottie animationData={LoadingLottie} loop={true} />
                  </div>
                </div>
                <p>
                  For secure sign-in, please utilize your Metamask wallet to
                  sign the message.
                </p>
              </div>
            </div>

            <div className="delete-button-wrap">
              <button
                className="btn-style cancel-btn-style"
                onClick={props.onHide}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConnectingModal;
