import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { web3 } from "../../constants/constants";
import { web3Connect } from "../../helpers/web3Modal";
import { serviceGet } from "../../helpers/api";
import {
  calculatePriceInCurrency,
  handleCopyWalletId,
} from "../../helpers/Normalization";
const ProfileDrawer = () => {
  const [userDetail, setUserDetail] = useState();
  const [balance, setBalance] = useState(0);
  const [account, setAccount] = useState("");
  const [convertedPrice, setConvertedPrice] = useState(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const navigate = useNavigate();
  const goToProfile = () => {
    navigate("/profile/owned");
    document.getElementById("closeDrawer").click();
  };

  const goToSetting = () => {
    navigate("/setting/profile");
    document.getElementById("closeDrawer").click();
  };

  const logout = async () => {
    localStorage.clear();
    if (web3Connect.cachedProvider) {
      let web3C = await web3Connect.clearCachedProvider();
      localStorage.removeItem("provider");
    }
    document.getElementById("closeDrawer").click();
    navigate("/");
  };

  let User = "";
  var finalAccount = "";
  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);
  const WalletId = userData && userData?.walletId;

  const setData = async () => {
    User = WalletId;
    if (WalletId !== null) {
      const firstdigt = User.slice(0, 8);
      const lastdigt = User.substr(0 - 4);
      finalAccount = firstdigt + "....." + lastdigt;
      setAccount(finalAccount);
      const bal = await web3.eth.getBalance(User);
      const bal1 = web3.utils.fromWei(bal, "ether");
      const bal2 = parseFloat(bal1).toFixed(4);
      setBalance(bal2);
    }
  };

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    const fetchConvertedPrice = async () => {
      const priceInUsd = balance; // Replace with the desired USD price
      const priceInCurrency = await calculatePriceInCurrency(priceInUsd);
      setConvertedPrice(priceInCurrency);
    };

    fetchConvertedPrice();
  }, [balance]);

  // get Logged User Details...
  const loggedUser = async () => {
    try {
      const userRes = await serviceGet(`user/${WalletId}`);
      setUserDetail(userRes.user);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    loggedUser();
  }, [WalletId]);

  // Get Provider form  localStorage...
  const provider = localStorage.getItem("provider");
  const cleanedProvider = provider ? provider.replace(/"/g, "") : null;

  return (
    <div className="profile-drower-data">
      <span
        id="closeDrawer"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></span>
      <div className="drawer-cancel-btn">
        <button data-bs-dismiss="offcanvas" aria-label="Close">
          <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
        </button>
      </div>
      <h2 className="drawer-heading">Profile Info</h2>
      <div className="profile-drawer-upper-wrapper">
        <div className="profile-links-wrap">
          <div className="profile-links-repeat d-flex align-items-center justify-content-between">
            <div className="profile-link-left">
              <img src="/images/icons/nft-icon.svg" alt="icon" />
              <span>My NFTs</span>
            </div>
            <div className="profile-link-arrow" onClick={goToProfile}>
              <img src="/images/icons/right-arrow.png" alt="icon" />
            </div>
          </div>

          <div className="profile-links-repeat d-flex align-items-center justify-content-between">
            <div className="profile-link-left">
              <img src="/images/icons/settings-icon.svg" alt="icon" />
              <span>Settings</span>
            </div>
            <div className="profile-link-arrow">
              <img
                onClick={goToSetting}
                src="/images/icons/right-arrow.png"
                alt="icon"
              />
            </div>
          </div>
        </div>

        <div className="wallet-connected-wrap">
          <h4>Wallet Connected</h4>
          <div className="wallet-data-inner">
            <div className="wallet-connect-active">
              <img src="/images/icons/wallect-active-icon.png" alt="icon" />
            </div>
            <div className="connect-wallet-top-warp">
              <div className="connect-wallet-wrap">
                <div className="meta-icon">
                  {cleanedProvider == "metamask" ? (
                    <img src="/images/icons/metamask.png" alt="img" />
                  ) : (
                    <img src="/images/icons/torus-icon.png" alt="img" />
                  )}
                </div>
                <div className="meta-info-text">
                  <div className="meta-info-text-left">
                    <p>{account}</p>
                    <span>BNB</span>
                  </div>
                  <div className="meta-copy-icon">
                    <img
                      onClick={() =>
                        handleCopyWalletId(
                          userDetail?.walletId,
                          setIsTooltipVisible
                        )
                      }
                      src="/images/icons/copy.svg"
                      alt="img"
                    />
                    <span> {isTooltipVisible && "Copied!"} </span>
                  </div>
                </div>
              </div>
              <div className="meta-log-off-icon">
                <img
                  onClick={logout}
                  src="/images/icons/logout.svg"
                  alt="img"
                />
              </div>
            </div>

            <div className="connect-wallet-balance-wrap">
              <div className="wallet-balance-repeat">
                <div className="wallet-balance-left">
                  <img src="/images/icons/eth-icon.png" alt="img" />
                  <p> {balance} BNB </p>
                </div>
                <div className="wallet-balance-right">
                  <p>${convertedPrice ? convertedPrice : 0}</p>
                </div>
              </div>

              <div className="wallet-balance-repeat">
                <div className="wallet-balance-left">
                  <img
                    className="pro-logo"
                    src="/images/icons/pro-logo.png"
                    alt="img"
                  />
                  <p> {balance} BNB </p>
                </div>
                <div className="wallet-balance-right">
                  <p>${convertedPrice ? convertedPrice : 0}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="profile-drawer-footer-wrap">
        <div className="profile-drawer-profile-info">
          <div className="profile-left-wrap" onClick={goToProfile}>
            {userDetail?.profileImageUrl ? (
              <img
                className="profile-pic"
                src={userDetail.profileImageUrl}
                alt="img"
              />
            ) : (
              <img
                className="profile-pic"
                src="/images/icons/avtar.png"
                alt="img"
              />
            )}
            <h3>
              {userDetail?.name
                ? userDetail?.name
                : userDetail?.walletId
                ? userDetail?.walletId.slice(0, 8)
                : "-"}
            </h3>

            {userDetail?.verified && (
              <img
                className="verified-tick"
                src="/images/icons/verified.png"
                alt="icon"
              />
            )}
          </div>
          <div className="profile-right-wrap" onClick={goToProfile}>
            <p> View Profile </p>
            <div className="profile-link-arrow">
              <img src="/images/icons/right-arrow.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDrawer;
