import { web3 } from "../constants/constants";
const auction = require("../components/abis/ERC1155Auction.json");

export const ercAuctionContract = async () => {
  const t = await web3.eth.net.getId();
  if (t == 97) {
    const networkAddress = auction.networks[t].address;
    const abi = auction.abi;
    return new web3.eth.Contract(abi, networkAddress);
  }
};
