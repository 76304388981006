import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const AdvancedFeatureMsg = (props) => {
    return (
        <Modal
          {...props}
          dialogClassName="connecting-account-modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="report-account-main-wrapper">
              <div className="report-account-main-wrap">
                <div className="report-modal-wrap">
                  {/* <img src="/images/icons/confirm-tick.png" alt="icon" /> */}
                  <div className="report-modal-contant">
                    <div className="loading-lottie-file-wrap">
                      <h3> Oops! </h3>
                    </div>
                    <p>
                     This is an advanced feature!
                    </p>
                  </div>
                </div>
    
                <div className="delete-button-wrap">
                  <button
                    className="btn-style cancel-btn-style"
                    onClick={props.onHide}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    };
    

export default AdvancedFeatureMsg;
