import React, { useState } from "react";
import ContractModal from "../Modals/ContractModal";
import { web3 } from "../../constants/constants";
import { servicePost } from "../../helpers/api";
import { ALLOWED_IMAGE_SIZE_IN_BYTES } from "../../constants/utility";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading-white.json";
const ERC721Collectible = require("../abis/ERC721Collectible.json");
const ERC1155Collectible = require("../abis/ERC1155Collectibles.json");

const CreateContract = ({ collectionType }) => {
  const [loading, setLoading] = useState(false);
  const [contractImage, setContractImage] = useState(null);
  const [showContractModal, setShowContractModal] = useState(false);
  const [contract, setContract] = useState();
  const [formData, setFormData] = useState({
    name: "",
    symbol: "",
    description: "",
    url: "",
  });
  const { name, symbol, description, url } = formData;
  const [fileValue, setFileValue] = useState();

  const navigate = useNavigate();

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  const handleContractImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setContractImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  let headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    // "xsrf-token": csrfToken,
    // "auth-token": token,
  };

  const ContractDeploy = async () => {
    if (name === "" && symbol === "") {
      alert("Please Enter Require fields");
      return;
    }

    setLoading(true);
    const accounts = await web3.eth.getAccounts();

    // if (checkName != "verified") {
    //   setErrorMsg("Contrat Name already Taken");
    //   return;
    // }

    // try {
    //   let reader = new FileReader();
    //   reader.onload = (e) => {
    //     const formData = new FormData();
    //     formData.append("contractImage", fileValue);
    //     formData.append("contractName", name);
    //     formData.append("symbol", symbol);
    //     formData.append("description", description);
    //     formData.append("shortUrl", url);
    //     formData.append(
    //       "contractAddress",
    //       "0xeE724Ba004d23390Be0Ad6CF9A1FEeDff6452cf5"
    //     );
    //     formData.append("ownerAddress", accounts[0]);
    //     formData.append("contractType", "Single");
    //     formData.append("transactionHash", "receipt.transactionHash");

    //     servicePost(`api/contract/addContractData`, formData, headers)
    //       .then((response) => {
    //         console.log("Api response", response.data);
    //         // window.open("/collection/items", "_parent");
    //         // alert("/collection/items");
    //         navigate("../collection/items", {
    //           state: response.data,
    //         });
    //       })
    //       .catch((err) => {
    //         // console.log(err);
    //       });
    //     setLoading(false);
    //   };
    //   reader.readAsDataURL(fileValue);
    // } catch (error) {
    //   throw new Error(error.message);
    //   setLoading(false);
    // }

    // return;

    if (collectionType == "Single") {
      alert("Single Contract");

      if (name !== "" && symbol !== "") {
        if (fileValue) {
          if (fileValue > ALLOWED_IMAGE_SIZE_IN_BYTES) {
            alert("File size exceeds maximum file size limit of 12MB");
            return;
          }

          var allowedExtensions = /(jpg|jpeg|png|gif)$/i;
          if (!allowedExtensions.exec(fileValue.type)) {
            alert("Invalid file type. Only jpg, png & gif allowed");
            return;
          }

          const bytecode = ERC721Collectible.bytecode;
          const abi = ERC721Collectible.abi;

          const accounts = await web3.eth.getAccounts();

          setShowContractModal(true);

          /// Deploy contract if image uploaded...
          try {
            const contractInstance = await new web3.eth.Contract(abi)
              .deploy({
                data: bytecode,
                arguments: [name, symbol],
              })
              .send({
                from: accounts[0],
                gas: 7000000,
                
              })
              .on("receipt", function (receipt) {
                //console.log("Receipt Data", receipt);
                // localStorage.setItem("walletId", JSON.stringify(accounts[0]));
                let reader = new FileReader();
                reader.onload = (e) => {
                  const formData = new FormData();
                  formData.append("contractImage", fileValue);
                  formData.append("contractName", name);
                  formData.append("symbol", symbol);
                  formData.append("description", description);
                  formData.append("shortUrl", url);
                  formData.append("contractAddress", receipt.contractAddress);
                  formData.append("ownerAddress", accounts[0]);
                  formData.append("contractType", "Single");
                  formData.append("transactionHash", receipt.transactionHash);

                  servicePost(`api/contract/addContractData`, formData, headers)
                    .then((response) => {
                      // console.log("Api response", response.data);
                      // window.open("/contract", "_parent");
                      navigate("/collection/items", { state: response.data });
                    })
                    .catch((err) => {
                      // console.log(err);
                    });
                };
                reader.readAsDataURL(fileValue);
              });

            setContract(contractInstance);
            // console.log("contractInstance", contractInstance);
          } catch (error) {
            // throw new Error(error.message);
          }
        } else {
          //// Deploy contract when image is not uploaded
          const bytecode = ERC721Collectible.bytecode;
          const abi = ERC721Collectible.abi;
          const accounts = await web3.eth.getAccounts();

          setShowContractModal(true);
          try {
            /// when not error
            const contractInstance = await new web3.eth.Contract(abi)
              .deploy({
                data: bytecode,
                arguments: [name, symbol],
              })
              .send({
                from: accounts[0],
                gas: 7000000,
              })
              .on("receipt", function (receipt) {
                // console.log("Receipt Data", receipt);
                localStorage.setItem("walletId", JSON.stringify(accounts[0]));
                servicePost(
                  `api/v1/contractdata/addcontractdata`,
                  {
                    contractImage: "",
                    contractName: name,
                    symbol: symbol,
                    description: description,
                    shortUrl: url,
                    contractAddress: receipt.contractAddress,
                    ownerAddress: accounts[0],
                    contractType: "single",
                    transactionHash: receipt.transactionHash,
                  },
                  headers
                )
                  .then((response) => {
                    // console.log("Api response", response.data);
                    // window.open("/contract", "_parent");

                    navigate("/collection/items", { state: response.data });
                  })
                  .catch((err) => {
                    // console.log(err);
                  });
              });
            setContract(contractInstance);
            // console.log("contractInstance", contractInstance);
          } catch (error) {
            // throw new Error(error.message);
          }
        }
      }
    } else {
      if (name !== "" && symbol !== "") {
        if (fileValue) {
          if (fileValue > ALLOWED_IMAGE_SIZE_IN_BYTES) {
            alert("File size exceeds maximum file size limit of 12MB");
            return;
          }

          var allowedExtensions = /(jpg|jpeg|png|gif)$/i;
          if (!allowedExtensions.exec(fileValue.type)) {
            alert("Invalid file type. Only jpg, png & gif allowed");
            return;
          }

          const bytecode = ERC1155Collectible.bytecode;
          // console.log("bytecode", bytecode);
          const abi = ERC1155Collectible.abi;
          // console.log(abi);
          const accounts = await web3.eth.getAccounts();

          setShowContractModal(true);

          /// Deploy contract if image uploaded...
          try {
            const contractInstance = await new web3.eth.Contract(abi)
              .deploy({
                data: bytecode,
                arguments: [name, symbol],
              })
              .send({
                from: accounts[0],
                gas: 7000000,
              })
              .on("receipt", function (receipt) {
                // console.log("Receipt Data", receipt);
                // localStorage.setItem("walletId", JSON.stringify(accounts[0]));

                let reader = new FileReader();
                reader.onload = (e) => {
                  const formData = new FormData();
                  formData.append("contractImage", fileValue);
                  formData.append("contractName", name);
                  formData.append("symbol", symbol);
                  formData.append("description", description);
                  formData.append("shortUrl", url);
                  formData.append("contractAddress", receipt.contractAddress);
                  formData.append("ownerAddress", accounts[0]);
                  formData.append("contractType", "Multiple");
                  formData.append("transactionHash", receipt.transactionHash);

                  servicePost(`api/contract/addContractData`, formData, headers)
                    .then((response) => {
                      navigate("../collection/items", {
                        state: response.data,
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  setLoading(false);
                };
                reader.readAsDataURL(fileValue);
              });

            setContract(contractInstance);
            // console.log("contractInstance", contractInstance);
          } catch (error) {
            throw new Error(error.message);
          }
        } else {
          //// Deploy contract when image is not uploaded
          const bytecode = ERC1155Collectible.bytecode;
          const abi = ERC1155Collectible.abi;
          const accounts = await web3.eth.getAccounts();

          setShowContractModal(true);
          try {
            /// when not error
            const contractInstance = await new web3.eth.Contract(abi)
              .deploy({
                data: bytecode,
                arguments: [name, symbol],
              })
              .send({
                from: accounts[0],
                gas: 7000000,
              })
              .on("receipt", function (receipt) {
                // console.log("Receipt Data", receipt);
                //   localStorage.setItem("walletId", JSON.stringify(accounts[0]));
                //   servicePost(
                //     `api/v1/contractdata/addcontractdata`,
                //     {
                //       contractImage: "",
                //       contractName: name,
                //       symbol: symbol,
                //       description: description,
                //       shortUrl: url,
                //       contractAddress: receipt.contractAddress,
                //       ownerAddress: accounts[0],
                //       contractType: "multiple",
                //       transactionHash: receipt.transactionHash,
                //     },
                //     headers
                //   )
                //     .then((response) => {
                //       // console.log("Api response", response.data);
                //       // window.open("/contract", "_parent");

                //       navigate("/collection/items", { state: response.data });
                //     })
                //     .catch((err) => {
                //       // console.log(err);
                //     });
                // });

                let reader = new FileReader();
                reader.onload = (e) => {
                  const formData = new FormData();
                  formData.append("contractImage", "");
                  formData.append("contractName", name);
                  formData.append("symbol", symbol);
                  formData.append("description", description);
                  formData.append("shortUrl", url);
                  formData.append("contractAddress", receipt.contractAddress);
                  formData.append("ownerAddress", accounts[0]);
                  formData.append("contractType", "Multiple");
                  formData.append("transactionHash", receipt.transactionHash);

                  servicePost(`api/contract/addContractData`, formData, headers)
                    .then((response) => {
                      // console.log("Api response", response.data);
                      navigate("../collection/items", {
                        state: response.data,
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  setLoading(false);
                };
                reader.readAsDataURL(fileValue);
              });
            setContract(contractInstance);
            // console.log("contractInstance", contractInstance);
          } catch (error) {
            // throw new Error(error.message);
          }
        }
      }
    }
  };

  return (
    <>
      <ContractModal
        show={showContractModal}
        onHide={() => setShowContractModal(false)}
      />

      <div className="create-contract-main-wrapper">
        <div className="drawer-cancel-btn">
          <button data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
          </button>
        </div>
        <h2 className="drawer-heading">Create Contract</h2>

        <div className="create-contract-main-wrap">
          <div className="contract-upload-file-wrap">
            {fileValue ? (
              <>
                <div className="contract-dynamic-image">
                  {/* <img src={contractImage} alt="img" /> */}
                  <img src={URL.createObjectURL(fileValue)} alt="img" />
                  <div className="contract-hover-icon">
                    <img src="/images/icons/edit-white.svg" alt="icon" />
                    <input
                      type="file"
                      onChange={(event) => {
                        setFileValue(event.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="contract-upload-wrap">
                <img
                  className="upload-image"
                  src="/images/icons/edit-profile-pic.svg"
                  alt="icon"
                />

                <div className="upload-contract-input">
                  <input
                    type="file"
                    //  onChange={handleContractImageUpload}
                    onChange={(event) => {
                      setFileValue(event.target.files[0]);
                    }}
                    accept="image/*"
                  />
                </div>
              </div>
            )}

            <div className="upload-image-contant">
              <h4> Upload File: </h4>
              <p>
                Ensure your image is at least <span> 400x400 pixels; </span>
                GIFs are also suitable for inclusion.
              </p>
            </div>
          </div>

          <div className="contract-input-wrapper">
            <div className="contract-input-repeat">
              <label> Name* </label>
              <input
                type="text"
                placeholder="Enter name"
                name="name"
                value={name}
                onChange={inputEventHandler}
              />
            </div>

            <div className="contract-input-repeat">
              <label>Symbol* </label>
              <input
                type="text"
                placeholder="Enter Unique Symbol"
                name="symbol"
                value={symbol}
                onChange={inputEventHandler}
              />
            </div>

            <div className="contract-input-repeat">
              <label> Description* </label>
              <input
                type="text"
                placeholder="Enter Description here"
                name="description"
                value={description}
                onChange={inputEventHandler}
              />
            </div>

            <div className="contract-input-repeat">
              <label> Short url* </label>
              <input
                type="text"
                placeholder="https://"
                name="url"
                value={url}
                onChange={inputEventHandler}
              />
            </div>
          </div>

          <div className="create-contract-button">
            <button onClick={ContractDeploy} className="btn-style">
              {loading ? (
                <div className="button-loading-gif">
                  <Lottie animationData={LoadingLottie} loop={true} />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateContract;
