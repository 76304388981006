import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ReportModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        dialogClassName="report-account-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="report-account-main-wrapper">
            <div className="report-account-main-wrap">
              <div className="report-modal-wrap">
                <img src="/images/icons/confirm-tick.png" alt="icon" />
                <div className="report-modal-contant">
                  <h3> Thanks for reporting </h3>
                  <p> We will investigate and make a right decision </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReportModal;
