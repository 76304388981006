import React from "react";
import CreateDrawer from "../Drawers/CreateDrawer";

const GuideData = [
  {
    icon: "/images/icons/wallet.png",
    heading: "Set up your wallet",
    text: `Once you’ve set up your wallet of choice, connect it to OpenSea
     by clicking the wallet icon in the top right corner. Learn about the
     wallets we support.`,
  },

  {
    icon: "/images/icons/folder.png",
    heading: "Create your collection",
    text: `Click My Collections and set up your collection. Add social links,
     a description, profile & banner images, and set a secondary sales fee.`,
  },

  {
    icon: "/images/icons/add-circle.png",
    heading: "Add your NFTs",
    text: `Upload your work (image, video, audio, or 3D art), add a title and description,
     and customize your NFTs with properties, stats, and unlockable content.`,
  },

  {
    icon: "/images/icons/sale.png",
    heading: "List them for sale",
    text: `Choose between auctions, fixed-price listings, and declining-price listings. You choose how 
    you want to sell your NFTs, and we help you sell them!`,
  },
];

const Guide = () => {
  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasCreateRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <CreateDrawer />
          </div>
        </div>
      </div>
      <div className="guide-main-wrapper custom-container">
        <div className="guide-main-wrap">
          <div className="guide-heading">
            <h3> How To Be A Creator </h3>
          </div>

          <div className="guide-card-wrapper">
            {GuideData?.map((data, index) => {
              return (
                <div key={index} className="guide-card-repeat">
                  <div className="guide-card-wrap">
                    <img src={data.icon} alt="icon" />
                    <h4> {data.heading} </h4>
                    <p>{data.text}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="guide-card-button">
          <button
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCreateRight"
            aria-controls="offcanvasRight"
            className="btn-style"
          >
            Create NFT
          </button>
        </div>
      </div>
    </>
  );
};

export default Guide;
