// @flow
import jwtDecode from "jwt-decode";

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const { user, token } = getLoggedInUser();

  if (!user) {
    return false;
  }
  //   if (user.userlist !== undefined) {
  //     return false;
  //   }
  if (token != undefined) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("token"));
  return { user, token };
};

export { isUserAuthenticated, getLoggedInUser };
