import React, { Fragment, useState } from "react";

const allMarketplace = [
  {
    name: "All",
    icon: "/images/icons/all-marketplace.png",
  },
  {
    name: "ProNFT",
    icon: "/images/icons/pro.svg",
  },
  {
    name: "Opensea",
    icon: "/images/icons/opensea.png",
  },
];

const MarketPlaceFilter = ({ onMartketPlaceChange }) => {
  const [isMarketActive, setIsMarketActive] = useState(false);
  const [marketSelected, setMarketSelected] = useState("All");
  return (
    <>
      <div className="select-box-wrapper">
        <div className="react-dropdown">
          <div
            className="react-dropdown-btn"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsMarketActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsMarketActive(false);
            }}
          >
            <div className="selected-item">
              <p>
                Marketplace: <span> {marketSelected} </span>
              </p>
            </div>

            <img
              className={
                isMarketActive
                  ? "dropdown-down-arrow rotate-arrow"
                  : "dropdown-down-arrow"
              }
              src="/images/icons/dropdown-arrow.svg"
              alt="arrow"
            />
          </div>
          <div
            className="dropdown-space-manage"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsMarketActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsMarketActive(false);
            }}
          ></div>
          {isMarketActive && (
            <div
              className="dropdown-content"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsMarketActive(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsMarketActive(false);
              }}
            >
              {allMarketplace &&
                allMarketplace.map((option, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        onClick={(e) => {
                          setMarketSelected(option.name);
                          setIsMarketActive(false);
                          onMartketPlaceChange(option.name);
                        }}
                        className="dropdown-item"
                      >
                        <div className="dropdown-options marketplace-option-images">
                          <img src={option.icon} alt="icon" />
                          <p
                            className={`${
                              option.name === marketSelected
                                ? "option-active"
                                : "option-normal"
                            }`}
                          >
                            {option.name}
                          </p>
                        </div>

                        {option.name === marketSelected && (
                          <div className="dropdown-selct-tick">
                            <span className="tick-mark">✓</span>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MarketPlaceFilter;
