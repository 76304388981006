import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import LoadingLottie from "../../lottiefiles/loading-white.json";
import { servicePost } from "../../../helpers/api";
import { setTaskDep } from "../../../redux/actions/contract";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConnectWallet from "../../Modals/ConnectWallet";

const UserCard = ({ data }) => {
  const [showConnectModal, setShowConnectmodal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState();

  const navigate = useNavigate();
  //get user from localstorage...
  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  // Get Dep from Redux
  const dispatch = useDispatch();
  const taskDep = useSelector(
    (state) => state.contract?.taskDep && state.contract.taskDep.Dep1
  );

  const checkIsisFollowing = async () => {
    try {
      const checkIsFollowing = await servicePost("api/followLink/isFollowing", {
        userId: data?.walletId,
        followerId: loggedInUser?.walletId,
      });
      setIsFollowing(checkIsFollowing.isFollowing);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    checkIsisFollowing();
  }, [data?.walletId, loggedInUser?.walletId, taskDep]);

  const followUser = async (userId) => {
    try {
      setIsLoading(true);
      const followData = await servicePost("api/followLink/follow", {
        userId: data?.walletId,
        followerId: loggedInUser?.walletId,
      });
      setIsLoading(false);
      dispatch(
        setTaskDep({
          Dep1: taskDep + 1,
        })
      );
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const unFollowUser = async (userId) => {
    try {
      setIsLoading(true);
      const followData = await servicePost("api/followLink/unfollow", {
        walletId: data.walletId,
        followerId: loggedInUser?.walletId,
      });
      setIsLoading(false);
      dispatch(
        setTaskDep({
          Dep1: taskDep + 1,
        })
      );
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const goToUserProfile = () => {
    if (data?.walletId == loggedInUser?.walletId) {
      navigate("/profile/owned");
    } else {
      navigate(`/user/${data?.walletId}/owned`);
    }
  };

  return (
    <>
      <ConnectWallet
        show={showConnectModal}
        onHide={() => setShowConnectmodal(false)}
      />
      <div className="profile-collection-repeat">
        <div className="profile-collection-wrap">
          <div className="collection-image-main-wrap" onClick={goToUserProfile}>
            <div className="collection-bg-image">
              {data.coverImageUrl ? (
                <img src={data.coverImageUrl} alt="img" />
              ) : (
                <img src="/images/default-cover.png" alt="img" />
              )}
            </div>
            <div className="collection-image-wrap user-profile-wrap">
              {data.profileImageUrl ? (
                <img src={data.profileImageUrl} alt="img" />
              ) : (
                <img src="/images/icons/avtar.png" alt="img" />
              )}
            </div>
          </div>

          <div className="explore-user-info-wrapper">
            <div className="explore-user-name" onClick={goToUserProfile}>
              <h3>
                {data?.name
                  ? data?.name
                  : data?.walletId
                  ? data?.walletId.slice(0, 8)
                  : "-"}
              </h3>
              {data.isVerified === true && (
                <img src="/images/icons/verified.png" alt="icon" />
              )}
            </div>
            <div className="explore-user-follower-wrap">
              <div className="explore-user-follower-count">
                <h4> Followers </h4>
                <p> {data.Follower} </p>
              </div>
              <div className="explore-user-btn-wrap">
                <div className="follower-btn-wrap">
                  {isLoading ? (
                    <div className="button-loading-gif">
                      <Lottie animationData={LoadingLottie} loop={true} />
                    </div>
                  ) : (
                    <>
                      {loggedInUser &&
                        loggedInUser.walletId !== data.walletId && (
                          <>
                            {isFollowing && (
                              <button
                                className="btn-style cancel-btn-style"
                                onClick={() => unFollowUser()}
                              >
                                Following
                              </button>
                            )}

                            {!isFollowing && (
                              <button
                                className="btn-style"
                                onClick={() => followUser()}
                              >
                                Follow
                              </button>
                            )}
                          </>
                        )}

                      {loggedInUser &&
                        loggedInUser.walletId === data.walletId && (
                          <button
                            className="btn-style cancel-btn-style"
                            disabled={true}
                          >
                            You
                          </button>
                        )}
                    </>
                  )}

                  {!loggedInUser && (
                    <button
                      className="btn-style"
                      onClick={() => setShowConnectmodal(true)}
                    >
                      Follow
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCard;
