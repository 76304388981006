import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const VerifyModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        dialogClassName="delete-account-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="delete-account-main-wrapper">
            <div className="delete-account-main-wrap">
              <h3> Oops! </h3>
              <p>
                To begin the verification process, please add the following data
                points.
              </p>
              <div className="verify-modal-req-wrapper">
                <div className="verify-modal-req-wrap">
                  <p>
                    <span> * Name </span> is required
                  </p>
                  <p>
                    <span> * Twitter Username </span> is required
                  </p>
                  <p>
                    <span> * Category </span> is required
                  </p>
                </div>
              </div>

              <div className="delete-button-wrap">
                <button className="btn-style" onClick={() => props.onHide()}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerifyModal;
