import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const CollectibleCard = ({ card }) => {
  const fileType = JSON.parse(card.fileType);
  const navigate = useNavigate();
  const goToSellPage = () => {
    if (card.sale == true) {
      navigate(
        `../token/${card.owner ? card.owner : " "}/${
          card.tokenId ? card.tokenId : " "
        }`,
        { state: card }
      );
      window.scrollTo(0, 0);
    } else {
      const userdata = JSON.parse(localStorage.getItem("user"));
      // console.log("userdata",userdata.walletId);
      if (!userdata) {
        navigate(
          `../token/${card.owner ? card.owner : " "}/${
            card.tokenId ? card.tokenId : " "
          }`,
          { state: card }
        );
        window.scrollTo(0, 0);
      } else if (card.tokenType == "Multiple") {
        if (userdata.walletId == card.owner) {
          navigate("../create/multiple", { state: card });
          window.scrollTo(0, 0);
        } else {
          navigate(
            `../token/${card.owner ? card.owner : " "}/${
              card.tokenId ? card.tokenId : " "
            }`,
            { state: card }
          );
          window.scrollTo(0, 0);
        }
      } else if (userdata.walletId == card.owner) {
        navigate("../create/single", { state: card });
      } else {
        navigate(
          `../token/${card.owner ? card.owner : " "}/${
            card.tokenId ? card.tokenId : " "
          }`,
          { state: card }
        );
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <>
      <div className="home-nft-card-repeat" onClick={goToSellPage}>
        {card.instantBuy == false && card.endDate && (
          <div className="bid-enddate-wrap">
            <img src="/images/icons/orange-bid-icon.png" alt="icon" />
            <div className="bid-enddate-timestep">
              <p>Sale ends</p>
              <p>
                <span>
                  {moment.unix(card.endDate).format("hh: mm: ss a")}
                  {/* {card.endDate} */}
                </span>
              </p>
            </div>
          </div>
        )}

        <div className="nft-card-wrap">
          <div className="nft-image-wrap">
            {fileType != undefined ? (
              fileType.type == "video" ? (
                <video controls>
                  <source src={card.image} type="video/mp4" />
                </video>
              ) : (
                <img
                  className="preview-image"
                  src={card.image !== "" && card.image}
                  alt="picked file"
                />
              )
            ) : null}
            {/* <img src={card.image} alt="img" /> */}
            {card.sale && (
              <div className="nft-status-wrap">
                {card.instantBuy ? (
                  <img src="/images/icons/buy-icon.png" alt="icon" />
                ) : (
                  <img src="/images/icons/bid-white-icon.svg" alt="icon" />
                )}

                {card.instantBuy ? <p> Buy Now </p> : <p> Place a bid </p>}
              </div>
            )}

            <div className="nft-platfrom-wrap">
              <img src="/images/icons/pro-logo.png" alt="img" />
            </div>
          </div>

          <div className="owner-info-wrap">
            <div className="owner-detail">
              {card.ownerImage ? (
                <img src={card.ownerImage} alt="img" />
              ) : (
                <img src="/images/icons/avtar.png" alt="img" />
              )}

              <h3> {card.tokenName} </h3>
            </div>
            {card.tokenType == "Multiple" && (
              <div className="copies-wrap">
                <img src="/images/icons/copies-icon.png" alt="icon" />
                {/* <p> 1 of 10</p> */}

                <p>
                  {card.copyRemaining}/{card.numberOfCopy}
                </p>
              </div>
            )}
          </div>

          <div className="nft-card-info-wrap">
            <div className="nft-card-left-info">
              <p> Current Price </p>
              {card.sale ? (
                <>
                  <div className="nft-card-price-wrap">
                    <h4>{card.price} BNB</h4>
                    <img src="/images/icons/eth-icon.png" alt="icon" />
                  </div>
                  <p> $605</p>
                </>
              ) : (
                <h4> Not For Sale</h4>
              )}
            </div>
            <div className="nft-card-right-info">
              <p> Current Price </p>
              <div className="nft-card-bid-wrap">
                <h4> No bids yet </h4>
                <img src="/images/icons/bid-icon.svg" alt="icon" />
              </div>
            </div>
          </div>
        </div>
        {card?.tokenType == "Multiple" && (
          <div className="multiple-line-wrapper">
            <div className="multiple-line-1"></div>
            <div className="multiple-line-2"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default CollectibleCard;
