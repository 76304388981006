import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ProfileDrawer from "../Drawers/ProfileDrawer";
import CreateDrawer from "../Drawers/CreateDrawer";
import ConnectWallet from "../Modals/ConnectWallet";
import MenuDrawer from "../Drawers/MenuDrawer";
import { serviceGet, servicePost } from "../../helpers/api";
import DesktopSeach from "./DesktopSeach";
import MoblieSearch from "./MobileSearch";

const Navbar = () => {
  const [showSearchDrop, setShowSearchDrop] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [showMobileSearchDrop, setShowMobileSearchDrop] = useState(false);
  const [showConnectModal, setShowConnectmodal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchMobileValue, setSearchMobileValue] = useState("");

  const [userData, setUserData] = useState([]);
  const inputRef = useRef(null);
  const dropdownWrapperRef = useRef(null);
  const dropdownContentRef = useRef(null);

  const navigate = useNavigate();
  const goToHomePage = () => {
    navigate("/");
  };

  const user = localStorage.getItem("user");
  const logginUser = JSON.parse(user);
  const walletId = logginUser && logginUser.walletId;

  const getUserDetail = async () => {
    try {
      const loginUserRes = await serviceGet(`user/${walletId}`);
      setUserData(loginUserRes.user);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [walletId]);

  const showDropdown = () => {
    setShowSearchDrop(true);
  };

  const hideDropdown = () => {
    setShowSearchDrop(false);
  };

  // useEffect(() => {
  //   const handleMouseDown = (event) => {
  //     if (
  //       inputRef.current &&
  //       !inputRef.current.contains(event.target) &&
  //       dropdownWrapperRef.current &&
  //       !dropdownWrapperRef.current.contains(event.target) &&
  //       !dropdownContentRef.current.contains(event.target) // Add this condition to prevent closing
  //     ) {
  //       hideDropdown();
  //     }
  //   };

  //   document.addEventListener("mousedown", handleMouseDown);
  //   return () => {
  //     document.removeEventListener("mousedown", handleMouseDown);
  //   };
  // }, []);x

  return (
    <>
      <ConnectWallet
        show={showConnectModal}
        onHide={() => setShowConnectmodal(false)}
      />
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <ProfileDrawer />
          </div>
        </div>
      </div>

      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasMenuRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <MenuDrawer />
          </div>
        </div>
      </div>

      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasCreateRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <CreateDrawer />
          </div>
        </div>
      </div>

      {showMobileSearch ? (
        <>
          <div className="mobile-navbar-search">
            <input
              type="text"
              placeholder="Explore collections, NFTs, or users through a search"
              onChange={(e) => setSearchMobileValue(e.target.value)}
              onFocus={() => setShowMobileSearchDrop(true)}
            />
            <div className="mobile-search-cross">
              <img
                onClick={() => {
                  setShowMobileSearch(false);
                  setSearchMobileValue("");
                  setShowMobileSearchDrop(false);
                }}
                src="/images/icons/cross-search.svg"
                alt="icon"
              />
            </div>
          </div>
          {showMobileSearchDrop && (
            <MoblieSearch
              searchValue={searchMobileValue}
              setShowMobileSearchDrop={setShowMobileSearchDrop}
            />
          )}
        </>
      ) : (
        <div className="navbar-main-wrapper">
          <div className="navbar-wrap custom-container">
            <div className="nav-left-wrap">
              <div className="nav-logo">
                <img
                  onClick={goToHomePage}
                  className="desktop-logo"
                  src="/images/icons/logo.png"
                  alt="logo"
                />
                <img
                  onClick={goToHomePage}
                  className="mobile-logo"
                  src="/images/icons/pro-logo.png"
                  alt="logo"
                />

                <img
                  onClick={() => setShowMobileSearch(true)}
                  className="mobile-search-logo"
                  src="/images/icons/search-icon.svg"
                  alt="icon"
                />
              </div>
              <div className="nav-manu-wrap">
                <ul>
                  <li>
                    <NavLink to="/explore/all/items" activeClassName="active">
                      Explore
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/profile/owned/sell" activeClassName="active">
                      Sell
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/feed" activeClassName="active">
                      Pro Feed
                    </NavLink>
                    <img src="/images/icons/pro-feed.png" alt="icon" />
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="nav-right-wrap">
              <div className="desktop-search-wrap">
                <input
                  type="text"
                  placeholder="Explore collections, NFTs, or users through a search"
                  onChange={(e) => setSearchValue(e.target.value)}
                  onFocus={showDropdown}
                  // onBlur={hideDropdown}
                />

                {showSearchDrop && (
                  <DesktopSeach
                    searchValue={searchValue}
                    hideDropdown={hideDropdown}
                  />
                )}
                {showSearchDrop ? (
                  <img
                    className="search-filter-icon"
                    src="/images/icons/search-2.png"
                    alt="icon"
                    onClick={hideDropdown}
                  />
                ) : (
                  <img
                    className="search-filter-icon"
                    src="/images/icons/search-1.png"
                    alt="icon"
                    onClick={showDropdown}
                  />
                )}
              </div>

              {!logginUser ? (
                <div className="connect-wallet-btn-wrap">
                  <button onClick={() => setShowConnectmodal(true)}>
                    Connect Wallet
                  </button>
                </div>
              ) : (
                <>
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasCreateRight"
                    aria-controls="offcanvasRight"
                  >
                    Create
                  </button>

                  {userData?.profileImageUrl ? (
                    <div className="profile-wrap">
                      <img
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        src={userData?.profileImageUrl}
                        alt="img"
                      />
                    </div>
                  ) : (
                    <div className="profile-wrap">
                      <img
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        src="/images/icons/avtar.png"
                        alt="img"
                      />
                    </div>
                  )}
                </>
              )}

              <div
                className="mobile-screen-menu"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasMenuRight"
                aria-controls="offcanvasRight"
              >
                <img src="/images/icons/menu.svg" alt="img" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
