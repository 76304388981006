import React, { useEffect, useState } from "react";
import MyProfile from "../MyProfile";
import ActivityFilter from "../../Dropdown/ActivityFilter";
import { servicePost } from "../../../helpers/api";
import moment from "moment";
import { shortenWalletId } from "../../../helpers/Normalization";
import { useParams } from "react-router-dom";

const UserActivity = () => {
  const [activity, setActivity] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState("");

  const { walletId } = useParams();
  const getActivity = async () => {
    try {
      const activityRes = await servicePost(
        "api/activity/getActivitiesByOwner",
        {
          ownerAddress: walletId,
        }
      );

      setActivity(activityRes.activities);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getActivity();
  }, [walletId]);

  const filterTokenData = () => {
    const filterData =
      activity &&
      activity.filter((ele) => {
        var activityFilter =
          selectedActivity && selectedActivity !== "All"
            ? selectedActivity
            : "";

        if (activityFilter == "") {
          return true;
        } else if (activityFilter) {
          return ele.type === activityFilter;
        }
        return false;
      });

    // Apply search filter based on tokenName
    const searchKeyword = searchValue.trim().toLowerCase();
    const searchedData = filterData.filter((ele) =>
      ele.itemName.toLowerCase().includes(searchKeyword)
    );

    setFilteredData(searchedData);
  };

  useEffect(() => {
    filterTokenData();
  }, [activity, selectedActivity, searchValue]);

  const handleTypeChange = (activity) => {
    setSelectedActivity(activity);
  };

  return (
    <>
      <div>
        <div
          className="offcanvas offcanvas-end profile-canvas-wrap"
          tabIndex="-1"
          id="offcanvasActivityFilter"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <div className="profile-mobile-filter-wrapper">
              <div className="profile-mobile-filter-wrap">
                <div className="drawer-cancel-btn">
                  <button data-bs-dismiss="offcanvas" aria-label="Close">
                    <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                  </button>
                </div>
                <h2 className="drawer-heading"> Filters </h2>
                <ActivityFilter onActivityChange={handleTypeChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-bg-wrapper">
        <MyProfile />
        <div className="profile-owned-main-wraper custom-container">
          <div className="profile-owned-filter-wrap">
            <div className="activity-owned-nft-search">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <img
                className="search-owned-icon"
                src="/images/icons/search-icon.svg"
                alt="icon"
              />
            </div>
            <div className="filter-mobile-view">
              <img
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasActivityFilter"
                aria-controls="offcanvasRight"
                src="/images/icons/filter-icon.svg"
                alt="icon"
              />
            </div>
            <div className="filter-desktop-view">
              <ActivityFilter onActivityChange={handleTypeChange} />
            </div>
          </div>

          <div className="activity-data-wrapper table-responsive">
            <table className="table activity-table  table-borderless">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ textAlign: "left", paddingLeft: "30px" }}
                  >
                    Type
                  </th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Items
                  </th>
                  <th scope="col"> quantity </th>
                  <th scope="col"> Price </th>
                  <th scope="col"> From </th>
                  <th scope="col"> To </th>
                  <th scope="col" style={{ paddingRight: "35px" }}>
                    Time
                  </th>
                </tr>
                {/* <div className="table-head-space"></div> */}
              </thead>
              <tbody>
                {filteredData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "left" }}>
                        <div className="activity-listing-wrap">
                          {data.type == "Purchase" ? (
                            <img
                              src="/images/icons/buy-active.svg"
                              alt="icon"
                            />
                          ) : data.type == "Listing" ? (
                            <img src="/images/icons/listing.svg" alt="icon" />
                          ) : data.type == "Sales" ? (
                            <img
                              src="/images/icons/sale-active.svg"
                              alt="icon"
                            />
                          ) : (
                            <img
                              src="/images/icons/bid-active.svg"
                              alt="icon"
                            />
                          )}
                          {data.type}
                        </div>
                      </td>
                      <td style={{ textAlign: "left" }}>
                        <div className="activity-table-info-wrap">
                          <div className="collection-image">
                            {data.itemImage ? (
                              <img src={data.itemImage} alt="img" />
                            ) : (
                              <img src="/images/default-cover.png" alt="img" />
                            )}
                          </div>

                          {data.itemName ? (
                            <p> {data.itemName} </p>
                          ) : (
                            <span className="span-for-empty-data"> - </span>
                          )}
                        </div>
                      </td>
                      <td>
                        {data.quantity ? (
                          `${data.quantity}`
                        ) : (
                          <span className="span-for-empty-data"> - </span>
                        )}
                      </td>
                      <td>
                        {data.price ? (
                          `${data.price} BNB`
                        ) : (
                          <span className="span-for-empty-data"> - </span>
                        )}
                      </td>
                      <td>{shortenWalletId(data.from, 8, 4)}</td>
                      <td>{shortenWalletId(data.to, 8, 4)}</td>
                      <td>{moment(data.updatedAt).format("LT")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {filteredData?.length === 0 && (
            <div className="user-empty-box-wrap">
              <img src="/images/icons/empty-box.png" alt="icon" />
              <p> Nothing Found </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserActivity;
