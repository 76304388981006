import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ConnectError from "./ConnectError";
import Torus from "@toruslabs/torus-embed";
import { servicePost } from "../../helpers/api";
import ConnectingModal from "./ConnectingModal";
import Web3 from "web3";

const ConnectWallet = (props) => {
  // const [showErrorModal, setShowErrorModal] = useState(false);
  const [showModalConnecting, setShowModalConnecting] = useState(false);

  // Connected with diffrent Wallet
  const connectToWallet = (param) => {
    switch (param) {
      case "metaMask":
        connectMetaMask();
        break;
      case "walletConnect":
        // connectWalletConnect();
        break;
      case "fortmatic":
        // connectformatic();
        break;
      case "torusConnect":
        torusConnect();
        break;
      default:

      case "MobilemetaMask":
        // connectMobileMetaMask();
        break;
      // code block
    }
  };

  // Connected With Metamask
  const connectMetaMask = async () => {
    const ethereum = typeof window != undefined ? window.ethereum : {};
    const web3 = typeof window != undefined ? new Web3(window.ethereum) : {};
    if (ethereum != undefined) {
      const networkId = await web3.eth.net.getId();
      if (networkId != 97) {
        alert("Please Connect To BNB Testnet Chain");
        // alert("Please Connect To Goerli");
      } else {
        requestPermissions();
      }
    } else {
      alert("Please Install Metamask");
    }
  };

  // Connected With Metamask Request Permission
  const requestPermissions = async () => {
    const ethereum = typeof window != undefined ? window.ethereum : {};
    const web3 = typeof window != undefined ? new Web3(window.ethereum) : {};
    ethereum
      .request({
        method: "wallet_requestPermissions",
        params: [{ eth_accounts: {} }],
      })
      .then(async (permissions) => {
        const accountsPermission = permissions.find(
          (permission) => permission.parentCapability === "eth_accounts"
        );
        if (accountsPermission) {
        }
        await ethereum.request({ method: "eth_requestAccounts" });
        localStorage.setItem("provider", JSON.stringify("metamask"));
        submitUser(web3);
      })
      .catch((error) => {
        if (error.code === 4001) {
          // EIP-1193 userRejectedRequest error
        } else {
          console.error(error);
        }
      });
  };

  // Connected With Tours
  const torusConnect = async () => {
    const torus = new Torus();
    await torus.init({
      enableLogging: true,

      network: {
        host: "https://eth-goerli.alchemyapi.io/v2/gSEBNODiS1QdyPSe3dsl50xGYYKGKMCY", // mandatory
        networkName: "Goerli",
        chainId: 5,
        blockExplorer: "https://goerli.etherscan.io",
        ticker: "ETH",
        tickerName: "ETH",
      },
      showTorusButton: true,
    });
    await torus.login(); // await torus.ethereum.enable()
    const web3 = new Web3(torus.provider);

    localStorage.setItem("provider", JSON.stringify("torus"));
    submitUser(web3);
  };

  // Submit User When Connected
  const submitUser = async (web3) => {
    const accounts = await web3.eth.getAccounts();

    // setModalShow(true);
    let balance = await web3.eth.getBalance(accounts[0]);
    try {
      const user = await servicePost("user/signup", {
        walletId: accounts[0],
        provider: "Metamask",
      });

      localStorage.setItem("user", JSON.stringify(user.user));
      localStorage.setItem("token", JSON.stringify(user.token));
      setShowModalConnecting(true);
      props.onHide();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error occurred:", error.message);
    }
  };

  return (
    <>
      {/* <ConnectError
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
      /> */}

      <ConnectingModal
        show={showModalConnecting}
        onHide={() => setShowModalConnecting(false)}
      />
      <Modal
        {...props}
        dialogClassName="connect-account-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="connect-wallet-main-wrapper">
            <div className="connect-wallet-main-wrap">
              <div className="connect-wallet-cancel">
                <button onClick={props.onHide}>
                  <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                </button>
              </div>
              <div className="connect-logo">
                <img src="/images/icons/logo.png" alt="img" />
              </div>

              <div className="connect-wallet-wrapper">
                <h3>
                  Connect <span>Wallet </span>
                </h3>
                <p>
                  Choose how you want to connect. There are several wallet
                  providers.
                </p>

                <div className="connect-wallet-options">
                  <div
                    onClick={() => connectToWallet("metaMask")}
                    className="connect-wallet-option-repeat"
                  >
                    <img src="/images/icons/metamask.png" alt="icon" />
                    <p> Meta Mask </p>
                  </div>

                  

                  <div className="connect-wallet-skip-wrap">
                    <button onClick={props.onHide} className="btn-style">
                      Skip for now
                    </button>
                  </div>
                </div>
              </div>
              <div className="connect-main-image">
                <div className="connect-wallet-cancel">
                  <button onClick={props.onHide}>
                    <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
                  </button>
                </div>
                <img src="/images/connect-image.png" alt="img" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectWallet;
