import React, { useEffect, useState } from "react";
import ContractModal from "../Modals/ContractModal";
import { web3 } from "../../constants/constants";
import { servicePost } from "../../helpers/api";
import { ALLOWED_IMAGE_SIZE_IN_BYTES } from "../../constants/utility";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading-white.json";
import { setTaskDep } from "../../redux/actions/contract";
import { useDispatch, useSelector } from "react-redux";

const EditContractDrawer = ({ ownerAddress, contractAddress }) => {
  const [loading, setLoading] = useState(false);
  const [contractImage, setContractImage] = useState(null);
  const [showContractModal, setShowContractModal] = useState(false);
  const [contractData, setContractData] = useState();
  const taskDep = useSelector(
    (state) => state.contract?.taskDep && state.contract.taskDep.Dep1
  );

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    symbol: "",
    description: "",
    url: "",
  });
  const { name, symbol, description, url } = formData;
  const [fileValue, setFileValue] = useState();

  const navigate = useNavigate();

  // console.log(fileValue);

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  // Check if Values already exist
  useEffect(() => {
    if (contractData) {
      setFormData({
        name: !contractData.contractName ? "" : contractData.contractName,
        symbol: !contractData.symbol ? "" : contractData.symbol,
        description: !contractData.description ? "" : contractData.description,
        url: !contractData.url ? "" : contractData.url,
      });
    }
  }, [contractData]);

  // get Contract Data....
  const getSingleContract = async () => {
    try {
      const contractdataRes = await servicePost(
        "api/contract/getSingleContractData",
        {
          ownerAddress: ownerAddress,
          contractAddress: contractAddress,
        }
      );

      setContractData(contractdataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSingleContract();
  }, [ownerAddress, contractAddress]);

  const handleContractImageUpload = (event) => {
    const file = event.target.files[0];
    if (file.size > ALLOWED_IMAGE_SIZE_IN_BYTES) {
      alert("File size exceeds maximum file size limit of 12MB");
      return;
    }

    var allowedExtensions = /(jpg|jpeg|png|gif)$/i;
    if (!allowedExtensions.exec(file.type)) {
      alert("Invalid file type. Only jpg, png & gif allowed");
      return;
    }
    setFileValue(file);
  };

  let headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    // "xsrf-token": csrfToken,
    // "auth-token": token,
  };

  const ContractDeploy = async () => {
    setLoading(true);
    const accounts = await web3.eth.getAccounts();
    try {
      let reader = new FileReader();
      reader.onload = (e) => {
        const formData = new FormData();
        formData.append("ownerAddress", ownerAddress);
        formData.append("contractAddress", contractAddress);
        formData.append("contractImage", fileValue);
        formData.append("description", description);
        formData.append("shortUrl", url);
        servicePost(`api/contract/updateCollection`, formData, headers)
          .then((response) => {
            dispatch(
              setTaskDep({
                Dep1: taskDep + 1,
              })
            );
            document.getElementById("closeUpdateCollectionDrawer").click();
          })
          .catch((err) => {
            console.log(err);
          });
        setLoading(false);
      };
      reader.readAsDataURL(fileValue);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <>
      <spna
        id="closeUpdateCollectionDrawer"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></spna>
      <ContractModal
        show={showContractModal}
        onHide={() => setShowContractModal(false)}
      />

      <div className="create-contract-main-wrapper">
        <div className="drawer-cancel-btn">
          <button data-bs-dismiss="offcanvas" aria-label="Close">
            <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
          </button>
        </div>
        <h2 className="drawer-heading">Create Contract</h2>

        <div className="create-contract-main-wrap">
          <div className="contract-upload-file-wrap">
            {fileValue ? (
              <>
                <div className="contract-dynamic-image">
                  {/* <img src={contractImage} alt="img" /> */}
                  <img src={URL.createObjectURL(fileValue)} alt="img" />
                  <div className="contract-hover-icon">
                    <img src="/images/icons/edit-white.svg" alt="icon" />
                    <input type="file" onChange={handleContractImageUpload} />
                  </div>
                </div>
              </>
            ) : contractData?.contractImage ? (
              <>
                <div className="contract-dynamic-image">
                  {/* <img src={contractImage} alt="img" /> */}
                  <img src={contractData?.contractImage} alt="img" />
                  <div className="contract-hover-icon">
                    <img src="/images/icons/edit-white.svg" alt="icon" />
                    <input type="file" onChange={handleContractImageUpload} />
                  </div>
                </div>
              </>
            ) : (
              <div className="contract-upload-wrap">
                <img
                  className="upload-image"
                  src="/images/icons/edit-profile-pic.svg"
                  alt="icon"
                />

                <div className="upload-contract-input">
                  <input
                    type="file"
                    onChange={handleContractImageUpload}
                    accept="image/*"
                  />
                </div>
              </div>
            )}

            <div className="upload-image-contant">
              <h4> Upload File: </h4>
              <p>
                Ensure your image is at least <span> 400x400 pixels; </span>
                GIFs are also suitable for inclusion.
              </p>
            </div>
          </div>

          <div className="contract-input-wrapper">
            <div className="contract-input-repeat">
              <label> Name* </label>
              <input
                type="text"
                placeholder="Enter name"
                name="name"
                value={name}
                // onChange={inputEventHandler}
                disabled
              />
            </div>

            <div className="contract-input-repeat">
              <label>Symbol* </label>
              <input
                type="text"
                placeholder="Enter Unique Symbol"
                name="symbol"
                value={symbol}
                onChange={inputEventHandler}
                disabled
              />
            </div>

            <div className="contract-input-repeat">
              <label> Description* </label>
              <input
                type="text"
                placeholder="Enter Description here"
                name="description"
                value={description}
                onChange={inputEventHandler}
              />
            </div>

            <div className="contract-input-repeat">
              <label> Short url* </label>
              <input
                type="text"
                placeholder="https://"
                name="url"
                value={url}
                onChange={inputEventHandler}
              />
            </div>
          </div>

          <div className="create-contract-button">
            <button onClick={ContractDeploy} className="btn-style">
              {loading ? (
                <div className="button-loading-gif">
                  <Lottie animationData={LoadingLottie} loop={true} />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditContractDrawer;
