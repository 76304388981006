import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading-white.json";
import { servicePost } from "../../helpers/api";
import { setTaskDep } from "../../redux/actions/contract";
import { useDispatch, useSelector } from "react-redux";
import ConnectWallet from "../Modals/ConnectWallet";

const FollowingCard = ({ data, type }) => {
  const [showConnectModal, setShowConnectmodal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBothFollowing, setIsBothFollowing] = useState();
  const [isFollowing, setIsFollowing] = useState();

  //get user from localstorage...
  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  // Get Dep from Redux
  const dispatch = useDispatch();
  const taskDep = useSelector(
    (state) => state.contract?.taskDep && state.contract.taskDep.Dep1
  );

  const checkIsBothFollowing = async () => {
    try {
      const checkBothFollowing = await servicePost(
        "api/followLink/isBothFollowing",
        {
          userId: data.followerid,
          followerId: loggedInUser?.walletId,
        }
      );
      setIsBothFollowing(checkBothFollowing.isFollowing);
    } catch (error) {
      console.log("error", error);
    }
  };

  const checkIsisFollowing = async () => {
    try {
      const checkIsFollowing = await servicePost("api/followLink/isFollowing", {
        userId: data.userid,
        followerId: loggedInUser?.walletId,
      });
      setIsFollowing(checkIsFollowing.isFollowing);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    checkIsBothFollowing();
    checkIsisFollowing();
  }, [data.userid, loggedInUser?.walletId, taskDep]);

  const followUser = async (userId) => {
    try {
      setIsLoading(true);
      const followData = await servicePost("api/followLink/follow", {
        userId: data.userid,
        followerId: loggedInUser?.walletId,
      });
      setIsLoading(false);
      dispatch(
        setTaskDep({
          Dep1: taskDep + 1,
        })
      );
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const unFollowUser = async (userId) => {
    try {
      setIsLoading(true);
      const followData = await servicePost("api/followLink/unfollow", {
        walletId: data.userid,
        followerId: loggedInUser?.walletId,
      });
      setIsLoading(false);
      dispatch(
        setTaskDep({
          Dep1: taskDep + 1,
        })
      );
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ConnectWallet
        show={showConnectModal}
        onHide={() => setShowConnectmodal(false)}
      />

      <div className="follower-wrap-repeat">
        <div className="follower-img-wrap">
          {data.followingImageUrl ? (
            <img src={data.followingImageUrl} alt="img" />
          ) : (
            <img src="/images/icons/avtar.png" alt="img" />
          )}

          <h3>
            {data.followingName
              ? data.followingName
              : data.followerid
              ? data.followerid.slice(0, 8)
              : "-"}
          </h3>
        </div>
        <div className="follower-btn-wrap">
          {isLoading ? (
            <div className="button-loading-gif">
              <Lottie animationData={LoadingLottie} loop={true} />
            </div>
          ) : (
            <>
              {loggedInUser && loggedInUser?.walletId !== data.userid && (
                <>
                  {isFollowing && (
                    <button
                      className="btn-style cancel-btn-style"
                      onClick={() => unFollowUser()}
                    >
                      Following
                    </button>
                  )}

                  {!isFollowing && (
                    <button className="btn-style" onClick={() => followUser()}>
                      Follow
                    </button>
                  )}
                </>
              )}

              {loggedInUser && loggedInUser?.walletId === data.userid && (
                <button className="btn-style cancel-btn-style" disabled={true}>
                  You
                </button>
              )}

              {!loggedInUser && (
                <button
                  className="btn-style"
                  onClick={() => setShowConnectmodal(true)}
                >
                  Follow
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FollowingCard;
