import React, { Fragment, useEffect, useState } from "react";
import { servicePost } from "../../../helpers/api";

const ACTIVITY = [
  {
    image: "",
    address: "0xca....fshbdjv",
    type: "listed for",
    date: "28 Minute ago",
    price: "3.1",
    dollar: "23",
  },
  {
    image: "/images/profile.jpg",
    address: "0xca....fshbdjv",
    type: "purchased for",
    date: "28 Minute ago",
    price: "5.1",
    dollar: "68",
  },
  {
    address: "0xca....fshbdjv",
    type: "listed for",
    date: "28 Minute ago",
    price: "1",
    dollar: "20",
  },
  {
    address: "0xca....fshbdjv",
    type: "purchased for",
    date: "28 Minute ago",
    price: "2",
    dollar: "18",
  },
];

const Activity = () => {
  const [activity, setActivity] = useState([]);
  const getActivity = async () => {
    try {
      const activityRes = await servicePost(
        "api/activity/getActivitiesByOwner",
        {
          ownerAddress: "",
        }
      );

      setActivity(activityRes.activities);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getActivity();
  }, []);
  return (
    <>
      <div className="activity-main-wrapper">
        <div className="activity-main-wrap">
          {ACTIVITY?.length == 0 ? (
            <>
              <div className="empty-activity-wrap">
                <img src="/images/icons/empty-box.png" alt="img" />
                <p> Nothing Found </p>
              </div>
            </>
          ) : (
            ACTIVITY?.map((data, index) => {
              return (
                <Fragment key={index}>
                  <div className="activity-repeat-wrap">
                    <div className="activity-left-wrap">
                      {data.image ? (
                        <img
                          className="activity-dynamic-image"
                          src={data.image}
                          alt="img"
                        />
                      ) : (
                        <img src="/images/icons/avtar.png" alt="img" />
                      )}

                      <div className="activity-address-wrap">
                        <h4>
                          {data.address} <span> {data.type} </span>
                        </h4>
                        <p> {data.date} </p>
                      </div>
                    </div>
                    <div className="activity-right-wrap">
                      <div className="activity-price-wrap">
                        <h3> {data.price} Eth </h3>
                        <img src="/images/icons/eth-icon.png" />
                      </div>
                      <p> ${data.dollar} </p>
                    </div>
                  </div>
                </Fragment>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default Activity;
