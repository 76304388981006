import React, { Fragment, useEffect, useState } from "react";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading-white.json";
import { servicePost } from "../../helpers/api";
import { useDispatch, useSelector } from "react-redux";
import { setTaskDep } from "../../redux/actions/contract";
import FollowCard from "../Cards/FollowCard";
import FollowingCard from "../Cards/FollowingCard";

const FollowingDrawer = ({ walletId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [followingList, setFollowingList] = useState([]);
  const [isBothFollowing, setIsBothFollowing] = useState();
  const [isFollowing, setIsFollowing] = useState();

  //get user from localstorage...
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  // Get Dep from Redux
  const dispatch = useDispatch();
  const taskDep = useSelector(
    (state) => state.contract?.taskDep && state.contract.taskDep.Dep1
  );

  const getFollowingList = async () => {
    try {
      const followingData = await servicePost("api/followLink/followingList", {
        userId: walletId,
      });

      setFollowingList(followingData.followingList);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getFollowingList();
  }, [walletId, taskDep]);

  const checkIsBothFollowing = async () => {
    try {
      const checkBothFollowing = await servicePost(
        "api/followLink/isBothFollowing",
        {
          userId: walletId,
          followerId: loggedInUser?.walletId,
        }
      );
      setIsBothFollowing(checkBothFollowing.isFollowing);
    } catch (error) {
      console.log("error", error);
    }
  };

  const checkIsisFollowing = async () => {
    try {
      const checkIsFollowing = await servicePost("api/followLink/isFollowing", {
        userId: walletId,
        followerId: loggedInUser?.walletId,
      });
      setIsFollowing(checkIsFollowing.isFollowing);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    checkIsBothFollowing();
    checkIsisFollowing();
  }, [walletId, loggedInUser?.walletId, taskDep]);

  const followUser = async () => {
    try {
      setIsLoading(true);
      const followData = await servicePost("api/followLink/follow", {
        userId: walletId,
        followerId: loggedInUser?.walletId,
      });
      setIsLoading(false);
      dispatch(
        setTaskDep({
          Dep1: taskDep + 1,
        })
      );
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const unFollowUser = async () => {
    try {
      setIsLoading(true);
      const followData = await servicePost("api/followLink/unfollow", {
        walletId: walletId,
        followerId: loggedInUser?.walletId,
      });
      setIsLoading(false);
      dispatch(
        setTaskDep({
          Dep1: taskDep + 1,
        })
      );
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="follower-drawer-main-wrapper">
      <div className="drawer-cancel-right-btn">
        <button data-bs-dismiss="offcanvas" aria-label="Close">
          <img src="/images/icons/drawer-cross-icon.svg" alt="icon" />
        </button>
      </div>
      <h2 className="drawer-heading"> Following </h2>

      <div className="follower-main-wrapper">
        {followingList?.length > 0 ? (
          followingList.map((data, index) => {
            return (
              <Fragment key={index}>
                <FollowingCard data={data} />
                {/* <div className="follower-wrap-repeat" key={index}>
                  <div className="follower-img-wrap">
                    {data.followingImageUrl ? (
                      <img src={data.followingImageUrl} alt="img" />
                    ) : (
                      <img src="/images/icons/avtar.png" alt="img" />
                    )}

                    <h3>
                      {data.followingName
                        ? data.followingName
                        : data.followingid
                        ? data.followingid.slice(0, 8)
                        : "-"}
                    </h3>
                  </div>
                  <div className="follower-btn-wrap">
                    {isLoading ? (
                      <div className="button-loading-gif">
                        <Lottie animationData={LoadingLottie} loop={true} />
                      </div>
                    ) : (
                      <>
                        {loggedInUser &&
                          loggedInUser.walletId !== data.followerid && (
                            <>
                              {isFollowing && isBothFollowing && (
                                <button
                                  className="btn-style cancel-btn-style"
                                  onClick={unFollowUser}
                                >
                                  Following
                                </button>
                              )}

                              {isFollowing && !isBothFollowing && (
                                <button
                                  className="btn-style cancel-btn-style"
                                  onClick={unFollowUser}
                                >
                                  Unfollow
                                </button>
                              )}

                              {!isFollowing && !isBothFollowing && (
                                <button
                                  className="btn-style"
                                  onClick={followUser}
                                >
                                  Follow
                                </button>
                              )}
                            </>
                          )}

                        {loggedInUser &&
                          loggedInUser.walletId === data.followerid && (
                            <button
                              className="btn-style cancel-btn-style"
                              disabled={true}
                            >
                              You
                            </button>
                          )}
                      </>
                    )}
                  </div>
                </div> */}
              </Fragment>
            );
          })
        ) : (
          <div className="empty-follower-card">
            <div className="empty-card-image">
              <img src="/images/icons/empty-box.png" alt="empty" />
              <p>Nothing Found</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FollowingDrawer;
