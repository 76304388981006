import React, { Fragment, useState } from "react";

const PriceItem = [
  {
    name: "Low to high",
  },
  {
    name: "High to low",
  },
];

const PriceFilter = ({ onPriceChange }) => {
  const [isPriceActive, setIsPriceActive] = useState(false);
  const [priceSelected, setPriceSelected] = useState("Low to high");
  return (
    <>
      <div className="select-box-wrapper">
        <div className="react-dropdown">
          <div
            className="react-dropdown-btn"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsPriceActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsPriceActive(false);
            }}
          >
            <div className="selected-item">
              <p>
                Price: <span> {priceSelected} </span>
              </p>
            </div>

            <img
              className={
                isPriceActive
                  ? "dropdown-down-arrow rotate-arrow"
                  : "dropdown-down-arrow"
              }
              src="/images/icons/dropdown-arrow.svg"
              alt="arrow"
            />
          </div>
          <div
            className="dropdown-space-manage"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsPriceActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsPriceActive(false);
            }}
          ></div>
          {isPriceActive && (
            <div
              className="dropdown-content"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsPriceActive(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsPriceActive(false);
              }}
            >
              {PriceItem &&
                PriceItem.map((option, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        onClick={(e) => {
                          setPriceSelected(option.name);
                          setIsPriceActive(false);
                          onPriceChange(option.name);
                        }}
                        className="dropdown-item"
                      >
                        <div className="dropdown-options">
                          <p
                            className={`${
                              option.name === priceSelected
                                ? "option-active"
                                : "option-normal"
                            }`}
                          >
                            {option.name}
                          </p>
                        </div>

                        {option.name === priceSelected && (
                          <div className="dropdown-selct-tick">
                            <span className="tick-mark">✓</span>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PriceFilter;
